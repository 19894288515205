import { HttpService } from "shared/http/http";
import {
    ApprovalStatus,
    ResponseWaiver,
    SearchSettings,
    SearchWaivers,
		StatusFilter
} from "shared/models/AWaiver";

class AWaiverService extends HttpService {
    constructor() {
        super("/AWaiver/SignedWaiver");
    }

    async searchWaivers(searchText: string, options: SearchSettings, waiverId: number) {
        const result = await this.post<Array<SearchWaivers>>("list", {
            SearchString: searchText,
            SearchSettings: options,
            waiverId: waiverId,
        });
        if (result.status === 200) {
            return result.data as SearchWaivers[];
        } else {
            return [];
        }
    }

    updateWaiverStatus(signedWaiverId: number, reservationId: number, approvalStatus: string) {
        return this.put(`updateStatus/${signedWaiverId}/${approvalStatus}/${reservationId}`, null);
    }

    async waiver(signedWaiverId: string) {
        const result = await this.get<ResponseWaiver>(`${signedWaiverId}`);
        if (result.status === 200) {
            return result.data as ResponseWaiver;
        }
        return null;
    }

		// searchWaivers() probably could support this query but the backend controllers
		// check if status == null on the RecentSigned path and then constructs a 12 hour
		// Time Span to use as a query parameter
    async getPendingWaivers(filter: StatusFilter, waiverId: number) {
        const endPoint = filter === StatusFilter.PENDING ? "PendingWaivers" : "RecentSigned";
				// Always Searches Pending Waivers. Recent
        const result = await this.post<Array<SearchWaivers>>(`${endPoint}/${waiverId}`, {
					status: filter === StatusFilter.PENDING ? 0 : null,
					searchExpired: true,
					searchNotExpired: true,
        });
        if (result.status === 200) {
            return result.data as SearchWaivers[];
        } else {
            return [];
				}
    }
}

const aWaiverService = new AWaiverService();
export default aWaiverService;
