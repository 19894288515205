import { QuestionBase, QuestionAnswer, QuestionType, QuestionMultiChoice } from "shared/models/QuestionTypes";
import { ReservationDetails } from "shared/models/Reservation";
import { isEmpty } from "shared/utils/Common";

/**
 * Process the questions and answers to generate:
 *
 * 1. Existing questions: search into the questions for existing answers and add the answers to the array
 * 3. Parsed questions: Answers that can be parsed without losing information are converted and added to this array
 * 2. Unavailable Answers: answers without an associated question (parsed answers not included) are grouped together in this array
 *
 * Note: Parsable answers into questions are also added to existing questions
 * @param questionsArr
 * @param answers
 * @return [existing answers, old answers, parsed questions]
 *
 */
export const divideQuestionAnswers = (
    questionsArr: QuestionBase[],
    answers: QuestionAnswer[] = [],
): [QuestionAnswer[], QuestionBase[]] => {
    let existing: QuestionAnswer[] = [];
    let parsed: QuestionBase[] = [];

    let questions: QuestionBase[] = [];
    if (!isEmpty(questionsArr)) {
        questions = questionsArr;
    }

    answers.forEach((answer) => {
        // Search on the current questions for the answer
        const temp: QuestionBase | undefined = questions.find(
            isSameQuestion(answer),
        );
        if (!temp) {
            // If we add the question to parsed, add to existing as well
            switch (answer.questionType) {
                case QuestionType.SingleChoice:
                    parsed.push(answerToQuestionSingleEditable(answer));
                    break;
                case QuestionType.MultiChoice:
                    parsed.push(answerToQuestionMultiEditable(answer));
                    break;
                case QuestionType.MultiChoiceEditable:
                    parsed.push(answerToQuestionMultiEditable(answer));
                    break;
                default:
                    parsed.push(answerToQuestion(answer));
            }
            existing.push(answer);
        } else {
            // If the related question exists we save it
            existing.push(answer);
        }
    }, []);

    return [existing, parsed];
};

/**
 * Converts `QuestionAnswers` object to `QuestionBase`, be sure to check information loss before using this function
 * @param answer
 */
export const answerToQuestion = (answer: QuestionAnswer): QuestionBase => {
    return {
        id: answer.questionId,
        questionText: answer.questionText,
        questionType: answer.questionType,
    };
};

export const answerToQuestionSingleEditable = (
    answer: QuestionAnswer,
): QuestionBase => {
    return {
        id: answer.questionId,
        questionText: answer.questionText,
        questionType: QuestionType.SingleChoiceEditable,
    };
};
export const answerToQuestionMultiEditable = (
    answer: QuestionAnswer,
): QuestionMultiChoice => {
    return {
        id: answer.questionId,
        questionText: answer.questionText,
        questionType: QuestionType.MultiChoiceEditable,
        answers: answer.answer,
    };
};

/**
 * HOF - Determine if a given question is related to an answer
 * @param answer
 */
export const isSameQuestion =
    (answer: QuestionAnswer) =>
        (question: QuestionBase): boolean => {
            return (
                question.questionType === answer.questionType &&
                question.questionText.toLowerCase() ===
                answer.questionText.toLowerCase()
            );
        };

/**
 * @description Returns a boolen if there is questions or answers to show
 * @param reservationData this object has the answers of every question or it could be an empty string¡
 * @param reservationEventQuestionJson this questions belongs to ReservationEditData
 * @param eventQuestionsJson this questions belongs to CurrentEvent
 * @returns boolean
 */
export const hasQuestions = (
    reservationData?: ReservationDetails | null,
    reservationEventQuestionJson?: string,
    eventQuestionsJson?: string,
): boolean => {
    if (
        reservationData?.answersJson &&
        reservationData?.answersJson?.length > 2
    ) {
        return true;
    }
    if (
        reservationEventQuestionJson &&
        reservationEventQuestionJson?.length > 2
    ) {
        return true;
    }
    if (eventQuestionsJson && eventQuestionsJson?.length > 2) {
        return true;
    }
    return false;
};
