import { FormControl, FormLabel, Grid, MenuItem, Select, Typography } from "@mui/material";
import { ICompanyUser } from "shared/models/Company";
import { CompanyPreferences, EventGroupFilter, StartPage } from "shared/models/Creds";

interface CredentialsProps {
    data: ICompanyUser;
    handleChange?: (arg: any) => void;
}

const defaultCompanyPreferences = {
    defaultPage: 0,
    defaultEventGroupId: 0,
    resourceCalendarDefaultTypes: [],
};

const UserGeneralSection = (props: CredentialsProps) => {
    let preferences: CompanyPreferences;
    if (props.data.companyPreferences) {
        preferences = props.data.companyPreferences;
    } else {
        preferences = defaultCompanyPreferences;
    }
    const handleChange = (event: any) => {
        let { name, value } = event.target;
        if (name === "defaultPage" || name === "defaultEventGroupId") {
            value = Number(value);
        }

        const data = { [name]: value };
        if (props.handleChange) props.handleChange(data);
    };
    return (
        <div>
            <Typography variant="h5" gutterBottom>
                General
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={4}>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">Starting page</FormLabel>
                        <Select
                            value={preferences.defaultPage}
                            name="defaultPage"
                            onChange={handleChange}
                        >
                            <MenuItem value={StartPage.Calendar}>Calendar</MenuItem>
                            <MenuItem value={StartPage.EventList}>Event List</MenuItem>

                            <MenuItem disabled value={StartPage.ReservationReport}>
                                Reservation report (coming soon)
                            </MenuItem>
                            <MenuItem disabled value={StartPage.ResourceCalendar}>
                                Resource calendar (coming soon)
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">Show events in group</FormLabel>
                        <Select
                            value={preferences.defaultEventGroupId}
                            name="defaultEventGroupId"
                            onChange={handleChange}
                        >
                            <MenuItem value={EventGroupFilter.AllEvents}>All events</MenuItem>
                            <MenuItem value={EventGroupFilter.Partner}>Partner events</MenuItem>
                            <MenuItem value={EventGroupFilter.JustCompany}>Company events</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
};

export default UserGeneralSection;
