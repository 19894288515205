import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import Drawer from "./Drawer/Drawer";
import Header from "./Header/Header";
import ReservationModalProvider from "../modules/Reservation/context/ReservationModalProvider";
import ShoppingCartProvider from "../modules/ShoppingCart/ShoppingCartProvider";
import useSignalRConnection from "shared/hooks/useSignalRConnection";
import ErrorBoundary from "../monitoring/ErrorBoundary";
import { useCredentials } from "store/Credentials/CredencialHook";
import TransactionDetailsPanel from "modules/TransactionDetails/TransactionDetailsPanel";
import NotificationSnackbar from "shared/components/NotificationSnackbar";
import { useIndexicTheme } from "theme/useIndexicTheme";
import { drawerWidth } from "theme/config";

const MainLayout = () => {
    const theme = useIndexicTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = useState(isMobile ? false : true);
    const credentials = useCredentials().credentialsState.credentials;
    const { connectSignalR } = useSignalRConnection();

    useEffect(() => {
        if (credentials) {
            connectSignalR(credentials.oid, credentials.companyID);
        }
    }, []);

    return (
        <ShoppingCartProvider>
            <ReservationModalProvider>
                <Box sx={{ display: "flex", width: "100%" }}>
                    <Header open={open} handleDrawerToggle={() => setOpen(!open)} />
                    <Drawer open={open} handleDrawerToggle={() => setOpen(!open)} />
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            pt: 7.5,
                            pl: open && !isMobile ? `${drawerWidth}px` : 0,
                            height: "100vh",
                            overflow: "auto",
                        }}
                    >
                        <ErrorBoundary>
                            <Outlet />
                        </ErrorBoundary>
                    </Box>
                    <TransactionDetailsPanel />
                    <NotificationSnackbar />
                </Box>
            </ReservationModalProvider>
        </ShoppingCartProvider>
    );
};

export default MainLayout;
