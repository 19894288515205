import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DateTimePickerComponent from "./DateTimePickerComponent";
import BasicDatePicker from "./DatePickerComponent";
import { FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { TimeType } from "../models/Report";
import { endOfDay, startOfDay } from "date-fns";

interface Props {
    useTime: boolean;
    singleDate?: boolean;
    values: { start: Date; end?: Date };
    displayTimeType?: boolean;
    timeType?: TimeType;
    setTimeType?: (timeType: TimeType) => void;
    getStartDate: (date: Date | null) => void;
    getEndDate?: (date: Date | null) => void;
    handleUTCChange?: (value: TimeType) => void;
}

const DateRangeComponent = (props: Props) => {
    const handleRadioChange = (value: TimeType) => {
        if (props.handleUTCChange) {
            props.handleUTCChange(value);
        }
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                {props.useTime ? (
                    <DateTimePickerComponent
                        label={props.singleDate ? "Date" : "From"}
                        onDateChanged={props.getStartDate}
                        value={props.values.start}
                    />
                ) : (
                    <BasicDatePicker
                        label={props.singleDate ? "Date" : "From"}
                        onDateChanged={(value) =>
                            props.getStartDate(value === null ? null : startOfDay(value))
                        }
                        value={props.values.start}
                    />
                )}
                {!props.singleDate && props.getEndDate ? (
                    <>
                        {props.useTime ? (
                            <DateTimePickerComponent
                                label="To"
                                onDateChanged={props.getEndDate}
                                value={props.values.end ?? new Date()}
                                minDateTime={props.values.start}
                            />
                        ) : (
                            <BasicDatePicker
                                label="To"
                                onDateChanged={(value) =>
                                    props.getEndDate!(value === null ? null : endOfDay(value))
                                }
                                value={props.values.end ?? new Date()}
                            />
                        )}
                    </>
                ) : null}
                {props.displayTimeType && props.timeType && props.handleUTCChange ? (
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label="time type"
                            name="timeType"
                            value={props.timeType}
                            onChange={(ev, value) => handleRadioChange(value as TimeType)}
                        >
                            <FormControlLabel
                                label="Local time"
                                control={<Radio />}
                                value={TimeType.LOCAL}
                            />
                            <FormControlLabel
                                label="UTC time"
                                control={<Radio />}
                                value={TimeType.UTC}
                            />
                        </RadioGroup>
                    </FormControl>
                ) : null}
            </div>
        </LocalizationProvider>
    );
};

export default DateRangeComponent;
