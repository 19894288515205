import { createUrl, HttpService } from "../http/http";
import { ValidateSchema } from "../utils/ZodValidations";
import {
    CartDTO,
    CartId,
    CartReservationAdjustment,
    GiftCardRecipient,
    PreCheckoutData,
    ReservationTip,
    SendCartEmailResponse,
    SendEmailCartInfo,
    SendEmailData,
    TicketsRequired,
} from "../models/Cart";
import {
    CartDTOSchema,
    GiftCardRecipientSchema,
    PreCheckoutDataSchema,
    SendCartEmailResponseSchema,
    SendEmailCartInfoSchema,
} from "../models/Cart.schema";
import { getCompanyHeader } from "../http/interceptors";
import { CheckoutStripeTransaction } from "../models/Stripe";
import { CheckoutStripeTransactionSchema } from "../models/Stripe.schema";
import { CatalogIDCode, CatalogItem, MiscellaneousItem } from "../models/Catalog";
import { ReservationDetails } from "shared/models/Reservation";

const API_ROUTE = "api/cart";
const PORTAL_ROUTE = "portal/cart";
class ShoppingCartService extends HttpService {
    constructor() {
        super("/");
        //TODO: for later we can remove every *const companyId = getCompanyHeader()* and use a global variable here
    }
    async getCart(cartId: CartId): Promise<CartDTO> {
        const companyId = getCompanyHeader()!;
        const uri = createUrl([API_ROUTE, companyId, cartId]);
        const result = await this.get<CartDTO>(uri);
        return this.validateCartDTO(result.data);
    }
    // async addReservation(cartId: CartId, data:)

    async addReservationToCart(cartId: CartId, r: ReservationDetails): Promise<CartDTO> {
        const companyId = getCompanyHeader()!;
        const uri = createUrl([API_ROUTE, companyId, "reservation", cartId]);
        const result = await this.post<CartDTO>(uri, r);
        return this.validateCartDTO(result.data);
    }

    async addExistingReservation(cartId: CartId, reservationId: number): Promise<CartDTO> {
        let uri = createUrl([PORTAL_ROUTE, "Reservation", reservationId]);
        if (cartId !== "Empty") {
            uri += `/${cartId}`;
        }
        const result = await this.put<CartDTO>(uri, {});
        return this.validateCartDTO(result.data);
    }
    async removeReservation(cartId: CartId, reservationId: number): Promise<CartDTO> {
        // Remove reservation shouldn't happen on empty carts
        if (cartId === "Empty") {
            throw new Error("Cannot remove reservation on an empty cart");
        }
        const companyId = getCompanyHeader()!;
        const uri = createUrl([API_ROUTE, companyId, "reservation", cartId, reservationId]);
        const result = await this.delete<CartDTO>(uri);
        return this.validateCartDTO(result.data);
    }
    async emptyCart(cartId: CartId): Promise<CartDTO> {
        if (cartId === "Empty") {
            throw new Error("Cannot delete an empty cart");
        }
        const companyId = getCompanyHeader()!;
        const uri = createUrl([API_ROUTE, companyId, "Empty", cartId]);
        const result = await this.delete<CartDTO>(uri);
        return this.validateCartDTO(result.data);
    }
    async applyCoupon(cartId: CartId, coupon: string): Promise<CartDTO> {
        const companyId = getCompanyHeader()!;
        const uri = createUrl([API_ROUTE, companyId, "ApplyCode", cartId, coupon]);
        const result = await this.post<CartDTO>(uri, {});
        return this.validateCartDTO(result.data);
    }
    async preCheckout(cartId: CartId): Promise<PreCheckoutData> {
        if (cartId === "Empty") {
            throw new Error("Can checkout only on valid cart");
        }
        const uri = createUrl([API_ROUTE, "PreCheckout", cartId]);
        const result = await this.get<PreCheckoutData>(uri);
        return ValidateSchema<PreCheckoutData>(result.data, PreCheckoutDataSchema);
    }
    private validateCartDTO(data: CartDTO): CartDTO {
        return ValidateSchema<CartDTO>(data, CartDTOSchema);
    }

    async addTransactionPaymentMethod(cartId: string | number, transactionId: number) {
        let uri: string = createUrl([
            PORTAL_ROUTE,
            "AddTransactionPaymentMethod",
            cartId,
            transactionId.toString(),
        ]);
        const result = await this.put(uri, {});
        return result;
    }
    async checkoutTransaction(cartId: string) {
        const uri = createUrl([PORTAL_ROUTE, "CheckoutTransaction", cartId]);
        const result = await this.get<CheckoutStripeTransaction>(uri);
        const data = ValidateSchema<CheckoutStripeTransaction>(
            result.data,
            CheckoutStripeTransactionSchema,
        );
        if (data.checkoutTransaction === null) {
            throw new Error("Incomplete Terminal process");
        }
        return data;
    }
    async addMiscellaneousItem(cartId: CartId, item: MiscellaneousItem): Promise<CartDTO> {
        const uri = createUrl([PORTAL_ROUTE, "AddMiscellaneous", cartId]);
        const result = await this.post<CartDTO, MiscellaneousItem>(uri, item);
        return this.validateCartDTO(result.data);
    }
    async addCatalogItem(cartId: CartId, item: CatalogItem): Promise<CartDTO> {
        const companyId = getCompanyHeader();

        if (!companyId) {
            throw new Error("Can checkout only on valid company");
        }
        const uri = createUrl([API_ROUTE, companyId, "catalog", cartId]);
        const result = await this.post<CartDTO, CatalogItem>(uri, item);
        return this.validateCartDTO(result.data);
    }
    async removeAdjustments(cartId: CartId, reservationId: number): Promise<CartDTO> {
        const uri = createUrl([PORTAL_ROUTE, cartId, "RemoveAdjustments", reservationId]);
        const result = await this.post<CartDTO>(uri, null);
        return this.validateCartDTO(result.data);
    }
    async adjustReservationAmount(
        cartId: CartId,
        reservationId: number,
        amount: number,
    ): Promise<CartDTO> {
        const uri = createUrl([PORTAL_ROUTE, cartId, "AdjustReservationAmount", reservationId]);
        const result = await this.post<CartDTO, CartReservationAdjustment>(uri, {
            amount,
            catalogIdCode: CatalogIDCode.Deposit,
        });
        return this.validateCartDTO(result.data);
    }
    async adjustReservationTickets(
        cartId: string,
        reservationId: number,
        data: TicketsRequired[],
    ): Promise<CartDTO> {
        let uri = createUrl([
            PORTAL_ROUTE,
            cartId,
            "AdjustReservationTickets",
            reservationId.toString(),
        ]);
        const result = await this.post<CartDTO, TicketsRequired[]>(uri, data);
        return this.validateCartDTO(result.data);
    }
    async addTip(cartId: string, tip: ReservationTip): Promise<CartDTO> {
        let uri: string = createUrl([PORTAL_ROUTE, "AddTip", cartId]);
        const result = await this.post<CartDTO, ReservationTip>(uri, tip);
        return this.validateCartDTO(result.data);
    }
    async removeCatalogItem(cartId: string, item: CatalogItem): Promise<CartDTO> {
        const companyId = getCompanyHeader();

        if (!companyId) {
            throw new Error("Can checkout only on valid company");
        }
        let uri = createUrl([
            API_ROUTE,
            companyId,
            "catalog",
            cartId,
            item.catalogId,
            item.reservationIdAddOn ?? "-1",
        ]);
        const result = await this.delete<CartDTO>(uri);
        return this.validateCartDTO(result.data);
    }
    async getSendingInfo(cartId?: string): Promise<SendEmailCartInfo> {
        if (!cartId) {
            throw new Error("Incorrect cartId to use");
        }
        let uri = createUrl([PORTAL_ROUTE, "SendingInfo", cartId]);
        const result = await this.get<SendEmailCartInfo>(uri);
        return ValidateSchema<SendEmailCartInfo>(result.data, SendEmailCartInfoSchema);
    }
    async sendCartEmail(data: SendEmailData): Promise<SendCartEmailResponse> {
        let uri = createUrl([PORTAL_ROUTE, "SendCartEmail"]);
        const result = await this.post<SendCartEmailResponse>(uri, data);
        return ValidateSchema<SendCartEmailResponse>(result.data, SendCartEmailResponseSchema);
    }
    async addGiftCardRecipients(
        cartId: string,
        catalogId: number,
        giftCardInformation: GiftCardRecipient[],
    ): Promise<CartDTO> {
        const companyId = getCompanyHeader();
        if (!companyId) {
            throw new Error("Can add gift card recipient only on valid company");
        }
        const uri = createUrl([API_ROUTE, companyId, "GiftCard", catalogId, cartId]);
        const result = await this.post<CartDTO, GiftCardRecipient[]>(uri, giftCardInformation);
        return this.validateCartDTO(result.data);
    }
    async editGiftCardRecipient(
        catalogId: number,
        cartId: string,
        giftCardInformation: GiftCardRecipient,
    ): Promise<CartDTO> {
        const companyId = getCompanyHeader();
        if (!companyId) {
            throw new Error("Can add gift card recipient only on valid company");
        }
        const uri = createUrl([API_ROUTE, companyId, "GiftCard", catalogId, cartId]);
        const result = await this.put<CartDTO, GiftCardRecipient>(uri, giftCardInformation);
        return this.validateCartDTO(result.data);
    }
    async removeGiftCardRecipient(
        catalogId: number,
        cartId: string,
        recipientId: number,
    ): Promise<CartDTO> {
        const companyId = getCompanyHeader();
        if (!companyId) {
            throw new Error("Can add gift card recipient only on valid company");
        }
        const uri = createUrl([API_ROUTE, companyId, "GiftCard", catalogId, recipientId, cartId]);
        const result = await this.delete<CartDTO>(uri);
        return this.validateCartDTO(result.data);
    }
    async getGiftCardRecipient(cartId: string, catalogId: number, recipientId: number) {
        const companyId = getCompanyHeader();
        if (!companyId) {
            throw new Error("Can add gift card recipient only on valid company");
        }
        const uri = createUrl([API_ROUTE, companyId, "GiftCard", catalogId, recipientId, cartId]);

        const result = await this.get<GiftCardRecipient>(uri);
        return ValidateSchema<GiftCardRecipient>(result.data, GiftCardRecipientSchema);
    }

    async completeRefundReservation(reservationId: number) {
        let uri = createUrl([PORTAL_ROUTE, "ReservationRefund", reservationId]);
        const result = await this.post<CartDTO>(uri, {});
        return this.validateCartDTO(result.data);
    }
}

const shoppingCartService = new ShoppingCartService();

export default shoppingCartService;
