import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { InnerTransactionRow } from "./InnerTransactionRow";
import {
    ReservationTransaction,
    TicketDetail,
    TransactionReservationDetail,
} from "shared/models/Reservation";
import { IRefundReason } from "shared/models/Company";
import { DateTimeUtils, FormatType } from "shared/utils/DateTimeUtils";
import { Button, Chip, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ReactComponent as RefundIconOutlined } from "../../assets/icons/amend_outlined.svg";
import { ReactComponent as RefundIcon } from "../../assets/icons/amend.svg";
import { MathEx } from "shared/utils/MathEx";
import { NotificationsType } from "shared/hooks/useNotification";
import { useReservationModalContext } from "modules/Reservation/context/ReservationModalProvider";
import { useIndexicTheme } from "theme/useIndexicTheme";
import shoppingCartService from "shared/services/ShoppingCartService";
import { LoadingButton } from "@mui/lab";
import "./TransactionTable.css";
import useGlobal from "shared/hooks/GlobalHook";

interface TransactionRowProps {
    data: ReservationTransaction;
    loading: boolean;
    refundReasons: IRefundReason[];
    tickets?: Partial<TicketDetail>[];
    relatedTransactions: ReservationTransaction[];
    handleReturn: (
        quantity: number,
        transaction: ReservationTransaction,
        tickets: TransactionReservationDetail[],
        reason?: IRefundReason,
    ) => void;
    getStacked?: boolean;
    hasRequirtedTickets: boolean;
    isCartEmpty: boolean;
    cartId: string | number;
    companyId: number;
}

export function TransactionRow(props: TransactionRowProps) {
    const { setTransactionID } = useGlobal();
    const theme = useIndexicTheme();
    const { data } = props;
    const { showNotification } = useReservationModalContext();
    const [transactionTickets, setTransactionTickets] = useState<TransactionReservationDetail[]>(
        [],
    );
    const [selectedTransactionTickets, setSelectedTransactionTickets] = useState<
        { quantity: number; ticket: TransactionReservationDetail }[]
    >([]);
    const [open, setOpen] = useState(false);
    const [refund, setRefund] = useState("");
    const [reason, setReason] = useState<number>(props.data.refundReasonId || 0);
    const [reasonText, setReasonText] = useState("--");
    const canRefund = data.canRefund && data.maxRefund > 0;

    useEffect(() => {
        const tranTickets: TransactionReservationDetail[] = [];
        const selectedTickets: {
            quantity: number;
            ticket: TransactionReservationDetail;
        }[] = [];

        (props.data.transactionReservationDetails || []).forEach((tranDetail) => {
            const detail = { ...tranDetail };
            // Needed since TransactionReservationDetails description can be null
            const reservationDetail = (props.tickets || []).find(
                (el) => el.rateId === detail.rateId,
            );
            if (reservationDetail?.description && !detail.description) {
                detail.description = reservationDetail.description;
            }

            if (detail.tickets > 0 && reservationDetail?.tickets) {
								detail.tickets = reservationDetail?.tickets; // Remaining Tickets BUG-3217
                tranTickets.push(detail);
                selectedTickets.push({ ticket: detail, quantity: 0 });
            }
        });

        setTransactionTickets(tranTickets);
        setSelectedTransactionTickets(selectedTickets);
    }, [props.tickets, props.data, props.relatedTransactions]);

    useEffect(() => {
        if (props.data) {
            if (props.data.refundReasonId) {
                const selectedReason = props.refundReasons.find(
                    (el) => el.refundReasonID === props.data.refundReasonId,
                );
                if (selectedReason) {
                    setReasonText(selectedReason.reason);
                }
            } else {
                setReasonText("--");
                setReason(0);
            }
            setRefund("");
        }
    }, [props.data, props.refundReasons]);

    const handleChange = (event: any) => {
        const value = event.target.value;
        updateTotalRefund(value);
    };

    const updateTotalRefund = (value: string | number) => {
        const num = Number(value);
        const limitedValue = num > data.maxRefund ? data.maxRefund : num;
        setRefund(limitedValue.toString());
    };

    const handleReturn = () => {
        const reasonItem = props.refundReasons.find((el) => el.refundReasonID === reason);
        const ticketAdjustments: TransactionReservationDetail[] = [];
        selectedTransactionTickets.forEach((el) => {
            if (el.quantity > 0) {
                ticketAdjustments.push({
                    rateId: el.ticket.rateId,
                    tickets: el.quantity,
                    rate: el.ticket.rate,
                    description: el.ticket.description,
                    questionGroupId: el.ticket.questionGroupId,
                });
            }
        });
        props.handleReturn(Number(refund), data, ticketAdjustments, reasonItem);
    };

    const handleTicketSelection = (quantity: number, ticket: TransactionReservationDetail) => {
        const newSelected = selectedTransactionTickets.map((tkt) =>
            tkt.ticket.rateId === ticket.rateId ? { quantity, ticket } : tkt,
        );
        setSelectedTransactionTickets(newSelected);

        let total = 0;
        newSelected.forEach((element) => {
            const subtotal = element.quantity * element.ticket.rate;
            total = total + subtotal;
        });

        updateTotalRefund(total);
    };

    const formatDate = (isoStr: string) => {
        const date = DateTimeUtils.parse(isoStr);
        return <div>{DateTimeUtils.newFormat(date, FormatType.short) ?? ""}</div>;
    };

    const addTransactionPayment = async () => {
        try {
            await shoppingCartService.addTransactionPaymentMethod(props.cartId, data.transactionId);
            showNotification({
                message: "Payment correctly added",
                type: NotificationsType.success,
            });
            // history.push("/cart");
        } catch {
            showNotification({
                message: "Payment can not be added to the cart",
                type: NotificationsType.error,
            });
        }
    };

    const handleSelectTransaction = (transactionId: number) => {
        setTransactionID(transactionId);
    };

    if (props.getStacked) {
        return (
            <div
                style={{
                    display: "flex",
                    borderBottom: "1px solid #bababa",
                    paddingBottom: ".5rem",
                    lineHeight: "1.5rem",
                    textAlign: "left",
                }}
            >
                <div style={{ width: "1.8rem" }}>
                    {canRefund && transactionTickets && transactionTickets.length > 0 && (
                        <IconButton
                            style={{ marginTop: "4rem" }}
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    )}
                </div>
                <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <th>
                                    <Typography variant="amountSummary">ID</Typography>
                                </th>
                                <th>
                                    <Typography variant="amountSummary">Date</Typography>
                                </th>
                                <th>
                                    <Typography variant="amountSummary">Amount</Typography>
                                </th>
                                <th>
                                    <Typography variant="amountSummary">Max Refund</Typography>
                                </th>
                            </tr>

                            <tr>
                                <td>
                                    <Typography
                                        sx={{
                                            color: "var(--grey-800)",
                                            fontSize: "0.75rem",
                                            fontWeight: 500,
                                            textDecorationLine: "underline",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => handleSelectTransaction(data.transactionId)}
                                    >
                                        {data.transactionId}
                                    </Typography>
                                    {data.cash ? (
                                        <Chip
                                            size="small"
                                            sx={{
                                                backgroundColor: theme.palette.success["lighter"],
                                                fontWeight: 600,
                                                color: "#05691A",
                                                borderRadius: "22.5rem",
                                            }}
                                            label={"CASH"}
                                        />
                                    ) : null}

                                    {data.dispute ? (
                                        <Chip
                                            size="small"
                                            sx={{
                                                backgroundColor: theme.palette.error["lighter"],
                                                fontWeight: 600,
                                                color: "#CC0000",
                                                borderRadius: "22.5rem",
                                                marginLeft: ".6rem",
                                            }}
                                            label={"DISPUTE"}
                                        />
                                    ) : null}
                                </td>
                                <td>{formatDate(data.transactionDate)}</td>
                                <td>
                                    {data.unitPrice < 0 ? (
                                        <span style={{ color: "red" }}>
                                            {MathEx.formatCurrency(data.unitPrice)}
                                        </span>
                                    ) : (
                                        MathEx.formatCurrency(data.unitPrice)
                                    )}
                                </td>
                                <td>{canRefund ? MathEx.formatCurrency(data.maxRefund) : "--"}</td>
                            </tr>

                            {canRefund && data.maxRefund > 0 && (
                                <tr>
                                    <td colSpan={5}>
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "1rem",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    paddingRight: ".8rem",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Typography variant="amountSummary">
                                                    Refund
                                                </Typography>
                                                {!data.dispute && (
                                                    <TextField
                                                        InputProps={{
                                                            style: {
                                                                height: "1.67rem",
                                                            },
                                                        }}
                                                        value={refund}
                                                        onChange={handleChange}
                                                        inputProps={{
                                                            max: data.unitPrice,
                                                            min: 0,
                                                        }}
                                                        type="number"
                                                        variant={"outlined"}
                                                        size="small"
                                                        margin="none"
                                                        style={{
                                                            width: "5rem",
                                                        }}
                                                    />
                                                )}
                                            </div>

                                            <div
                                                style={{
                                                    paddingRight: ".8rem",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Typography variant="amountSummary">
                                                    Reason
                                                </Typography>

                                                {!data.dispute && (
                                                    <>
                                                        {props.refundReasons.length > 0 ? (
                                                            <>
                                                                <Select
                                                                    sx={{
                                                                        ".MuiSvgIcon-root": {
                                                                            color: "var(--dark-aqua-main)",
                                                                        },
                                                                        height: "1.65rem",
                                                                        color: "var(--grey-600)",
                                                                        fontSize: "0.75rem",
                                                                        fontWeight: 400,
                                                                        lineHeight: "150%",
                                                                    }}
                                                                    value={reason}
                                                                    onChange={(event) =>
                                                                        setReason(
                                                                            Number(
                                                                                event.target.value,
                                                                            ),
                                                                        )
                                                                    }
                                                                    style={{
                                                                        marginRight: "10px",
                                                                    }}
                                                                    disabled={
                                                                        !canRefund && !data.dispute
                                                                    }
                                                                >
                                                                    <MenuItem value={0}>
                                                                        Select
                                                                    </MenuItem>
                                                                    {props.refundReasons.map(
                                                                        (el) => (
                                                                            <MenuItem
                                                                                key={
                                                                                    el.refundReasonID
                                                                                }
                                                                                value={
                                                                                    el.refundReasonID
                                                                                }
                                                                            >
                                                                                {el.reason}
                                                                            </MenuItem>
                                                                        ),
                                                                    )}
                                                                </Select>
                                                            </>
                                                        ) : (
                                                            <i>{reasonText}</i>
                                                        )}
                                                    </>
                                                )}
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column-reverse",
                                                }}
                                            >
                                                {canRefund && (
                                                    <LoadingButton
                                                        size="small"
                                                        disabled={!refund || data.dispute}
                                                        loading={props.loading}
                                                        loadingPosition="start"
                                                        startIcon={
                                                            !refund || data.dispute ? (
                                                                <RefundIconOutlined />
                                                            ) : (
                                                                <RefundIcon />
                                                            )
                                                        }
                                                        sx={{
                                                            height: "1.6rem",
                                                        }}
                                                        variant="refundBtn"
                                                        onClick={handleReturn}
                                                    >
                                                        <span>Refund</span>
                                                    </LoadingButton>
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    <table style={{ width: "100%" }}>
                        <tbody>
                            {canRefund && transactionTickets?.length > 0 ? (
                                <tr>
                                    <td style={{ paddingTop: 0 }} colSpan={12}>
                                        <InnerTransactionRow
                                            dispute={data.dispute}
                                            transactionTickets={transactionTickets}
                                            open={open}
                                            handleTicketSelection={handleTicketSelection}
                                            hasRequirtedTickets={props.hasRequirtedTickets}
                                            getStacked={props.getStacked}
                                        />
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } else {
        return (
            <>
                <tr>
                    <td style={{ width: "1.8rem" }}>
                        {canRefund && transactionTickets && transactionTickets.length > 0 ? (
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        ) : null}
                    </td>
                    <td
                        style={{
                            textAlign: "left",
                            display: "inline-flex",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "var(--grey-800)",
                                fontSize: "0.75rem",
                                fontWeight: 500,
                                textDecorationLine: "underline",
                                cursor: "pointer",
                            }}
                            onClick={() => handleSelectTransaction(data.transactionId)}
                        >
                            {data.transactionId}
                        </Typography>
                        {data.cash ? (
                            <Chip
                                size="small"
                                sx={{
                                    backgroundColor: theme.palette.success["lighter"],
                                    fontWeight: 600,
                                    color: "#05691A",
                                    borderRadius: "22.5rem",
                                    marginLeft: ".6rem",
                                }}
                                label={"CASH"}
                            />
                        ) : null}
                        {data.dispute ? (
                            <Chip
                                size="small"
                                sx={{
                                    backgroundColor: theme.palette.error["lighter"],
                                    fontWeight: 600,
                                    color: "#CC0000",
                                    borderRadius: "22.5rem",
                                    marginLeft: ".6rem",
                                }}
                                label={"DISPUTE"}
                            />
                        ) : null}
                    </td>
                    <td style={{ textAlign: "center" }}>{formatDate(data.transactionDate)}</td>
                    <td style={{ textAlign: "right" }}>
                        {data.unitPrice < 0 ? (
                            <span style={{ color: "red" }}>
                                {MathEx.formatCurrency(data.unitPrice)}
                            </span>
                        ) : (
                            MathEx.formatCurrency(data.unitPrice)
                        )}
                    </td>
                    <td style={{ textAlign: "right" }}>
                        {canRefund ? MathEx.formatCurrency(data.maxRefund) : "--"}
                    </td>
                </tr>

                <tr>
                    <td></td>
                    <td colSpan={4}>
                        <div style={{ display: "flex", gap: "1.5rem" }}>
                            {canRefund && !data.dispute ? (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant="amountSummary">REFUND</Typography>
                                    <TextField
                                        InputProps={{
                                            style: {
                                                height: "1.81rem",
                                            },
                                        }}
                                        value={refund}
                                        onChange={handleChange}
                                        inputProps={{ max: data.unitPrice, min: 0 }}
                                        type="number"
                                        variant={"outlined"}
                                        size="small"
                                        margin="none"
                                        style={{ width: "5rem" }}
                                    />
                                </div>
                            ) : null}

                            {canRefund && !data.dispute && data.maxRefund > 0 ? (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant="amountSummary">REASON</Typography>
                                    {props.refundReasons.length > 0 ? (
                                        <Select
                                            sx={{
                                                ".MuiSvgIcon-root": {
                                                    color: "var(--dark-aqua-main)",
                                                },
                                                height: "1.85rem",
                                                color: "var(--grey-600)",
                                                fontSize: "0.75rem",
                                                fontWeight: 400,
                                                lineHeight: "150%",
                                            }}
                                            fullWidth
                                            size="small"
                                            value={reason}
                                            onChange={(event) =>
                                                setReason(Number(event.target.value))
                                            }
                                            disabled={!canRefund && !data.dispute}
                                        >
                                            <MenuItem value={0}>Select</MenuItem>
                                            {props.refundReasons.map((el) => (
                                                <MenuItem
                                                    key={el.refundReasonID}
                                                    value={el.refundReasonID}
                                                >
                                                    {el.reason}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    ) : (
                                        <i>{reasonText}</i>
                                    )}
                                </div>
                            ) : null}

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                }}
                            >
                                {canRefund ? (
                                    <LoadingButton
                                        size="small"
                                        disabled={!refund || data.dispute}
                                        loading={props.loading}
                                        loadingPosition="start"
                                        startIcon={<RefundIcon />}
                                        variant="refundBtn"
                                        onClick={handleReturn}
                                    >
                                        <span>Refund</span>
                                    </LoadingButton>
                                ) : null}
                            </div>
                            {!props.isCartEmpty && data.hasSavedPayment && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column-reverse",
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={() => addTransactionPayment()}
                                    >
                                        Use Payment
                                    </Button>
                                </div>
                            )}
                        </div>
                    </td>
                </tr>
                {canRefund && transactionTickets && transactionTickets.length > 0 ? (
                    <tr>
                        <td colSpan={12}>
                            <InnerTransactionRow
                                dispute={data.dispute}
                                transactionTickets={transactionTickets}
                                open={open}
                                handleTicketSelection={handleTicketSelection}
                                hasRequirtedTickets={props.hasRequirtedTickets}
                                getStacked={props.getStacked}
                            />
                        </td>
                    </tr>
                ) : null}
            </>
        );
    }
}
