import { RateSelectedForLinkStyle } from "modules/EventList/LinkStyleReservation";
import {
    GlobalState,
    toggleOpen,
    transactionDetailsOpen,
    setCompanyId,
    updateEventDate,
    setTransactionId,
    setUnreadMessagesCount,
    setReservationPanelPayload,
} from "store/Globals";
import { useAppDispatch, useAppSelector } from "store/Store";

type Props = {
    globalState: GlobalState;
    setOpenPanel: (flag: boolean) => void;
    setOpenTransactionDetails: (flag: boolean) => void;
    setCompany: (companyId: number) => void;
    setTransactionID: (transactionId: number) => void;
    setUnreadMessages: (unReadMessages: number) => void;
    reloadEventDateDetails: (isEventDateUpdated: boolean) => void;
    addReservationPanelPayload: (
        reservationId: number,
        eventDateId?: number,
        tickets?: RateSelectedForLinkStyle[],
        cartId?: string | null,
    ) => void;
    clearReservationPanelPayload: () => void;
};
const useGlobal = (): Props => {
    const globalState = useAppSelector((state) => state.globals);
    const dispatch = useAppDispatch();

    const setOpenPanel = (flag: boolean) => {
        dispatch(toggleOpen(flag));
    };

    const setOpenTransactionDetails = (flag: boolean) => {
        dispatch(transactionDetailsOpen(flag));
    };

    const setCompany = (companyId: number) => {
        dispatch(setCompanyId(companyId));
    };

    const setTransactionID = (transactionId: number) => {
        dispatch(setTransactionId(transactionId));
    };

    const reloadEventDateDetails = (isEventDateUpdated: boolean) => {
        dispatch(updateEventDate(isEventDateUpdated));
    };

    const setUnreadMessages = (unReadMessages: number) => {
        dispatch(setUnreadMessagesCount(unReadMessages));
    };

    /**
     * The function `addReservationPanelPayload` sets the payload for a reservation panel with the
     * given reservation ID, event date ID, and an optional array of rate selected for link styles.
     * @param {number} reservationId - A number representing the ID of the reservation.
     * @param {number} [eventDateId=0] - The eventDateId parameter is a number that represents the ID
     * of the event date for which the reservation is being made. By default is 0 since you can load reservations without a eventDateId
     * @param {RateSelectedForLinkStyle[]} [tickets] - The `tickets` parameter is an optional array of
     * `RateSelectedForLinkStyle` objects.
     */
    const addReservationPanelPayload = (
        reservationId: number,
        eventDateId: number = 0,
        tickets?: RateSelectedForLinkStyle[],
        cartId?: string | null,
    ) => {
        dispatch(
            setReservationPanelPayload({
                reservationId,
                eventDateId,
                tickets: tickets ?? [],
                cartId: cartId ?? null,
            }),
        );
    };

    /**
     * The clearReservationPanelPayload function clears the reservation panel payload by setting it to
     * null.
     */
    const clearReservationPanelPayload = () => {
        dispatch(setReservationPanelPayload(null));
    };
    return {
        globalState,
        setOpenPanel,
        setOpenTransactionDetails,
        setCompany,
        setTransactionID,
        reloadEventDateDetails,
        addReservationPanelPayload,
        clearReservationPanelPayload,
        setUnreadMessages,
    };
};

export default useGlobal;
