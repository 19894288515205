import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
    SilentRequest,
    InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { msalConfig, scopes } from "../environment/environment";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

export const acquireTokenSilent =
    async (): Promise<AuthenticationResult | null | void> => {
        let response: AuthenticationResult | null = null;
        const account = msalInstance.getActiveAccount();
        if (!account) {
            return null;
        }
        let tokenRequest: SilentRequest = { scopes, account: account };
        try {
            if (msalInstance) {
                response = await msalInstance.acquireTokenSilent(tokenRequest);
            }
        } catch (error) {
            // Lower typescript version won't allow typed catch
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return msalInstance.acquireTokenRedirect({ scopes });
            }
        }
        return response;
    };

export const logout = ()=> {
    return msalInstance.logout();
}