import React, { FunctionComponent } from "react";
import LoadingButtons from "../../../shared/components/LoadingButton";
import useReservation from "../../../shared/hooks/ReservationHook";

export const SaveReservationButton: FunctionComponent = () => {
    const { canSave, loading, saveReservation, dialogContext } = useReservation();
    const handleSave = async () => {
        await saveReservation();
    };
    return (
        <>
            <div data-cy="SaveReservation">
                <LoadingButtons
                    handleClick={handleSave}
                    disabled={!canSave}
                    label="Save reservation"
                    loading={loading}
                />
            </div>
            {dialogContext}
        </>
    );
};
