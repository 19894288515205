import { addMonths, endOfDay, startOfDay, subMonths } from "date-fns";
import { useEffect, useState } from "react";
import { DateTimeUtils } from "shared/utils/DateTimeUtils";
import { Nullable } from "shared/models/Generals";
import { DateRange, DateRangeStored, ReportNames, TimeType } from "../models/Report";

type ReportRow<T> = T & { id?: number | string };

const useReports = <T>() => {
    const [timeType, setTimeType] = useState(TimeType.UTC);
    const [isLoading, setIsLoading] = useState(false);
    const [showNoDataLabel, setShowNoDataLabel] = useState(false);
    const [selectedRow, setSelectedRow] = useState<ReportRow<T> | null>(null);
    const [rows, setRows] = useState<ReportRow<T>[]>([]);
    const [dateRange, setDateRange] = useState<DateRange>({
        start: startOfDay(new Date()),
        end: endOfDay(addMonths(new Date(), 1)),
    });

    useEffect(() => {
        setRows([]);
        setShowNoDataLabel(false);
    }, [dateRange]);

    const changeTimeType = (timeType: TimeType) => {
        setTimeType(timeType);
    };
    const parseDateByTimeType = (date: Date) => {
        if (timeType === TimeType.UTC) {
            return DateTimeUtils.DateToUTC(date);
        }
        return date;
    };
    const setStartDate = (date: Date | null) => {
        if (date !== null) {
            setDateRange((d) => ({ ...d, start: date }));
        }
    };
    const setEndDate = (date: Date | null) => {
        if (date !== null) {
            setDateRange((d) => ({ ...d, end: date }));
        }
    };
    const handlePrintReport = (name: string) => {
        const printable = document.getElementById(name);
        if (!printable) return;
        const w = window.open();
        if (!w) return;
        w.document.write(printable.innerHTML);
        w.print();
        w.close();
    };
    //use this function everytime that you generates the report
    const saveDateRange = (reportName: ReportNames) => {
        const data: DateRangeStored = {
            start: dateRange.start.toISOString(),
            end: dateRange.end.toISOString(),
            type: timeType,
        };
        localStorage.setItem(reportName, JSON.stringify(data));
    };
    const loadDateRange = (reportName: ReportNames) => {
        const data = getDateRange(reportName);
        if (data !== null) {
            const dateR: DateRange = {
                start: new Date(data.start),
                end: new Date(data.end),
            };
            setDateRange(dateR);
            setTimeType(data.type);
        } else {
            // no data saved
            const backDateReports = [
                ReportNames.ReservationsByEmployee,
                ReportNames.Payout,
                ReportNames.AssignedPersonnelSummary,
                ReportNames.SalesByEmployee,
                ReportNames.Commission,
                ReportNames.TicketTypeSummary,
            ];
            if (backDateReports.includes(reportName)) {
                const end = startOfDay(new Date());
                setDateRange({
                    end,
                    start: subMonths(endOfDay(end), 1),
                });
            }
            const singleDateReports = [ReportNames.DailyTransaction, ReportNames.Delivery];
            if (singleDateReports.includes(reportName)) {
                setDateRange({
                    end: endOfDay(new Date()),
                    start: startOfDay(new Date()),
                });
            }
        }
    };
    const getDateRange = (reportName: ReportNames): Nullable<DateRangeStored> => {
        const temp = localStorage.getItem(reportName);
        if (temp) {
            const data = JSON.parse(temp);
            if (data) return data as DateRangeStored;
        }
        return null;
    };

    return {
        timeType,
        dateRange,
        isLoading,
        showNoDataLabel,
        selectedRow,
        rows,
        changeTimeType,
        parseDateByTimeType,
        setStartDate,
        setEndDate,
        setSelectedRow,
        setIsLoading,
        setShowNoDataLabel,
        handlePrintReport,
        saveDateRange,
        loadDateRange,
        setDateRange,
        setRows,
    };
};

export default useReports;
