import React, { FunctionComponent, useEffect, useState } from "react";
import SlidePanel from "shared/components/SlidePanel";
import { useAppSelector } from "../../../store/Store";
import { useShoppingCartSlice } from "../../../store/ShoppingCart/useShoppingCartSlice";
import { GiftCardRecipient } from "shared/models/Cart";
import { Button, Fade, LinearProgress, Typography } from "@mui/material";
import { GiftCardRecipientsSerialized } from "shared/models/GiftCardRecipientsData";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { MathEx } from "shared/utils/MathEx";
import useNotification, { NotificationsType } from "../../../shared/hooks/useNotification";
import { GiftCardRecipientEntry } from "./GiftCardRecipientEntry";

interface GiftCardRecipientListProps {
    data: GiftCardRecipientsSerialized | null;
    handleClose: () => void;
    isNew?: boolean;
}
type RecipientValidation = { recipient: GiftCardRecipient; isValid: boolean };

const prepareGiftCardRecipients = (recipient: GiftCardRecipient): GiftCardRecipient => {
    return {
        recipientEmail: recipient.recipientEmail !== "" ? recipient.recipientEmail : null,
        recipientName: recipient.recipientName !== "" ? recipient.recipientName : null,
        note: recipient.note !== "" ? recipient.note : null,
    };
};
const GiftCardRecipientListContent: FunctionComponent<GiftCardRecipientListProps> = (props) => {
    const { showNotification } = useNotification();
    const { clearGiftCardRecipientPanel } = useShoppingCartSlice();
    const [recipients, setRecipients] = useState<GiftCardRecipient[]>(props.data?.giftCards ?? []);
    const [editedRecipients, setEditedRecipients] = useState<RecipientValidation[]>([]);
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { saveGiftCardRecipients } = useShoppingCartSlice();
    useEffect(() => {
        return () => {
            clearGiftCardRecipientPanel();
            setRecipients([]);
            setEditedRecipients([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setRecipients(props.data?.giftCards ?? []);
    }, [props.data]);

    if (props.data === null) {
        return null;
    }
    const giftCardData = props.data.giftCardData;
    const handleChange = (recipient: GiftCardRecipient, isValid: boolean) => {
        setEditedRecipients((s) => {
            const filtered = s.filter(
                (el) => el.recipient.giftCardRecipientId !== recipient.giftCardRecipientId,
            );
            return [...filtered, { recipient, isValid }];
        });
    };
    const handleSaveAll = async () => {
        setShowError((s) => !s);
        const isValid = editedRecipients.filter((el) => !el.isValid).length === 0;
        console.log({ isValid, editedRecipients, recipients });
        if (!isValid || editedRecipients.length !== recipients.length) {
            return;
        }
        const newRecipients = editedRecipients.map((el) => prepareGiftCardRecipients(el.recipient));
        setLoading(true);
        try {
            await saveGiftCardRecipients(giftCardData.catalogId, newRecipients);
            showNotification({
                message: "Gift cards recipients added",
                type: NotificationsType.success,
            });
            props.handleClose();
        } catch (e) {
            showNotification({
                message: "Recipients not saved, try again later",
                type: NotificationsType.warning,
            });
        } finally {
            setLoading(false);
        }
    };
    const handleRemoveRecipient = (recipient: GiftCardRecipient) => {
        if (props.isNew) {
            const newRecipients = recipients.filter(
                (t) => t.giftCardRecipientId !== recipient.giftCardRecipientId,
            );
            if (newRecipients.length === 0) {
                props.handleClose();
                return;
            }
            setRecipients(newRecipients);
        } else {
            // Existing recipients will be handled by child component
        }
    };

    return (
        <div
            style={{
                height: "100vh",
                padding: "0.5rem 1rem",
            }}
        >
            <Grid container spacing={1}>
                <Grid xs={12}>
                    <Typography variant={"inputLabel"}>
                        {MathEx.formatCurrency(giftCardData.price)} {giftCardData.description}
                    </Typography>
                </Grid>
                <Fade in={loading}>
                    <Grid xs={12}>
                        <LinearProgress />
                    </Grid>
                </Fade>
            </Grid>
            {recipients.map((el, idx) => (
                <GiftCardRecipientEntry
                    giftCardData={giftCardData}
                    isNew={props.isNew}
                    recipient={el}
                    key={el.giftCardRecipientId}
                    index={idx}
                    showError={showError}
                    onChange={handleChange}
                    onRemove={handleRemoveRecipient}
                />
            ))}
            {props.isNew ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button variant={"contained"} onClick={handleSaveAll}>
                        Save recipients
                    </Button>
                </div>
            ) : null}
        </div>
    );
};

const GiftCardRecipientList = () => {
    const giftCardRecipientPanelMessage = useAppSelector(
        (s) => s.shoppingCart.giftCardRecipientPanelMessage,
    );
    const [open, setOpen] = useState(false);
    const [innerData, setInnerData] = useState<GiftCardRecipientsSerialized | null>(null);
    const [isNew, setIsNew] = useState(false);
    useEffect(() => {
        if (giftCardRecipientPanelMessage !== null) {
            setOpen(true);
            setInnerData(giftCardRecipientPanelMessage);
            setIsNew(giftCardRecipientPanelMessage.isNew);
        } else {
            setOpen(false);
            setInnerData(null);
        }
    }, [giftCardRecipientPanelMessage]);

    const handleClose = () => {
        setOpen(false);
        setInnerData(null);
    };
    return (
        <SlidePanel
            title={"Gift card recipients"}
            relativeWidth={90}
            padding={"0"}
            open={open}
            onClose={handleClose}
            children={
                <GiftCardRecipientListContent
                    data={innerData}
                    isNew={isNew}
                    handleClose={handleClose}
                />
            }
        />
    );
};

export default GiftCardRecipientList;
