import { CalendarDate, Event, EventDateTimeItem } from "shared/models/Event";
import { createQueryParams, createUrl, HttpService, QueryParam } from "shared/http/http";
import { ValidateSchema } from "shared/utils/ZodValidations";
import {
    CalendarDateListSchema,
    EventDateTimeItemListSchema,
    EventSchema,
} from "shared/models/Event.schema";
import { DateTimeUtils } from "shared/utils/DateTimeUtils";
import { getCompanyHeader } from "../../http/interceptors";
import { TicketsRequired } from "../../models/Cart";
class EventService extends HttpService {
    constructor() {
        super("/api");
    }
    async getEvent(eventId: number): Promise<Event> {
        const companyId = getCompanyHeader();
        if (!companyId) {
            //TODO: verify action
            throw new Error("Unknown company id");
        }
        const result = await this.get<Event>(`${companyId}/Events/${eventId}`);
        return ValidateSchema<Event>(result.data, EventSchema);
    }
    async getCalendarEventDates(
        eventId: number,
        startDate: Date,
        endDate: Date,
    ): Promise<CalendarDate[]> {
        const companyId = getCompanyHeader();
        if (!companyId) {
            throw new Error("Unknown company id");
        }
        const url = createUrl([companyId, "Events", eventId, "Dates"]);
        const params: QueryParam[] = [
            { key: "startDate", value: DateTimeUtils.toYYYYMMDD(startDate) },
            { key: "endDate", value: DateTimeUtils.toYYYYMMDD(endDate) },
        ];
        const paramsUri = createQueryParams(params);
        const result = await this.get<CalendarDate[]>(url + paramsUri);
        return ValidateSchema<CalendarDate[]>(result.data, CalendarDateListSchema);
    }

    async getAvailableTimes(
        eventId: number,
        date: Date,
        tickets: TicketsRequired[],
    ): Promise<EventDateTimeItem[]> {
        const companyId = getCompanyHeader();
        if (!companyId) {
            throw new Error("Unknown company id");
        }
        const requestString = tickets.map((el) => `${el.rateId}!${el.tickets}`).join(",");
        const queryDate = DateTimeUtils.toYYYYMMDD(date);
        const result = await this.get<EventDateTimeItem[]>(
            `${companyId}/Events/${eventId}/DateTimes/${queryDate}?clientTicketList=${requestString}`,
        );
        return ValidateSchema<EventDateTimeItem[]>(result.data, EventDateTimeItemListSchema);
    }
}
const eventService = new EventService();
export default eventService;
