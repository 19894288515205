import { Grid, SxProps, Typography } from "@mui/material";
import { MathEx } from "shared/utils/MathEx";

interface ReceiptTotalProps {
    subTotal: number;
    serviceCharge: number;
    tax: number;
}
const ReceiptTotal = (props: ReceiptTotalProps) => {
    const styleTitle: SxProps = {
        color: "var(--grey-500)",
        fontSize: "0.625rem",
        fontWeight: 700,
        letterSpacing: "0.0625rem",
        textTransform: "uppercase",
    };
    const styleContent: SxProps = {
        color: "var(--grey-800)",
        fontSize: "0.875rem",
        fontWeight: 500,
    };
    return (
        <Grid container spacing={0}>
            <Grid item xs={6}>
                <Typography sx={styleTitle}>Subtotal:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleContent}>
                    {MathEx.formatCurrency(props.subTotal) ?? ""}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleTitle}>Service Fees:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleContent}>
                    {MathEx.formatCurrency(props.serviceCharge) ?? ""}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleTitle}>Tax: </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleContent}>{MathEx.formatCurrency(props.tax) ?? ""}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={styleTitle}>Total:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography color="primary" sx={{ fontSize: "0.875rem", fontWeight: 500 }}>
                    {MathEx.formatCurrency(
                        Number(props.subTotal + props.serviceCharge + props.tax),
                    )}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ReceiptTotal;
