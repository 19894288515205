import React, { useEffect, useState } from "react";
import eventService from "shared/services/api/EventService";
import { RateSelectedForLinkStyle } from "./LinkStyleReservation";
import { Event, EventDateTimeItem } from "shared/models/Event";
import { Alert, Button, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { IDateTimeAvailableValidation, isDateTimeAvailable } from "shared/business/AvailableTimes";
import useGlobal from "shared/hooks/GlobalHook";
import reservationService from "shared/services/ReservationService";
import { ManageErrorMessage } from "shared/services/ErrorService";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { TicketsRequired } from "shared/models/Cart";

//We added this interface since we need both properties when is a Moving.
//so to have more self explained code when the optional property gets set it will require this two props.
export interface MoveReservationRequiredParams {
    isMovingReservation: boolean;
    reservationId: number;
}
interface Props {
    event: Event;
    date: Date;
    moveReservationRequiredParams?: MoveReservationRequiredParams;
    tickets: RateSelectedForLinkStyle[];
}

interface AvailableTimeElementProps {
    event: Event;
    item: EventDateTimeItem;
    goToReservation: (eventDateId: number) => void;
}
const AvailableTimes = (props: Props) => {
    const [availableTime, setAvailableTimes] = useState<EventDateTimeItem[]>([]);
    const { addReservationPanelPayload } = useGlobal();
    const { showNotification } = useNotification();
    const navigate = useNavigate();
    const [loadingAvailableItems, setLoadingAvailableItems] = useState<boolean>(false);
    useEffect(() => {
        return () => {
            setAvailableTimes([]);
        };
    }, []);

    useEffect(() => {
        getAvailableTimes();
        //eslint-disable-next-line
    }, [props.date]);

    const getAvailableTimes = async () => {
        setLoadingAvailableItems(true);
        const data = await eventService.getAvailableTimes(
            props.event.eventId,
            props.date,
            props.tickets,
        );
        if (data) {
            setAvailableTimes(data);
        }
        setLoadingAvailableItems(false);
    };

    const goToReservation = async (eventDateId: number) => {
        if (
            props.moveReservationRequiredParams &&
            props.moveReservationRequiredParams.isMovingReservation
        ) {
            try {
                await reservationService.moveReservationEvent(
                    eventDateId,
                    props.moveReservationRequiredParams.reservationId,
                    props.tickets as TicketsRequired[],
                );
                showNotification({ message: "Reservation moved", type: NotificationsType.success });
                navigate(`/event/calendar/${props.event.eventId}/${eventDateId}`);
            } catch (error) {
                showNotification({
                    message: ManageErrorMessage(error),
                    type: NotificationsType.warning,
                });
            }
        } else {
            addReservationPanelPayload(-1, eventDateId, props.tickets);
        }
    };
    return (
        <Stack spacing={2}>
            {!loadingAvailableItems ? (
                availableTime.length > 0 ? (
                    <div data-cy="AvailableTimes">
                        <Alert sx={{ marginBottom: 2 }} variant="outlined" severity="info">
                            Select an available time
                        </Alert>
                        {availableTime.map((item) => (
                            <AvailableTimeElement
                                key={item.eventDateID}
                                item={item}
                                event={props.event}
                                goToReservation={goToReservation}
                            />
                        ))}
                    </div>
                ) : (
                    <Typography>There are not times available for the selected date.</Typography>
                )
            ) : (
                <AvailableTimeSkeleton />
            )}
        </Stack>
    );
};

const AvailableTimeElement = (props: AvailableTimeElementProps) => {
    const { globalState } = useGlobal();
    const validationPayload: IDateTimeAvailableValidation = {
        eventEarliestSell: props.event.earliestSell,
        eventLatestSell: props.event.latestSell,
        isPartnerCompany: props.event.companyId !== globalState.companyId,
        eventLatestChange: props.event.latestChange,
        eventDateTimeItem: props.item,
    };
    const { text, timeString, disabled, rateTotalAmount } = isDateTimeAvailable(validationPayload);

    return (
        <div style={{ marginBottom: 5 }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 5,
                    marginBottom: 2,
                }}
            >
                <div data-cy-time={timeString}>
                    <Button
                        disabled={disabled}
                        variant="outlined"
                        onClick={() => props.goToReservation(props.item.eventDateID)}
                    >
                        {timeString}
                    </Button>
                </div>
                <div>
                    {text !== "" ? (
                        <Alert variant="outlined" severity="warning">
                            {text}
                        </Alert>
                    ) : null}
                </div>
                <div>{rateTotalAmount}</div>
            </div>
            <Divider />
        </div>
    );
};
const AvailableTimeSkeleton = () => {
    return (
        <div>
            <div>
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </div>
            <div>
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </div>
            <div>
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </div>
        </div>
    );
};
export default AvailableTimes;
