import { SearchReservationTransaction } from "shared/models/Search";
import { HttpService } from "../http/http";
import { ValidateSchema } from "../utils/ZodValidations";
import { SearchReservationTransactionSchema } from "shared/models/Search.schema";

class SearchService extends HttpService {
    constructor() {
        super("/portal");
    }

    async SearchReservationTransaction(searchId: number) {
        const result = await this.get<SearchReservationTransaction>(
            `Search/${searchId}`,
        );
        return ValidateSchema<SearchReservationTransaction>(
            result.data,
            SearchReservationTransactionSchema,
        );
    }
}

const searchService = new SearchService();

export default searchService;
