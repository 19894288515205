import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import LinkStyleReservation, {
    RateSelectedForLinkStyle,
} from "modules/EventList/LinkStyleReservation";

export interface GlobalState {
    isReservationDetailsOpen: boolean;
    isTransactionDetailsOpen: boolean;
    companyId: number;
    isEventDateUpdated: boolean;
    transactionId: number;
    reservationPanelPayload: LinkStylePayloadProps;
    unReadMessages: number;
}

interface LinkStylePayloadProps {
    reservationId: number;
    eventDateId: number;
    tickets: RateSelectedForLinkStyle[];
    cartId?: string | null;
}
const reservationPanelPayload = {
    reservationId: 0,
    eventDateId: 0,
    tickets: [],
    cartId: null,
};
const initialState: GlobalState = {
    isReservationDetailsOpen: false,
    isTransactionDetailsOpen: false,
    companyId: 0,
    isEventDateUpdated: false,
    transactionId: 0,
    reservationPanelPayload: reservationPanelPayload,
    unReadMessages: 0,
};

const globals = createSlice({
    name: "globals",
    initialState,
    reducers: {
        toggleOpen: (state, param: PayloadAction<boolean>) => {
            state.isReservationDetailsOpen = param.payload;
        },
        transactionDetailsOpen: (state, param: PayloadAction<boolean>) => {
            state.isTransactionDetailsOpen = param.payload;
        },
        setCompanyId: (state, param: PayloadAction<number>) => {
            state.companyId = param.payload;
        },
        setTransactionId: (state, param: PayloadAction<number>) => {
            state.transactionId = param.payload;
        },
        updateEventDate: (state, param: PayloadAction<boolean>) => {
            state.isEventDateUpdated = param.payload;
        },
        setReservationPanelPayload: (state, param: PayloadAction<LinkStylePayloadProps | null>) => {
            state.reservationPanelPayload =
                param.payload !== null ? param.payload : reservationPanelPayload;
        },
        setUnreadMessagesCount: (state, param: PayloadAction<number>) => {
            state.unReadMessages = param.payload;
        },
    },
});

const globalsReducer = globals.reducer;
export const {
    toggleOpen,
    transactionDetailsOpen,
    setCompanyId,
    setTransactionId,
    setUnreadMessagesCount,
    updateEventDate,
    setReservationPanelPayload,
} = globals.actions;
export default globalsReducer;
