import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ContactInfo from "../../shared/components/ContactInfo";
import { Button, Stack, TextField } from "@mui/material";
import { IContactInfo, IContactInfoTemplate } from "shared/models/ContactInfo";
import UserSelector from "./UserSelector";
import { useReservationModalContext } from "./context/ReservationModalProvider";
import { IconActionButton } from "shared/components/IconActionButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import useConfirmationDialog from "shared/components/ConfirmationDialog";
interface Props {
    closePanel?: () => void;
}

interface Information {
    contact: Partial<IContactInfo>;
    comment: string;
    userId: string | null;
}
const EditInformation = (props: Props) => {
    const { reservation, updateReservationData } = useReservationModalContext();
    const { handleOpen, dialogContext } = useConfirmationDialog();
    const [isInformationPasted, setInformationPasted] = useState<boolean>(false);
    const [information, setInformation] = useState<Information>({
        contact: {
            firstName: reservation?.firstName!,
            lastName: reservation?.lastName!,
            email: reservation?.email!,
            phone: reservation?.phone!,
        },
        comment: reservation?.comment ?? "",
        userId: reservation?.userId ?? null,
    });

    const [isValid, setIsValid] = useState<IContactInfoTemplate<boolean>>({
        email: false,
        lastName: false,
        phone: false,
        firstName: false,
    } as IContactInfoTemplate<boolean>);

    useEffect(() => {
        updateReservationData({
            firstName: information.contact.firstName,
            lastName: information.contact.lastName,
            email: information.contact.email,
            phone: information.contact.phone,
            comment: information.comment,
            userId: information.userId,
        });
    }, [information]);

    const addComment = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setInformation({ ...information, comment: ev.target.value });
    };

    const addContact = (contact: Partial<IContactInfo>) => {
        setInformation({ ...information, contact: { ...contact } });
    };

    const addUser = (userId: string | null) => {
        if (userId) {
            setInformation({ ...information, userId });
        }
    };

    const handleCopyContactInformation = () => {
        const { contact } = information;
        localStorage.setItem("contactInformation", JSON.stringify(contact));
    };

    const pasteContactInformation = () => {
        const contact = localStorage.getItem("contactInformation");
        if (contact) {
            const { firstName, lastName, email, phone } = information.contact;
            if (firstName !== "" || lastName !== "" || email !== "" || phone !== "") {
                setInformationPasted(false);
                handleOpen(
                    "This action requires confirmation",
                    "Current displayed Contact Information will be overwritten. Do you want to continue?",
                    [
                        {
                            onAction: () => [
                                setInformation({ ...information, contact: JSON.parse(contact) }),
                                setInformationPasted(true),
                            ],
                            buttonText: "Continue",
                        },
                    ],
                );
                return;
            }
            setInformation({ ...information, contact: JSON.parse(contact) });
            setInformationPasted(true);
        }
    };

    const close = () => {
        if (props.closePanel) {
            props.closePanel();
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid xs={12}>
                <ContactInfo
                    addContact={addContact}
                    isValid={isValid}
                    setIsValid={setIsValid}
                    contactInfo={information.contact}
                    isInformationPasted={isInformationPasted}
                />
            </Grid>
            <Grid xs={12}>
                <TextField
                    id="reservation-comment"
                    label="Comment"
                    name="comment"
                    multiline
                    fullWidth
                    minRows={1}
                    value={information.comment || ""}
                    onChange={addComment}
                />
            </Grid>
            <Grid xs={12}>
                <UserSelector handleUserSelected={addUser} />
            </Grid>
            <Grid xs={12}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <Stack spacing={2} direction="row">
                            <IconActionButton
                                color="primary"
                                size="small"
                                icon={<ContentCopyIcon />}
                                onClick={() => handleCopyContactInformation()}
                                label="Copy info"
                            />
                            <IconActionButton
                                size="small"
                                color="primary"
                                icon={<AssignmentReturnedIcon />}
                                label="Paste"
                                onClick={() => pasteContactInformation()}
                            />
                        </Stack>
                    </div>
                    <div data-cy="SaveInformation">
                        <Stack spacing={2} direction="row">
                            <Button size="small" onClick={() => close()}>
                                Close
                            </Button>
                        </Stack>
                    </div>
                </div>
            </Grid>
            {dialogContext}
        </Grid>
    );
};

export default EditInformation;
