import React, { FunctionComponent, useState } from "react";
import { Button, Fade, LinearProgress } from "@mui/material";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { IconActionButton } from "shared/components/IconActionButton";
import shoppingCartService from "shared/services/ShoppingCartService";
import DialogComponent, { DialogHooks } from "shared/components/DialogComponent";
import { Nullable } from "shared/models/Generals";
import { CartDTO, PreCheckoutData } from "shared/models/Cart";
import checkoutService from "shared/services/CheckoutService";
import { CheckoutConfiguration, CheckoutInfo } from "shared/models/Checkout";
import { CartSummary } from "../../Checkout/components/CartSummary/CartSummary";
import { ReactComponent as RefundIconOutlined } from "../../../assets/icons/amend_outlined.svg";
import { LoadingButton } from "@mui/lab";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import { useQueryClient } from "@tanstack/react-query";

interface RefundCompleteReservationProps {
    reservationId: number;
    refundAmount: number;
}

export const RefundCompleteReservation: FunctionComponent<RefundCompleteReservationProps> = (
    props,
) => {
    const { showNotification } = useNotification();
    const [open, handleClickOpen, handleClose] = DialogHooks();
    const queryClient = useQueryClient();
    const [cart, setCart] = useState<Nullable<CartDTO>>(null);
    const [preCheckout, setPreCheckout] = useState<Nullable<PreCheckoutData>>(null);
    const [loading, setLoading] = useState(false);
    const handleRefundReservation = async () => {
        if (props.refundAmount === 0) {
            showNotification({
                type: NotificationsType.warning,
                message: "Nothing to refund",
            });
            return;
        }
        try {
            const result = await shoppingCartService.completeRefundReservation(props.reservationId);
            setCart(result);
            if (result && result.cartId !== "Empty") {
                const preCheckout = await shoppingCartService.preCheckout(result.cartId);
                setPreCheckout(preCheckout);
            }
            handleClickOpen();
        } catch (e) {
            console.error(e);
        }
    };
    const createCheckoutInfo = () => {
        if (!cart || !preCheckout) return null;
        const { billingInfo, cartDTO, paymentsOnFile } = preCheckout;
        let processorPaymentData = null;
        if (paymentsOnFile && paymentsOnFile.length > 0) {
            processorPaymentData = JSON.stringify({
                transactionId: paymentsOnFile[0].transactionId,
            });
        }
        const info: CheckoutInfo = {
            billingInfo,
            howHeardAnswerId: -1,
            cart: cartDTO,
            processorPaymentData,
        };
        return info;
    };
    const executeCheckout = async () => {
        const config = new CheckoutConfiguration();
        const info = createCheckoutInfo();
        if (info) {
            setLoading(true);
            try {
                const data = await checkoutService.ProcessCheckout(info, config);
                if (data?.errors && data.errors.length > 0) {
                    showNotification({
                        type: NotificationsType.success,
                        message: "Error refunding reservation, try again later",
                    });
                } else {
                    setPreCheckout(null);
                    setCart(null);
                    showNotification({
                        type: NotificationsType.success,
                        message: "Refund completed.",
                    });
                    queryClient.invalidateQueries({
                        queryKey: ["event-date-details"],
                    });
                    queryClient.invalidateQueries({
                        queryKey: ["reservation-details"],
                    });
                    queryClient.invalidateQueries({
                        queryKey: ["calendar-by-event"],
                    });
                }
            } catch (e) {
                showNotification({
                    type: NotificationsType.success,
                    message: "Error refunding reservation, try again later",
                });
                console.error(e);
            } finally {
                setLoading(false);
                handleClose();
            }
        }
    };

    return (
        <>
            <IconActionButton
                icon={<RemoveShoppingCartIcon />}
                onClick={handleRefundReservation}
                label="Refund"
                size="small"
                color={"primary"}
                disabled={props.refundAmount === 0}
                tooltip={props.refundAmount === 0 ? "Nothing to refund" : ""}
            />
            <DialogComponent
                open={open}
                DialogText="Refund reservation"
                handleClose={handleClose}
                customButtons={
                    <>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            Cancel
                        </Button>
                        <LoadingButton
                            onClick={executeCheckout}
                            color="primary"
                            variant={"contained"}
                            startIcon={<RefundIconOutlined />}
                            loading={loading}
                        >
                            Refund
                        </LoadingButton>
                    </>
                }
            >
                {cart !== null ? <CartSummary cart={cart} isRefund={true} /> : null}
                <Fade in={loading}>
                    <LinearProgress />
                </Fade>
            </DialogComponent>
        </>
    );
};
