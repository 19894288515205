import { useEffect, useState } from "react";
import { TransactionRow } from "./TransactionRow";
import "./TransactionTable.css";
import useCompany from "shared/hooks/CompanyHook";
import { IRefundReason } from "shared/models/Company";
import { useReservationModalContext } from "modules/Reservation/context/ReservationModalProvider";
import {
    IRefundResult,
    ReservationTransaction,
    TicketDetail,
    TransactionReservationDetail,
} from "shared/models/Reservation";
import { Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import useGlobal from "shared/hooks/GlobalHook";
import RefundService from "shared/services/RefundService";
import { NotificationsType } from "shared/hooks/useNotification";
import { APIError } from "shared/http/errors";
import { useQueryClient } from "@tanstack/react-query";

export interface TransactionsDisplayProps {
    transactions?: Array<ReservationTransaction>;
    ticketEdits?: Partial<TicketDetail>[];
}

const TransactionsTable = (props: TransactionsDisplayProps) => {
    const { reservation, showNotification, handleActiveProgressBar } = useReservationModalContext();

    const { refundReasons, getRefundReasons } = useCompany();
    const { globalState } = useGlobal();
    const [companyId, setCompanyId] = useState<number>(0);
    // TODO IMPLEMENT CART
    // const { isEmpty, cartId } = useSelector(cart);
    const items = props.transactions || ([] as ReservationTransaction[]);
    // This loading state works for the button loading state
    const [loading, setLoading] = useState(false);
    const wideScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
    const queryClient = useQueryClient();

    useEffect(() => {
        setLoading(false);
    }, [props.transactions]);

    useEffect(() => {
        getRefundReasons();
        setCompanyId(globalState.companyId);
        // eslint-disable-next-line
    }, [globalState.companyId]);

    if (items.length === 0) {
        return <Typography>No transactions for this reservation</Typography>;
    }

    const handleReturn = async (
        quantity: number,
        transaction: ReservationTransaction,
        tickets: TransactionReservationDetail[],
        reason?: IRefundReason,
    ) => {
        setLoading(true);
        handleActiveProgressBar(true);
        try {
            await RefundService.refundReservation(transaction.transactionItemId, quantity, {
                refundReason: reason?.refundReasonID!,
                ticketAdjustments: tickets,
            });

            showNotification({
                message: "Transaction refunded",
                type: NotificationsType.success,
            });
            if (reservation?.reservationId) {
                queryClient.refetchQueries({ queryKey: ["reservation-details"] });
            }
        } catch (err) {
            showNotification({
                message: "Error refunding reservation",
                type: NotificationsType.error,
            });
            if (err instanceof APIError) {
                const data = err.data as IRefundResult;
                if (!data.paymentGatewayResult.success) {
                    data.paymentGatewayResult.errors.forEach((message: string) => {
                        showNotification({
                            message,
                            type: NotificationsType.error,
                        });
                    });
                }
            }
        }

        setLoading(false);
        handleActiveProgressBar(false);
    };

    const buildTransactionRows = (getStacked?: boolean) => {
        return items.map((el) => {
            const relatedTransactions = items.filter(
                (tran) => tran.originalTransactionId === el.transactionId,
            );
            return (
                <TransactionRow
                    key={el.transactionId}
                    loading={loading}
                    data={el}
                    relatedTransactions={relatedTransactions}
                    tickets={props.ticketEdits}
                    handleReturn={handleReturn}
                    refundReasons={refundReasons}
                    getStacked={getStacked}
                    hasRequirtedTickets={
                        reservation?.ticketRequiredFields
                            ? reservation.ticketRequiredFields > 0
                            : false
                    }
                    isCartEmpty={true}
                    cartId={"0"}
                    companyId={companyId}
                />
            );
        });
    };

    return (
        <Grid container style={{ minWidth: "90%", verticalAlign: "top" }}>
            <table className="transaction-table" style={{ minWidth: "90%" }}>
                <tbody>
                    <tr key={-1} style={{ display: wideScreen ? "" : "none" }}>
                        <th></th>
                        <th style={{ textAlign: "left" }}>
                            Reservation <br /> Transactions
                        </th>
                        <th style={{ textAlign: "center" }}>Date</th>
                        <th style={{ textAlign: "right" }}>Amount</th>
                        <th style={{ textAlign: "right" }}>Max Refund</th>
                    </tr>

                    {wideScreen === true ? (
                        buildTransactionRows(false)
                    ) : (
                        <tr>
                            <td>{buildTransactionRows(true)}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Grid>
    );
};

export default TransactionsTable;
