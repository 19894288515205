import { useState } from "react";
import { setCompanyHeader } from "shared/http/interceptors";
import { ICompanySearch, ICompanyUser, IPublicNote, IRefundReason } from "shared/models/Company";
import companyService from "shared/services/CompanyService";
import useGlobal from "./GlobalHook";
import { useAppDispatch, useAppSelector } from "store/Store";
import { setCompanyStore } from "store/Company/CompanySlice";

const useCompany = () => {
    const { setCompany } = useGlobal();
    const [companyUsers, setCompanyUsers] = useState<ICompanyUser[]>([]);
    const [companies, setCompanies] = useState<ICompanySearch[]>([]);
    const [publicNotes, setPublicNotes] = useState<IPublicNote[]>([]);
    const [refundReasons, setRefundReasons] = useState<IRefundReason[]>([]);
    const currentCompany = useAppSelector((state) => state.company.company);
    const dispatch = useAppDispatch();

    const getCompanyUsers = async () => {
        const data = await companyService.getCompanyUsers();
        setCompanyUsers(data);
    };

    const getCompaniesSearch = async (search: string) => {
        const data = await companyService.getCompaniesSearch(search);
        setCompanies(data);
    };

    const getCompany = async (companyId?: number) => {
        if (companyId) {
            setCompanyHeader(companyId.toString());
        }
        const data = await companyService.getCompany();
        dispatch(setCompanyStore(data));
        setCompany(data.companyId);
    };

    const getPublicNotes = async () => {
        const data = await companyService.getCompanyPublicNotes();
        setPublicNotes(data);
    };

    const getRefundReasons = async () => {
        const data = await companyService.getRefundReasons();
        setRefundReasons(data);
    };

    return {
        companyUsers,
        getCompanyUsers,
        companies,
        getCompaniesSearch,
        currentCompany,
        getCompany,
        publicNotes,
        getPublicNotes,
        refundReasons,
        getRefundReasons,
    };
};
export default useCompany;
