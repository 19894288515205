import { HttpService } from "shared/http/http";

export interface IUploadPictureSas {
    serviceUri: string;
    sas: string;
    container: string;
    fileName: string;
}

class UploadService extends HttpService {
    constructor() {
        const companyId = localStorage.getItem("x-indexic-c");
        super(`/api/${companyId}/`);
    }

    async getUploadDetails(reservationId: number) {
        const result = await this.get(`upload/GetUploadDetails/null/${reservationId}`);
        if (result.status === 200) {
            return result.data as { sasData: IUploadPictureSas };
        } else {
            return null;
        }
    }

    async updateImageCount(reservationId: number) {
        return await this.put(`upload/UpdateImageCount/${reservationId}`);
    }
}
const uploadService = new UploadService();
export default uploadService;
