import { Nullable } from "./Generals";
import { DateTimeString } from "./UtilsTypes";

export interface TransactionReport {
    items: Array<TransactionReportAccount>;
    totals: TransactionReportSummary;
    transactions: number;
    transactionFees: number;
}

export interface TransactionReportAccount {
    financialAccount: string;
    summary: TransactionReportSummary;
    transactions: Array<TransactionReportDetail>;
}

export interface TransactionReportDetail {
    description: string;
    transactionId: number;
    billingFirstName: Nullable<string>;
    billingLastName: Nullable<string>;
    transactionDate: string;
    cardProcessorId: number;
    cartCompanyId: number;
    percentCommission: number;
    boost: boolean;
    onlineSale: Nullable<boolean>;
    processorCompanyId: number;
    itemCode: string;
    companyId: number;
    catalogId: number;
    reservationId: number;
    quantity: number;
    unitPrice: number;
    wholesale: number;
    tax: number;
    serviceCharge: number;
    ebmServiceCharge: number;
    financialAccount: string;
    wholesaler: string;
    category: Nullable<string>;
    transactionCategoryId: Nullable<number>;
    ourProcessingFee: number;
    processorFee: number;
    gatewayId: number;
    itemTotal: number;
    wholesaleTotal: number;
    saleType: SaleType;
}

export interface TransactionReportSummary {
    onlineNonTaxable: number;
    onlineTaxable: number;
    posNonTaxable: number;
    posTaxable: number;
    serviceCharge: number;
    directTax: number;
    ebmCommission: number;
    ebmServiceCharge: number;
    refunds: number;
    refundsTaxable: number;
    refundsTax: number;
    wholesaleSales: number;
    wholesaleTax: number;
    retailValue: number;
    boostCommission: number;
    ticketCount: number;
    boostGross: number;
    boostEbmCommission: number;
    cashNonTaxable: number;
    cashTaxable: number;
    cashTaxCollected: number;
    directSales: number;
    totalSales: number;
    netSales: number;
    cashTotalNet: number;
    totalNormalTaxable: number;
    totalTaxable: number;
    totalTaxCollected: number;
}

export enum SaleType {
    Direct,
    Wholesaler,
    Reseller,
}

export interface TicketTypeSummaryReport {
    eventId: number;
    name: string;
    rateDescription: string;
    tickets: number;
    value: number;
}

export interface SalesByEmployeeReport {
    firstName: string;
    lastName: string;
    totalSales: number;
    details: Array<SalesByEmployeeDetail>;
}

export interface SalesByEmployeeDetail {
    description: string;
    itemCode: string;
    reservationId: number;
    unitPrice: number;
    quantity: number;
    total: number;
    transactionDate: string;
}

export enum SalesByEmployeeOption {
    ReservationsAndCatalogItems,
    Reservations,
    CatalogItems,
}

export interface ReservationByEmployeeReport {
    userId: string;
    firstName: string;
    lastName: string;
    eventName: string;
    reservationId: number;
    reservationDate: string;
    paidAmount: number;
    tickets: number;
}

export interface PayoutSummaryReport {
    payoutId: string;
    initiated: string;
    expected: string;
    amount: number;
    status: string;
}

export interface PayoutDetailReport {
    transactionId: number;
    transactionDate: string;
    name: string;
    amount: number;
    fees: number;
    tax: number;
    total: number;
}

export interface DeliveryPickup {
    address: string | null;
    lat: number | null;
    lon: number | null;
}

export interface DeliveryReportReport {
    comment: string | null;
    deliveryPickup: DeliveryPickup;
    checkedIn?: string | Date;
    eventName: string;
    firstName: string;
    lastName: string;
    phone: string | null;
    tickets: number;
    rateDescription: string;
    reservationId: number;
    eventDateTime: string;
    pickup: boolean;
}

export interface CommissionReport {
    transactionId: number;
    transactionDate: string;
    reservationDate: string;
    partnerCompanyName: string;
    description: string;
    name: string;
    commission: number;
}

export interface CashCategoryReportItem {
    transactionId: number;
    transactionDate: DateTimeString;
    name: string;
    category: string;
    transactionCategoryId: Nullable<number>;
    description: string;
    tax: number;
    price: number;
    fee: number;
}
export interface CashCategorySummary {
    category: string;
    transactionCategoryId: Nullable<number>;
    tax: number;
    price: number;
    fee: number;
    rows: CashCategoryReportItem[];
}
export enum ReportNames {
    DailyTransaction = "DailyTransaction",
    AssignedPersonnelSummary = "AssignedPersonnelSummary",
    Commission = "Commission",
    Delivery = "Delivery",
    Payout = "Payout",
    ReservationsByEmployee = "ReservationsByEmployee",
    SalesByEmployee = "SalesByEmployee",
    TicketTypeSummary = "TicketTypeSummary",
}

export enum TimeType {
    UTC = "UTC",
    LOCAL = "LOCAL",
}

export interface DateRange {
    start: Date;
    end: Date;
}

export interface DateRangeStored {
    start: string;
    end: string;
    type: TimeType;
}
