import { IconButton, Typography, IconButtonProps, Tooltip } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";
import { useIndexicTheme } from "../../theme/useIndexicTheme";

interface IconActionButtonProps extends IconButtonProps {
    icon: ReactNode;
    borderColor?: string;
    label?: string;
    tooltip?: string;
    showLabel?: boolean;
}
export const IconActionButton: FunctionComponent<IconActionButtonProps> = ({
    icon,
    label,
    showLabel = true,
    borderColor,
    ...props
}) => {
    const theme = useIndexicTheme();
    const borderC = borderColor ? borderColor : theme.palette.grey["400"];

    return (
        <div
            style={{
                display: "inline-flex",
                flexDirection: "column",
                alignItems: "center",
                paddingRight: 5,
                paddingLeft: 5,
            }}
        >
            <Tooltip title={props.tooltip ?? ""}>
                <span>
                    <IconButton
                        sx={{
                            border: 1,
                            borderRadius: 360,
                            borderColor: borderC,
                        }}
                        aria-label="icon"
                        {...props}
                    >
                        {icon}
                    </IconButton>
                </span>
            </Tooltip>
            {label && showLabel ? <Typography variant="button">{label}</Typography> : null}
        </div>
    );
};
