import React, { ReactElement, createContext, useCallback, useEffect, useState } from "react";
import { MsalAuthenticationTemplate, useIsAuthenticated } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { scopes } from "environment/environment";
import CredentialsService from "shared/services/CredentialsService";
import useCompany from "shared/hooks/CompanyHook";
import { useCredentials } from "store/Credentials/CredencialHook";
import { Typography } from "@mui/material";
import { ReportAccess, SystemAccess } from "../../shared/models/AccessRights";
import { getRolesHeader } from "shared/http/interceptors";

interface AReservationContextProps {
    children?: ReactElement;
}
interface AuthProps {
    userValid: boolean;
    status: string;
}

interface AccessRights {
    isSysAdmin: boolean;
    getSystemAccess: (access: SystemAccess) => boolean;
    getReportAccess: (access: ReportAccess) => boolean;
}

type AuthContextValue = AccessRights & AuthProps;
const AuthContext = createContext<AuthContextValue | undefined>(undefined);

const AuthProvider = ({ children }: AReservationContextProps) => {
    const [aResContext, setAResContext] = useState<AuthProps>({
        userValid: false,
        status: "WaitingOnApiRequest",
    });
    const { credentialsState, setUserCredentials, fetchCredentials } = useCredentials();
    const isAuthenticated = useIsAuthenticated();
    const { currentCompany, getCompany } = useCompany(); // Company Store
    const { isSysAdmin, getSystemAccess, getReportAccess } = useRoles();

    useEffect(() => {
        if (aResContext.status === "WaitingOnApiRequest") {
            GetCredentials(); // Async Call to API for user's credentials and default company
        }
    });

    // Look for Switch Company. Company Preferences are on the Credentials Object
    useEffect(() => {
        if (currentCompany && credentialsState) {
            if (currentCompany.companyId !== credentialsState.credentials?.companyID) {
                fetchCredentials(); // Async call to get Company Preferences
            }
        }
    }, [currentCompany, credentialsState, fetchCredentials]);

    // Here we need to block and perform the Credentials query
    // The results will allow us to set the user's validity for access
    const GetCredentials = async () => {
        // Guard to only call once
        if (isAuthenticated) {
            setAResContext({ userValid: false, status: "ApiRequestComplete" });
            // Will return a UserCredentials object if api call succeeds however
            // success is not binary so still need to inspect the results
            const credentials = await CredentialsService.loadUserCredentials();
            if (credentials && credentials.companyID > 0) {
                setUserCredentials(credentials);
                // Get Company Data now and make page initialization easier.
                // getCompany() should only be called to switch companies
                await getCompany(credentials.companyID);
                setAResContext({ userValid: true, status: "ApiRequestComplete" });
            } // User is authenticated but does not belong to a company.
            else {
                // Could be an invite, inactive, or a non-authorized user
                // AppRouter should handle at this point
                setAResContext({ userValid: false, status: "ApiRequestComplete" });
            }
        }
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{
                scopes: scopes,
                redirectStartPage: window.location.href,
            }}
            loadingComponent={LoadingComponent}
        >
            {aResContext.status !== "ApiRequestComplete" ? (
                <Typography> Validating User Access...</Typography>
            ) : (
                <AuthContext.Provider
                    value={{
                        ...aResContext,
                        isSysAdmin,
                        getReportAccess,
                        getSystemAccess,
                    }}
                >
                    {children}
                </AuthContext.Provider>
            )}
        </MsalAuthenticationTemplate>
    );
};

export default AuthProvider;

export const useAuthContext = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuthContext must be used within a ContextProvider");
    }
    return context;
};

//TODO: work loading component
function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

const useRoles = () => {
    const [isSysAdmin, setIsSysAdmin] = useState(false);
    const [systemAccess, setSystemAccess] = useState<number>(0);
    const [reportAccess, setReportAccess] = useState<number>(0);

    useEffect(() => {
        const rolesHeader = getRolesHeader();
        if (rolesHeader) {
            const accessRights = splitAccessRights(rolesHeader);
            setReportAccess(accessRights[1]);
            setSystemAccess(accessRights[2]);
        }
    }, []);

    const handleRolesUpdate = useCallback(
        (event: StorageEvent) => {
            if (event.key === "roles" && event.newValue) {
                const result = splitAccessRights(event.newValue);
                setIsSysAdmin(result[0] === -1);
                setSystemAccess(result[1]);
                setReportAccess(result[2]);
            }
        },
        [setIsSysAdmin, setSystemAccess, setReportAccess],
    );

    useEffect(() => {
        window.addEventListener("roles", handleRolesUpdate);
        return () => window.removeEventListener("roles", handleRolesUpdate);
    }, [handleRolesUpdate]);

    const getReportAccess = (access: ReportAccess): boolean => {
        // Bitwise operation
        return isSysAdmin ? true : (reportAccess & access) > 0;
    };
    const getSystemAccess = (access: SystemAccess): boolean => {
        // Bitwise operation
        return isSysAdmin ? true : (systemAccess & access) > 0;
    };
    return {
        isSysAdmin,
        getReportAccess,
        getSystemAccess,
    };
};

type AccessRightsRoles = [number, number, number];
const splitAccessRights = (roles: string): AccessRightsRoles => {
    const emptyRoles: AccessRightsRoles = [0, 0, 0];
    if (!roles) {
        return emptyRoles;
    }
    const arr = roles.split("_");
    if (arr.length !== 3) {
        console.error("Malformed roles");
        return emptyRoles;
    }
    return [Number(arr[0]), Number(arr[1]), Number(arr[2])];
};
