import { z } from "zod";
import { InterfaceToZod } from "./UtilsTypes";
import { Rate, RateEffective, RateQuestionGroup } from "./Rates";
import { DateTimeISOValidation } from "../utils/ZodValidations";

export const RateQuestionGroupSchema = z.object<
    InterfaceToZod<RateQuestionGroup>
>({
    questionGroupId: z.number().nullable(),
    questionsJson: z.string().nullable(),
});
export const RateEffectiveSchema = z.object<InterfaceToZod<RateEffective>>({
    rateId: z.number(),
    effectiveFrom: DateTimeISOValidation,
    effectiveTo: DateTimeISOValidation,
    rate: z.number(),
});
export const RateSchema = z.object<InterfaceToZod<Rate>>({
    eventId: z.number(),
    rateId: z.number(),
    description: z.string(),
    durationInSeconds: z.number(),
    maxTickets: z.number(),
    minReservation: z.number(),
    internal: z.boolean(),
    displayOrder: z.number(),
    questionGroupId: z.number().nullable(),
    rateEffectives: z.array(RateEffectiveSchema),
    ticketIncrement: z.number(),
    flatRate: z.boolean().nullable(),
    seatAssingmentCategoryId: z.number().nullable(),
});
