import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import useCompany from "shared/hooks/CompanyHook";
import { ICompanySearch } from "shared/models/Company";
import { Apartment } from "@mui/icons-material";
import { useQueryClient } from "@tanstack/react-query";
import { useCredentials } from "store/Credentials/CredencialHook";

const CompanySelect = () => {
    const { companies, getCompaniesSearch, currentCompany, getCompany } = useCompany();
    const { credentialsState } = useCredentials();
    const [currentCompanySelected, setCurrentCompanySelected] = useState<ICompanySearch>({
        companyId: currentCompany?.companyId ?? 0,
        name: currentCompany?.name ?? "",
    });
    const [companyList, setCompanyList] = useState<ICompanySearch[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const queryClient = useQueryClient();

    useEffect(() => {
        // User Credentials, User Company Preferences, and static Company Data
        // is queried in the aReservationUser Provider and set in the store
        // Get an initial list companies for easy selection
        if (credentialsState.credentials?.systemAdmin) {
            if (currentCompany) {
                setCompanyList((s) => [...s, currentCompany]);
            }
        } else {
            // Build Company List from Credentials
            setCompanyList(
                (credentialsState.credentials?.companies || []).map((item) => ({
                    companyId: item.companyId,
                    name: item.companyName,
                })),
            );
        }
    }, []);

    useEffect(() => {
        // Static Company data should always be available at this point
        if (currentCompany) {
            setCurrentCompanySelected({
                companyId: currentCompany?.companyId,
                name: currentCompany?.name,
            });
        }
        // eslint-disable-next-line
    }, [currentCompany]);

    const handleSelect = async (value: ICompanySearch | null) => {
        if (value !== null) {
            setCurrentCompanySelected(value);
            // if successful will set Company State and trigger Credential and Preferences updates
            await getCompany(value.companyId);
            return queryClient.invalidateQueries();
        }
    };

    // Input will invoke queries for companies
    const onInputChange = async (value: string | null) => {
        // Only System Admins can search all companies
        if (credentialsState.credentials?.systemAdmin && value && value.length >= 2) {
            setIsLoading(true);
            await getCompaniesSearch(value);
            setCompanyList(companies);
            setIsLoading(false);
        }
    };

    return (
        <Autocomplete
            id="select-company"
            sx={{
                minWidth: "150px",
                backgroundColor: "var(--grey-100)",
                color: "var(--grey-800)",
                borderRadius: "0.5rem",
                fontWeight: "500",
            }}
            forcePopupIcon={false}
            size="small"
            disableClearable
            noOptionsText="No company found"
            getOptionLabel={(company: ICompanySearch) => company.name || ""}
            options={companyList}
            onChange={(event: any, newValue: ICompanySearch | null) => {
                handleSelect(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                onInputChange(newInputValue);
            }}
            loading={isLoading}
            value={currentCompanySelected}
            isOptionEqualToValue={(option: ICompanySearch, value: ICompanySearch) =>
                option.companyId === value.companyId
            }
            renderInput={(params) => (
                <TextField
                    style={{
                        color: "var(--grey-800)",
                        fontWeight: "200",
                    }}
                    {...params}
                    onFocus={(e) => e.target.select()}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Apartment style={{ color: "var(--grey-800)" }}></Apartment>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default CompanySelect;
