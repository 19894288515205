import { ReactNode } from "react";
import HomeIcon from "@mui/icons-material/Home";
import {
    BarChart,
    CalendarMonth,
    CardMembership,
    FormatListBulleted,
    People,
    Sms,
} from "@mui/icons-material";
import { ReportAccess, SystemAccess } from "../../../shared/models/AccessRights";

export enum MenuItemType {
    group = "group",
    item = "item",
    collapse = "collapse",
}
export interface MenuItems {
    path: string;
    icon: ReactNode;
    name: string;
    displayOrder: number;
    display: boolean;
    active: boolean;
    hasChildren: boolean;
    type: MenuItemType;
    menu?: MenuItems[];
    reportAccess?: ReportAccess;
    systemAccess?: SystemAccess;
}

const menuItems: MenuItems[] = [
    {
        icon: <HomeIcon />,
        path: "/",
        name: "Home",
        displayOrder: 9,
        display: true,
        active: true,
        hasChildren: false,
        type: MenuItemType.item,
    },
    {
        icon: <CalendarMonth />,
        path: "/event/calendar",
        name: "Reservations",
        displayOrder: 9,
        display: true,
        active: true,
        hasChildren: false,
        type: MenuItemType.item,
    },
    {
        icon: <CalendarMonth />,
        path: "/",
        name: "Resources",
        displayOrder: 9,
        display: true,
        active: false,
        hasChildren: false,
        type: MenuItemType.item,
    },
    {
        icon: <FormatListBulleted />,
        path: "/event/list",
        name: "Event List",
        displayOrder: 9,
        display: true,
        active: true,
        hasChildren: false,
        type: MenuItemType.item,
    },
    {
        icon: <BarChart />,
        path: "/reports",
        name: "Reports",
        displayOrder: 9,
        display: true,
        active: true,
        hasChildren: true,
        type: MenuItemType.group,
        menu: [
            {
                icon: <BarChart />,
                path: "/reports/delivery",
                name: "Delivery",
                displayOrder: 9,
                display: true,
                active: true,
                reportAccess: ReportAccess.Delivery,
                hasChildren: false,
                type: MenuItemType.item,
            },
            {
                icon: <BarChart />,
                path: "/reports/reservations-employees",
                name: "Reservation by employees",
                displayOrder: 9,
                display: true,
                active: true,
                reportAccess: ReportAccess.ReservationsByEmployee,
                hasChildren: false,
                type: MenuItemType.item,
            },
            {
                icon: <BarChart />,
                path: "/reports/transactions",
                name: "Transactions",
                displayOrder: 9,
                display: true,
                active: true,
                reportAccess: ReportAccess.Transaction,
                hasChildren: false,
                type: MenuItemType.item,
            },
            {
                icon: <BarChart />,
                path: "/reports/cash-category",
                name: "Cash category",
                displayOrder: 9,
                display: true,
                active: true,
                reportAccess: ReportAccess.CashCategory,
                hasChildren: false,
                type: MenuItemType.item,
            },
        ],
    },
    {
        icon: <Sms />,
        path: "/textMessages",
        name: "Text Messages",
        displayOrder: 9,
        display: true,
        active: true,
        hasChildren: false,
        type: MenuItemType.item,
    },
    {
        icon: <CalendarMonth />,
        path: "/",
        name: "Work schedule",
        displayOrder: 9,
        display: true,
        active: false,
        hasChildren: false,
        type: MenuItemType.item,
    },
    {
        icon: <People />,
        path: "/admin/users",
        name: "Manage",
        displayOrder: 10,
        display: true,
        active: true,
        systemAccess: SystemAccess.UserAccess,
        hasChildren: false,
        type: MenuItemType.item,
    },
    {
        icon: <CardMembership />,
        path: "/giftcards",
        name: "Gift cards",
        displayOrder: 11,
        display: true,
        active: true,
        hasChildren: false,
        type: MenuItemType.item,
    },
];

export default menuItems;
