import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    Switch,
    Typography,
} from "@mui/material";
import { ICompanyUser } from "shared/models/Company";
import { UserPreferences } from "shared/models/Creds";

interface CredentialsProps {
    data: ICompanyUser;
    handleChange?: (arg: any) => void;
}
const defaultUserPreferences: UserPreferences = {
    defaultCalendarView: 0,
    showZeroTicketTimes: false,
    showInactiveEvents: false,
    user24HourTime: false,
    firstDayOfWeek: 0,
};

const UserCalendarSection = (props: CredentialsProps) => {
    let preferences: UserPreferences;

    if (props.data.userPreferences) {
        preferences = props.data.userPreferences;
    } else {
        preferences = defaultUserPreferences;
    }
    const handleChange = (event: any) => {
        let { name, value } = event.target;
        if (name === "showInactiveEvents" || name === "showZeroTicketTimes") {
            value = !(preferences as any)[name];
        }
        if (name === "defaultCalendarView" || name === "firstDayOfWeek") {
            value = Number(value);
        }
        const data = { [name]: value };
        if (props.handleChange) props.handleChange(data);
    };
    return (
        <div style={{ paddingTop: "1.5rem" }}>
            <Typography variant="h5" gutterBottom>
                Calendar
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={8}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={!preferences.showInactiveEvents}
                                        onChange={handleChange}
                                        name="showInactiveEvents"
                                        color="primary"
                                    />
                                }
                                label="Only display active events"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={!preferences.showZeroTicketTimes}
                                        onChange={handleChange}
                                        name="showZeroTicketTimes"
                                        color="primary"
                                    />
                                }
                                label="Only show events with reservations on All Events Calendar"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth component="fieldset" onChange={handleChange}>
                                <FormLabel component="legend">Default calendar view</FormLabel>

                                <Select
                                    fullWidth
                                    value={preferences.defaultCalendarView}
                                    name="defaultCalendarView"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={0}>Month</MenuItem>
                                    <MenuItem value={1}>Week</MenuItem>
                                    <MenuItem value={2}>Day</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth>
                                <FormLabel component="legend">
                                    First day of week to show on calendars
                                </FormLabel>
                                <Select
                                    value={preferences.firstDayOfWeek}
                                    name="firstDayOfWeek"
                                    onChange={handleChange}
                                    fullWidth
                                >
                                    <MenuItem value={0}>Sunday</MenuItem>
                                    <MenuItem value={1}>Monday</MenuItem>
                                    <MenuItem value={2}>Tuesday</MenuItem>
                                    <MenuItem value={3}>Wednesday</MenuItem>
                                    <MenuItem value={4}>Thursday</MenuItem>
                                    <MenuItem value={5}>Friday</MenuItem>
                                    <MenuItem value={6}>Saturday</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default UserCalendarSection;
