import React, { FunctionComponent, useEffect, useState } from "react";
import { RateTicketPerson } from "shared/models/Rates";
import { RateQuestionParsed } from "./TicketsDisplay";
import { QuestionAnswer, QuestionBase, QuestionsState } from "shared/models/QuestionTypes";
import { isEmpty } from "shared/utils/Common";
import { TicketPerson } from "shared/models/Reservation";
import * as QuestionUtils from "shared/business/Questions";
import { validateRequiredFields } from "shared/business/RequiredFields";
import RequiredFields from "./RequiredFields";
import Questions from "./Questions";

interface TicketsFormProps {
    ticketPerson: RateTicketPerson;
    requiredFields: number;
    rateQuestionGroup: RateQuestionParsed | null;
    handleEdit: (ticket: RateTicketPerson) => void;
    showError: boolean;
}
type Props = TicketsFormProps;

const TicketsForm: FunctionComponent<Props> = (props) => {
    const [showError, setShowError] = useState(false);
    const [questions, setQuestions] = useState<QuestionBase[]>([]);
    const [answers, setAnswers] = React.useState<QuestionAnswer[]>([]);

    useEffect(() => {
        let rateQuestions: QuestionBase[] = [];
        if (!isEmpty(props.rateQuestionGroup?.questions)) {
            if (
                props.rateQuestionGroup?.questions &&
                Array.isArray(props.rateQuestionGroup?.questions)
            ) {
                rateQuestions = props.rateQuestionGroup?.questions;
            }
        }
        const answers =
            isEmpty(props.ticketPerson.parsedAnswers) && props.ticketPerson.parsedAnswers
                ? []
                : props.ticketPerson.parsedAnswers!;
        const [existing, parsed] = QuestionUtils.divideQuestionAnswers(rateQuestions, answers);
        const questions = parsed.length > 0 ? [...rateQuestions, ...parsed] : rateQuestions;
        // Current questions per Rate
        setQuestions(questions);
        // All answers on answers payload
        setAnswers(existing);
    }, [props.rateQuestionGroup, props.ticketPerson]);

    const handleAnswerChange = (answers: QuestionsState) => {
        const newData: RateTicketPerson = {
            ...props.ticketPerson,
            parsedAnswers: answers.questionsWithAnswers,
        };
        props.handleEdit(newData);
    };

    const handleRequiredFields = (ticket: TicketPerson) => {
        const newData: RateTicketPerson = { ...props.ticketPerson, ticket };
        setShowError(!validateRequiredFields(newData.ticket, props.requiredFields));
        props.handleEdit(newData);
    };

    return (
        <div style={{ marginTop: 10 }}>
            <RequiredFields
                requiredFields={props.requiredFields}
                handleAnswers={handleRequiredFields}
                ticketPerson={props.ticketPerson.ticket}
                disabled={false}
                showError={showError}
            />

            <div style={{ marginTop: 20 }}>
                <Questions
                    questions={questions}
                    answers={answers}
                    onAnswerChange={handleAnswerChange}
                    showError={showError}
                />
            </div>
        </div>
    );
};

export default TicketsForm;
