import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ReportAccess } from "shared/models/AccessRights";
import { Each } from "../Each";

export interface IReportAccess {
    None: boolean;
    Transaction: boolean;
    DailyReservations: boolean;
    CashSummary: boolean;
    OutstandingBalances: boolean;
    Partner: boolean;
    SalesByDay: boolean;
    CouponUsage: boolean;
    EmailAddresses: boolean;
    HowHeardReport: boolean;
    RefundReason: boolean;
    Delivery: boolean;
    TicketTypeSummary: boolean;
    ReservationsByEmployee: boolean;
    PayoutReport: boolean;
    AssignedPersonnel: boolean;
    Commission: boolean;
}
interface IReportProps {
    onHadleReturnForm: (form: ReportAccess) => void;
    reportAccess: number;
}

const defaultValues: IReportAccess = {
    None: false,
    Transaction: false,
    DailyReservations: false,
    CashSummary: false,
    OutstandingBalances: false,
    Partner: false,
    SalesByDay: false,
    CouponUsage: false,
    EmailAddresses: false,
    HowHeardReport: false,
    RefundReason: false,
    Delivery: false,
    TicketTypeSummary: false,
    ReservationsByEmployee: false,
    PayoutReport: false,
    AssignedPersonnel: false,
    Commission: false,
};

interface ReportItem {
    label: string;
    key: keyof IReportAccess;
}

const reportItems: ReportItem[] = [
    { key: "None", label: "None" },
    { key: "Transaction", label: "Transaction" },
    { key: "DailyReservations", label: "Daily Reservations" },
    { key: "CashSummary", label: "Cash Summary" },
    { key: "OutstandingBalances", label: "Outstanding Balances" },
    { key: "Partner", label: "Partner" },
    { key: "SalesByDay", label: "Sales By Day" },
    { key: "CouponUsage", label: "Coupon Usage" },
    { key: "EmailAddresses", label: "Email Addresses" },
    { key: "HowHeardReport", label: "How Heard Report" },
    { key: "RefundReason", label: "Refund Reason" },
    { key: "Delivery", label: "Delivery" },
    { key: "TicketTypeSummary", label: "Ticket Type Summary" },
    { key: "ReservationsByEmployee", label: "Reservations By Employee" },
    { key: "PayoutReport", label: "Payout Report" },
    { key: "AssignedPersonnel", label: "Assigned Personnel" },
    { key: "Commission", label: "Commission" },
];
const ReportAccessForm = (props: IReportProps) => {
    const [access, setAccess] = useState<IReportAccess>(defaultValues);

    useEffect(() => {
        functionAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reportAccess]);

    const functionAccess = () => {
        const valuesAccess: IReportAccess = { ...defaultValues };
        Object.keys(valuesAccess).forEach((option) => {
            const key = option as keyof IReportAccess;
            const reportAccessKey = ReportAccess[key];
            let resultReportAccess = (props.reportAccess & reportAccessKey) > 0;

            if (props.reportAccess === ReportAccess.None && reportAccessKey === ReportAccess.None) {
                resultReportAccess = true;
            }
            valuesAccess[key] = resultReportAccess;
        });

        setAccess(valuesAccess);
    };

    useEffect(() => {
        handleRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [access]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        if (name === "None") {
            setAccess({ ...defaultValues, None: true });
        } else {
            setAccess({ ...access, [name]: checked, None: false });
        }
    };

    const handleRoles = () => {
        let _role = props.reportAccess;

        Object.keys(access).forEach((option) => {
            const key = option as keyof IReportAccess;
            _role = access[key] ? _role | ReportAccess[key] : (_role &= ~ReportAccess[key]);
            if (option === "None" && access[key]) {
                _role = ReportAccess.None;
            }
        });

        props.onHadleReturnForm(_role);
    };

    return (
        <Grid container>
            <Grid item md={12}>
                <FormControl component="fieldset" style={{ margin: 3 }}>
                    <FormLabel component="legend">Report Access</FormLabel>
                    <FormGroup>
                        <Each
                            of={reportItems}
                            render={(item, key) => (
                                <FormControlLabel
                                    key={key}
                                    control={
                                        <Checkbox
                                            onChange={handleChange}
                                            checked={access[item.key]}
                                            name={item.key}
                                        />
                                    }
                                    label={item.label}
                                />
                            )}
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default ReportAccessForm;
