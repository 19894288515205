import { Divider, Drawer, List, useMediaQuery } from "@mui/material";
import { Each } from "shared/components/Each";
import menuItems, { MenuItems } from "./Navigation/menuItems";
import NavigationItem from "./Navigation/NavigationItem";
import NavigationGroup from "./Navigation/NavigationGroup";
import "./Drawer.css";
import { useIndexicTheme } from "theme/useIndexicTheme";
import { drawerWidth } from "theme/config";
import { useAuthContext } from "modules/Auth/AuthProvider";

interface Props {
    open: boolean;
    handleDrawerToggle: () => void;
}

const MainDrawer = ({ open, handleDrawerToggle }: Props) => {
    const theme = useIndexicTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { getReportAccess, getSystemAccess } = useAuthContext();
    // Recursive function to traverse nested menu items
    const setActiveItemByAccess = (el: MenuItems): MenuItems => {
        const item: MenuItems = { ...el };
        if (el.menu && el.menu.length > 0) {
            item.menu = el.menu.map(setActiveItemByAccess);
        }
        if (!item.active) {
            return item;
        }
        if (el.reportAccess) {
            item.active = getReportAccess(el.reportAccess);
        }

        if (el.systemAccess) {
            item.active = getSystemAccess(el.systemAccess);
        }

        return item;
    };

    const items = menuItems.map(setActiveItemByAccess);

    return (
        <Drawer
            sx={{
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    borderRight: `1px solid ${theme.palette.divider}`,
                    backgroundImage: "none",
                    boxShadow: "inherit",
                    width: `${drawerWidth}px`,
                },
            }}
            variant={isMobile ? "temporary" : "persistent"}
            anchor="left"
            open={open}
            onClose={handleDrawerToggle}
        >
            <Divider />
            <List className={"main-drawer"}>
                <Each
                    of={items
                        .filter((x) => x.display)
                        .sort((a, b) => a.displayOrder - b.displayOrder)}
                    render={(item: MenuItems, index) =>
                        !item.hasChildren ? (
                            <NavigationItem key={index} {...item} />
                        ) : (
                            <NavigationGroup key={index} {...item} />
                        )
                    }
                />
            </List>
        </Drawer>
    );
};

export default MainDrawer;
