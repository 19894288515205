import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { SystemAccess } from "shared/models/AccessRights";

export interface ISystemAccess {
    None: boolean;
    ReservationView: boolean;
    ReservationAdd: boolean;
    ReservationModify: boolean;
    ReservationDelete: boolean;
    Refund: boolean;
    EventEdit: boolean;
    PersonnelManage: boolean;
    ResourcesManage: boolean;
    CouponsManage: boolean;
    CompanyEditFinancials: boolean;
    CompanyEditNotFinancials: boolean;
    AddDeleteEventDates: boolean;
    UpdateEventDates: boolean;
    WebsiteManage: boolean;
    UserAccess: boolean;
    SysAdmin: boolean;
}

interface ISystemProps {
    onHadleReturnForm: (form: SystemAccess) => void;
    systemAccess: number;
    resetForm?: boolean;
}

const defaultValues: ISystemAccess = {
    None: false,
    ReservationView: false,
    ReservationAdd: false,
    ReservationModify: false,
    ReservationDelete: false,
    Refund: false,
    EventEdit: false,
    PersonnelManage: false,
    ResourcesManage: false,
    CouponsManage: false,
    CompanyEditFinancials: false,
    CompanyEditNotFinancials: false,
    AddDeleteEventDates: false,
    UpdateEventDates: false,
    WebsiteManage: false,
    UserAccess: false,
    SysAdmin: false,
};
const SystemAccessForm = (props: ISystemProps) => {
    const [access, setAccess] = useState<ISystemAccess>(defaultValues);

    useEffect(() => {
        functionAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.resetForm]);

    const functionAccess = () => {
        const valuesSystem: ISystemAccess = { ...defaultValues };
        Object.keys(valuesSystem).forEach((option) => {
            const key = option as keyof ISystemAccess;
            const systemAccessKey = (SystemAccess as any)[key];
            if (props.systemAccess !== SystemAccess.SysAdmin) {
                let resultSystemAccess = (props.systemAccess & systemAccessKey) > 0;
                if (
                    props.systemAccess === SystemAccess.None &&
                    systemAccessKey === SystemAccess.None
                ) {
                    resultSystemAccess = true;
                }

                if (option === "SysAdmin") {
                    resultSystemAccess = false;
                }
                valuesSystem[key] = resultSystemAccess;
            } else {
                let resultSystemAccess = true;
                if (systemAccessKey === SystemAccess.None) {
                    resultSystemAccess = false;
                }
                valuesSystem[key] = resultSystemAccess;
            }
        });

        setAccess(valuesSystem);
    };

    useEffect(() => {
        handleRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [access]);

    const handleRoles = () => {
        let _role = props.systemAccess;
        const maxValue = 1073745919; // Max value of all permissions: Change when ENUM system change
        if (_role === SystemAccess.SysAdmin) {
            _role = maxValue;
        }
        Object.keys(access).forEach((option) => {
            const key = option as keyof ISystemAccess;
            if (option === "SysAdmin" && access[key]) {
                _role = SystemAccess.SysAdmin;
            }

            if (option !== "SysAdmin") {
                _role = access[key]
                    ? _role | (SystemAccess as any)[key]
                    : (_role &= ~(SystemAccess as any)[key]);
            }

            if (option === "None" && access[key]) {
                _role = SystemAccess.None;
            }
        });

        props.onHadleReturnForm(_role);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        let state: ISystemAccess = defaultValues;
        switch (name) {
            case "ReservationAdd":
                if (checked) {
                    state = {
                        ...access,
                        ReservationAdd: true,
                        ReservationView: true,
                        SysAdmin: false,
                        None: false,
                    };
                } else {
                    state = {
                        ...access,
                        ReservationAdd: false,
                    };
                }
                break;
            case "ReservationModify":
                if (checked) {
                    state = {
                        ...access,
                        ReservationModify: true,
                        ReservationView: true,
                        ReservationAdd: true,
                        SysAdmin: false,
                        None: false,
                    };
                } else {
                    state = {
                        ...access,
                        ReservationModify: false,
                    };
                }
                break;
            case "ReservationDelete":
                if (checked) {
                    state = {
                        ...access,
                        ReservationDelete: true,
                        ReservationView: true,
                        ReservationModify: true,
                        SysAdmin: false,
                        None: false,
                    };
                } else {
                    state = {
                        ...access,
                        ReservationDelete: false,
                    };
                }
                break;
            case "None":
                state = { ...defaultValues, None: true };
                break;
            case "SysAdmin":
                if (checked) {
                    state = {
                        None: false,
                        ReservationView: true,
                        ReservationAdd: true,
                        ReservationModify: true,
                        ReservationDelete: true,
                        Refund: true,
                        EventEdit: true,
                        PersonnelManage: true,
                        ResourcesManage: true,
                        CouponsManage: true,
                        CompanyEditFinancials: true,
                        CompanyEditNotFinancials: true,
                        AddDeleteEventDates: true,
                        UpdateEventDates: true,
                        WebsiteManage: true,
                        UserAccess: true,
                        SysAdmin: true,
                    };
                } else {
                    state = { ...access, SysAdmin: false };
                }
                break;
            default:
                if (checked) {
                    state = { ...access, [name]: checked, None: false };
                } else {
                    state = { ...access, [name]: checked, SysAdmin: false };
                }
                break;
        }

        setAccess(state);
    };

    return (
        <Grid container style={{ display: "flex" }}>
            <Grid item md={12}>
                <FormControl component="fieldset" style={{ margin: 3 }}>
                    <FormLabel component="legend">System Access</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.None}
                                    name="None"
                                />
                            }
                            label="None"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.ReservationView}
                                    name="ReservationView"
                                />
                            }
                            label="View Reservations"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.ReservationAdd}
                                    name="ReservationAdd"
                                />
                            }
                            label="Add Reservations"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.ReservationModify}
                                    name="ReservationModify"
                                />
                            }
                            label="Modify Reservations"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.ReservationDelete}
                                    name="ReservationDelete"
                                />
                            }
                            label="Delete Reservations"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.WebsiteManage}
                                    name="WebsiteManage"
                                />
                            }
                            label="Modify Website Manage"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.Refund}
                                    name="Refund"
                                />
                            }
                            label="Refund"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.EventEdit}
                                    name="EventEdit"
                                />
                            }
                            label="Edit Events"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.PersonnelManage}
                                    name="PersonnelManage"
                                />
                            }
                            label="Manage Personnel"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.ResourcesManage}
                                    name="ResourcesManage"
                                />
                            }
                            label="Manage Resources"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.CouponsManage}
                                    name="CouponsManage"
                                />
                            }
                            label="Manage Coupons"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.CompanyEditFinancials}
                                    name="CompanyEditFinancials"
                                />
                            }
                            label="Edit company financial information"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.CompanyEditNotFinancials}
                                    name="CompanyEditNotFinancials"
                                />
                            }
                            label="Edit company non financial information"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.UserAccess}
                                    name="UserAccess"
                                />
                            }
                            label="Manage User Access"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.AddDeleteEventDates}
                                    name="AddDeleteEventDates"
                                />
                            }
                            label="Add/Delete Events"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.UpdateEventDates}
                                    name="UpdateEventDates"
                                />
                            }
                            label="Update Events"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleChange}
                                    checked={access.SysAdmin}
                                    name="SysAdmin"
                                />
                            }
                            label="Full Company Administration"
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default SystemAccessForm;
