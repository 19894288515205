import React, { useState } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { MathEx } from "shared/utils/MathEx";

interface RefundAmountFieldProps {
    value: number;
    max: number;
    onChange: (data: number) => void;
}

export const RefundAmountField = (props: RefundAmountFieldProps) => {
    const [error, setError] = useState("");
    const handleOnChange: TextFieldProps["onChange"] = (e) => {
        const { value } = e.target;
        const parsedValue = Number(value);
        if (parsedValue > props.max) {
            setError(`Max refundable amount is ${MathEx.formatCurrency(props.max)}`);
        } else {
            setError("");
        }
        props.onChange(parsedValue);
    };
    return (
        <TextField
            size="small"
            fullWidth
            value={Number(props.value)}
            type="number"
            onChange={handleOnChange}
            onFocus={(e) => e.target.select()}
            inputProps={{ max: props.max, min: 0 }}
            helperText={error}
            error={error !== ""}
            sx={{ marginBottom: "1rem" }}
            disabled={props.max <= 0}
        />
    );
};
