import React from "react";
import { CartReservation } from "shared/models/Cart";
import { MathEx } from "../../../shared/utils/MathEx";
import useGlobal from "../../../shared/hooks/GlobalHook";
import { useShoppingCartProvider } from "../ShoppingCartProvider";
import { useShoppingCartSlice } from "../../../store/ShoppingCart/useShoppingCartSlice";
import { FlatButton } from "shared/components/FlatButton";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CatalogItemCart } from "./CatalogItemCart";
import { getShoppingCartNotifications } from "../ShoppingCartNotifications";
import useNotification from "../../../shared/hooks/useNotification";
import { ShoppingCartAdjustments } from "./ShoppingCartAdjustments/ShoppingCartAdjustments";
export enum AdjustmentActions {
    none = "none",
    split = "split",
    coupon = "coupon",
    deposit = "deposit",
    group_discount = "group_discount",
    other_discount = "other_discount",
    surcharge = "surcharge",
}

interface ReservationItemEntryProps {
    reservation: CartReservation;
    totalCartDiscount: number;
    handleActions?: (
        reservation: CartReservation,
        action: AdjustmentActions,
        quantity: number | null,
    ) => void;
    handleDelete?: (reservation: CartReservation) => void;
}

const ReservationItemCart = (props: ReservationItemEntryProps) => {
    const reservation = props.reservation;
    const { addReservationPanelPayload } = useGlobal();
    const { toggleShoppingCartModal } = useShoppingCartProvider();
    const { removeReservation, cart } = useShoppingCartSlice();
    const { showNotification } = useNotification();
    const handleEditReservation = () => {
        toggleShoppingCartModal(false);
        addReservationPanelPayload(reservation.reservationId, 0, [], cart?.cartId);
    };
    const handleRemoveReservation = async () => {
        const notifications = getShoppingCartNotifications("RemoveReservation");
        try {
            await removeReservation(reservation.reservationId);
            showNotification(notifications.success);
        } catch (e) {
            showNotification(notifications.error);
        }
    };
    return (
        <Grid container spacing={1} style={{ padding: "0.5rem 0" }}>
            <Grid xs={9}>
                <div style={{ display: "flex", gap: "0.5rem" }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                        <FlatButton onClick={handleEditReservation} color={"primary"}>
                            Edit
                        </FlatButton>
                        <FlatButton color={"error"} onClick={handleRemoveReservation}>
                            Remove
                        </FlatButton>
                    </div>
                    <div>
                        <ReservationContactData reservation={reservation} />
                        <ShoppingCartAdjustments reservation={props.reservation} />
                    </div>
                </div>
            </Grid>
            <Grid xs={3}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <Typography
                        variant={"headerExpandableTitle"}
                        component={"p"}
                        style={{ marginBottom: "0.5rem" }}
                    >
                        Total
                    </Typography>
                    <Typography variant={"inputLabel"}>
                        {MathEx.formatCurrency(reservation.price ?? 0)}
                    </Typography>
                </div>
            </Grid>
        </Grid>
    );
};

const ReservationItemCartWrapper = (props: ReservationItemEntryProps) => {
    return (
        <div style={{ borderBottom: "1px solid var(--grey-300)", marginBottom: "0.5rem" }}>
            <ReservationItemCart {...props} />
            {props.reservation.addOns.map((el, idx) => (
                <CatalogItemCart
                    item={el}
                    isAddon={true}
                    key={`catalog-${idx}`}
                    reservationId={props.reservation.reservationId}
                />
            ))}
        </div>
    );
};
export default ReservationItemCartWrapper;

interface ReservationContactDataProps {
    reservation: CartReservation;
}
const ReservationContactData = (props: ReservationContactDataProps) => {
    const { reservation } = props;
    const reservationName = `${reservation.contact?.firstName || ""} ${
        reservation.contact?.lastName || ""
    }`;
    const lines = reservation.description.split("\n");
    const eventName = lines[0];
    const date = lines[1];
    return (
        <div>
            <Typography variant={"headerExpandableTitle"}>{eventName}</Typography>
            <Typography variant={"inputLabel"}>{reservationName}</Typography>
            <Typography>{date}</Typography>
            <ul style={{ margin: 0 }}>
                {reservation.rates.map((el, key) => (
                    <li key={key}>
                        {el.tickets} {el.description}
                    </li>
                ))}
            </ul>
        </div>
    );
};
