// Copied from aResDB/Models/Roles.cs
export enum SystemAccess {
    None = 0,
    ReservationView = 1 << 0,
    ReservationAdd = 1 << 1,
    ReservationModify = 1 << 2,
    ReservationDelete = 1 << 3,
    Refund = 1 << 4,
    EventEdit = 1 << 5,
    PersonnelManage = 1 << 6,
    ResourcesManage = 1 << 7,
    CouponsManage = 1 << 8,
    CompanyEditFinancials = 1 << 9,
    CompanyEditNotFinancials = 1 << 10,
    AddDeleteEventDates = 1 << 11,
    UpdateEventDates = 1 << 12,
    UserAccess = 1 << 30,
    SysAdmin = -1, // All bits on
}

export enum ReportAccess {
    None = 0,
    Transaction = 1 << 1,
    DailyReservations = 1 << 2,
    CashSummary = 1 << 3,
    OutstandingBalances = 1 << 4,
    Partner = 1 << 5,
    SalesByDay = 1 << 6,
    CouponUsage = 1 << 7,
    EmailAddresses = 1 << 8,
    HowHeardReport = 1 << 9,
    RefundReason = 1 << 10,
    Delivery = 1 << 11,
    TicketTypeSummary = 1 << 12,
    ReservationsByEmployee = 1 << 13,
    PayoutReport = 1 << 14,
    AssignedPersonnel = 1 << 15,
    Commission = 1 << 16,
    CashCategory = 1 << 17,
}
