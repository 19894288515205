import React, { FunctionComponent, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Fade,
    Grid,
    LinearProgress,
    MenuItem,
    Select,
    TextField,
    Typography,
    TextFieldProps,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import ShoppingCartService from "shared/services/ShoppingCartService";
import { useAppSelector } from "store/Store";
import { SendEmailData } from "shared/models/Cart";
import { useShoppingCartSlice } from "store/ShoppingCart/useShoppingCartSlice";
import { MuiTelInput } from "mui-tel-input";
import { Locale } from "shared/utils/Locale";
import { Validation } from "shared/utils/Validation";

interface SendCartButtonDialogProps {
    open: boolean;
    onCartSendSuccess: () => void;
    onCartCancel: () => void;
}

export const SendCartButtonDialog: FunctionComponent<SendCartButtonDialogProps> = (props) => {
    const { cart, clearCart } = useShoppingCartSlice();
    const query = useQuery(
        ["send-cart-info", cart?.cartId],
        () => ShoppingCartService.getSendingInfo(cart?.cartId),
        {
            enabled: Boolean(cart?.cartId && cart?.cartId !== "Empty") && props.open,
            placeholderData: {
                email: "",
                phone: "",
                companyFriendlyNames: [],
            },
        },
    );
    useEffect(() => {
        const companyFriendlyNames = query.data?.companyFriendlyNames || [];
        if (query.isSuccess && companyFriendlyNames.length > 0) {
            setSelectedFriendlyName(companyFriendlyNames[0]);
        }
    }, [query.isSuccess, query.data]);

    useEffect(() => {
        if (cart && cart?.reservations.length > 0) {
            // Will return first reservation with an email or phone
            const reservation = cart.reservations.find(
                (el) => el.contact.email || el.contact.phone,
            );
            if (reservation) {
                setCartEmail(reservation.contact?.email ?? "");
                setCartPhone(reservation.contact?.phone ?? "");
            }
        }
    }, [cart]);

    const [loading, setLoading] = useState(false);
    const hasTextingModule = useAppSelector(
        (s) => s.company.company?.textingModuleEnabled ?? false,
    );

    const [cartEmail, setCartEmail] = useState("");
    const [cartPhone, setCartPhone] = useState("");
    const [emailErrorMsg, setEmailErrorMsg] = useState("");
    const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
    const [selectedFriendlyName, setSelectedFriendlyName] = useState("");

    const emailCart = async () => {
        if (cartEmail === "") {
            setEmailErrorMsg(Validation.Email(cartEmail));
            return;
        }
        if (emailErrorMsg === "" && phoneErrorMsg === "" && cart !== null) {
            setLoading(true);
            const data: SendEmailData = {
                email: cartEmail,
                companyUrlFriendlyName: selectedFriendlyName,
                phone: cartPhone === "" ? null : cartPhone,
                cartId: cart?.cartId,
            };
            try {
                const result = await ShoppingCartService.sendCartEmail(data);
                if (result.success) {
                    props.onCartSendSuccess();
                    clearCart();
                }
            } catch (e) {
                // show notification
            } finally {
                setLoading(false);
            }
        }
    };

    const handlePhoneChange = (phone: string) => {
        const errorMsg = phone === "" ? "" : Validation.PhoneNumber(phone);
        setPhoneErrorMsg(errorMsg);
        setCartPhone(phone);
    };

    const handleEmailChange: TextFieldProps["onChange"] = (e) => {
        setEmailErrorMsg(Validation.Email(e.target.value));
        setCartEmail(e.target.value);
    };

    const companyFriendlyNames = query.data?.companyFriendlyNames || [];
    return (
        <Dialog onClose={props.onCartCancel} open={props.open}>
            <DialogTitle
                sx={{
                    background: (t) => t.palette.grey["200"],
                }}
            >
                <Typography variant={"inputLabel"}>Send cart</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Fade in={loading || query.isLoading}>
                            <LinearProgress />
                        </Fade>
                    </Grid>
                    <Grid item xs={12} sm={hasTextingModule ? 6 : 12}>
                        <Typography>Email to send the cart</Typography>
                        <TextField
                            required
                            fullWidth
                            value={cartEmail}
                            disabled={loading}
                            size={"small"}
                            error={emailErrorMsg !== ""}
                            helperText={emailErrorMsg}
                            onChange={handleEmailChange}
                        />
                    </Grid>
                    {hasTextingModule ? (
                        <Grid item xs={12} sm={6}>
                            <Typography>Phone to send the cart</Typography>
                            <MuiTelInput
                                required
                                value={cartPhone}
                                onChange={handlePhoneChange}
                                variant="outlined"
                                disableFormatting
                                forceCallingCode
                                defaultCountry={"US"}
                                fullWidth
                                error={phoneErrorMsg !== ""}
                                helperText={phoneErrorMsg}
                                InputProps={{
                                    autoComplete: "off",
                                }}
                                size="small"
                            />
                        </Grid>
                    ) : null}
                    {query.isSuccess && companyFriendlyNames.length > 1 ? (
                        <Grid item xs={12}>
                            <Typography style={{ marginTop: "10px" }}>
                                Select a company website
                            </Typography>
                            <Select
                                size={"small"}
                                value={selectedFriendlyName}
                                onChange={(e) => setSelectedFriendlyName(e.target.value as string)}
                                fullWidth
                            >
                                {companyFriendlyNames.map((el, idx) => (
                                    <MenuItem key={idx} value={el}>
                                        {el}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    ) : null}
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    background: (t) => t.palette.grey["100"],
                    borderTopWidth: "1px",
                    borderTopStyle: "solid",
                    borderTopColor: (t) => t.palette.grey["300"],
                }}
            >
                <Button
                    variant={"text"}
                    sx={{
                        color: (t) => t.palette.grey["600"],
                    }}
                    onClick={props.onCartCancel}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color={"primary"}
                    variant={"contained"}
                    autoFocus
                    onClick={emailCart}
                >
                    Send Cart
                </Button>
            </DialogActions>
        </Dialog>
    );
};
