import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { IconActionButton } from "shared/components/IconActionButton";
import EventsService from "shared/services/EventsService";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { PortalEventSummary } from "shared/models/Event";
import DialogComponent from "shared/components/DialogComponent";
import {
    Alert,
    Button,
    IconButton,
    InputAdornment,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    TextField,
    TextFieldProps,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ArrowDownward } from "@mui/icons-material";
import { Each } from "shared/components/Each";
import SlidePanel from "shared/components/SlidePanel";
import LinkStyleReservation from "modules/EventList/LinkStyleReservation";
import { useAuthContext } from "modules/Auth/AuthProvider";
import { SystemAccess } from "shared/models/AccessRights";

interface Props {
    currentEventId: number;
    reservationId: number;
}
type Searchable<T> = { searchable: string; data: T };
const MoveReservationEvent = (props: Props) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [eventList, setEventList] = useState<Searchable<PortalEventSummary>[]>([]);
    const [value, setValue] = useState("");
    const [selectedEvent, setSelectedEvent] = useState<PortalEventSummary | null>(null);
    const [currentEventName, setCurrentEventName] = useState("");
    const [openLinkStyle, setOpenLinkStyle] = useState(false);
    const { getSystemAccess } = useAuthContext();
    const havePermissionToModifyReservation = getSystemAccess(SystemAccess.ReservationModify);

    const summariesQuery = useQuery(
        ["portal-summaries"],
        () => EventsService.getPortalSummaries(),
        {
            enabled: openDialog,
        },
    );

    useEffect(() => {
        if (summariesQuery.data) {
            const searchableEvents: Searchable<PortalEventSummary>[] = summariesQuery.data.events
                .map((el) => ({
                    searchable: `${el.name.toLowerCase()}${el.eventId}${el.eventCode}`,
                    data: el,
                }))
                .filter((el) => el.data.eventId !== props.currentEventId);
            const event = summariesQuery.data.events.find(
                (x) => x.eventId === props.currentEventId,
            );
            if (event) {
                setCurrentEventName(event.name);
            }
            if (value !== "") {
                const searchVal = value.toLowerCase().trim();
                setEventList(searchableEvents.filter((el) => el.searchable.includes(searchVal)));
            } else {
                setEventList(searchableEvents);
            }
        }
    }, [value, summariesQuery.data]);

    const handleOpenModal = () => {
        setOpenDialog(true);
        setSelectedEvent(null);
    };

    const handleUpdateValue: TextFieldProps["onChange"] = (e) => {
        setValue(e.target.value);
    };
    const handleSelectEvent = (evt: typeof selectedEvent) => {
        setSelectedEvent(evt);
    };
    return (
        <>
            <IconActionButton
                icon={<CompareArrowsIcon />}
                onClick={() => handleOpenModal()}
                label="Move"
                size="small"
                disabled={!havePermissionToModifyReservation}
            />
            <DialogComponent
                DialogText="Search"
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
            >
                {selectedEvent === null ? (
                    <>
                        <div className="flex">
                            <TextField
                                style={{ flexBasis: "100%" }}
                                fullWidth
                                label="Search"
                                type="text"
                                size="small"
                                onChange={handleUpdateValue}
                                value={value}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            onClick={() => [setValue(""), setSelectedEvent(null)]}
                                        >
                                            <IconButton aria-label="delete">
                                                <CloseIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <Alert severity="info" style={{ margin: "10px 0" }}>
                            Select an event
                        </Alert>
                        <div style={{ height: "50vh", width: "60vw", overflowY: "scroll" }}>
                            {summariesQuery.isLoading && <LinearProgress />}
                            <List>
                                <Each
                                    of={eventList}
                                    render={(el, index) => (
                                        <ListItem onClick={() => handleSelectEvent(el.data)}>
                                            <ListItemText
                                                primary={`${el.data.name}`}
                                                secondary={`EventId: ${el.data.eventId}`}
                                            />
                                        </ListItem>
                                    )}
                                />
                            </List>
                        </div>
                    </>
                ) : (
                    <div style={{ height: "50vh" }}>
                        <Typography>
                            Your reservation{" "}
                            <b>{props.reservationId ? props.reservationId : "--"}</b> will move to
                            another event:{" "}
                        </Typography>
                        <Alert severity="info" style={{ margin: "10px 0" }}>
                            {currentEventName ?? "--"}
                        </Alert>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <ArrowDownward color={"primary"} />
                        </div>
                        <Alert severity="info" style={{ margin: "10px 0" }}>
                            {selectedEvent.name ?? "--"}
                        </Alert>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                marginTop: "30px",
                            }}
                        >
                            <Button
                                style={{ textAlign: "center" }}
                                color={"primary"}
                                onClick={() => [setSelectedEvent(null), setOpenLinkStyle(false)]}
                            >
                                Select another event
                            </Button>
                            <Button
                                variant={"contained"}
                                style={{ marginLeft: "10px", textAlign: "center" }}
                                color={"primary"}
                                onClick={() => [setOpenLinkStyle(true), setOpenDialog(false)]}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                )}
            </DialogComponent>
            {selectedEvent !== null && (
                <SlidePanel
                    open={openLinkStyle}
                    onClose={() => setOpenLinkStyle(false)}
                    children={
                        <LinkStyleReservation
                            eventId={selectedEvent.eventId}
                            moveReservationRequiredParams={{
                                reservationId: props.reservationId,
                                isMovingReservation: true,
                            }}
                        />
                    }
                />
            )}
        </>
    );
};

export default MoveReservationEvent;
