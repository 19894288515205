import { BiteWiseUtils } from "shared/utils/BitwiseUtils";

export enum RentalInsuranceStatus {
    None = 0,
    Initiated = 1 << 0,
    DocumentsCompleted = 1 << 1,
    RentalStarted = 1 << 2,
    RentalCompleted = 1 << 3,
}

export const RentalInsuranceName: { [key in RentalInsuranceStatus]: string } = {
    [RentalInsuranceStatus.None]: "None",
    [RentalInsuranceStatus.Initiated]: "Initialited",
    [RentalInsuranceStatus.DocumentsCompleted]: "Documents Completed",
    [RentalInsuranceStatus.RentalStarted]: "Rental Started",
    [RentalInsuranceStatus.RentalCompleted]: "Rental Completed",
};

export const RentalInsuaranceColor: { [key in RentalInsuranceStatus]: string } = {
    [RentalInsuranceStatus.None]: "var(--grey-100)",
    [RentalInsuranceStatus.Initiated]: "var(--error-dark)",
    [RentalInsuranceStatus.DocumentsCompleted]: "var(--warning-main)",
    [RentalInsuranceStatus.RentalStarted]: "var(--success-darker)",
    [RentalInsuranceStatus.RentalCompleted]: "var(--grey-900)",
};

export const getRentalInsuranceName = (status: RentalInsuranceStatus): string => {
    const mostSignificantValue = BiteWiseUtils.getMostSignificantBite(
        status,
        RentalInsuranceStatus,
    );
    return RentalInsuranceName[mostSignificantValue as RentalInsuranceStatus];
};

export const getRentalInsuranceStatusColor = (status: number): string => {
    const mostSignificantValue = BiteWiseUtils.getMostSignificantBite(
        status,
        RentalInsuranceStatus,
    );
    return RentalInsuaranceColor[mostSignificantValue as RentalInsuranceStatus];
};

export interface IRentalInsurance {
    rentalInsuranceStatusId: number;
    reservationId: number;
    insuredName: string;
    indexId: number;
    isRequired: boolean;
    insuranceGateway: number;
    insuranceBookingId: string;
    status: number;
    cd: string;
    ud: string;
}
