import { CountryCode, isValidPhoneNumber } from "libphonenumber-js";
import { Locale } from "./Locale";
import { CountryInfo } from "../components/AddressMutiLine/countryInfo";

export class Validation {
    static Email = (value: string | null = "") => {
        let errorMessage: string = ``;
        if (!value) {
            errorMessage = Locale.required;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            errorMessage = Locale.emailError;
        }
        return errorMessage;
    };

    static PhoneNumber = (value: string = "", countryCode?: CountryCode): string => {
        let errorMessage: string = ``;
        if (value) {
            if (!isValidPhoneNumber(value, countryCode)) {
                errorMessage = Locale.phoneNumberError;
            } else {
                if (value.length === 0 || value.length === 4 || value.length === 3) {
                    errorMessage = Locale.phoneNumberError;
                }
            }
        } else {
            errorMessage = Locale.required;
        }

        return errorMessage;
    };
    //TODO: check with Mark if we still use the first and second name for the validation
    /**
     * @deprecated
     * @param value
     * @constructor
     */
    static Name(value?: string) {
        if (!value) {
            return Locale.nameRequired;
        }
        let errorMessage: string = ``;
        let singleNameTrim: string[] = value.trim().split(" ");
        let gtNameLength = (value: string) => value.length >= 2;

        if (value.length < 5) {
            errorMessage = Locale.tooShort;
        } else if (value.length > 50) {
            errorMessage = Locale.tooLong;
        } else if (value.trim().match(/ /) === null) {
            errorMessage = Locale.fullName;
        } else if (singleNameTrim.filter((r: any) => gtNameLength(r)).length <= 1) {
            errorMessage = Locale.tooShort;
        }
        return errorMessage;
    }

    static validateUSZip(value?: string) {
        let errorMessage: string = "";
        if (!value) {
            errorMessage = Locale.zipRequired;
        } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)) {
            errorMessage = Locale.zipUSInvalid;
        }
        return errorMessage;
    }

    static Zip(value: string = "", countryInfo: CountryInfo) {
        let errorMessage: string = "";
        if (!value) {
            return Locale.zipRequired;
        }
        if (countryInfo.zipRegex) {
            const isValid = countryInfo.zipRegex.test(value);
            if (!isValid) {
                errorMessage = Locale.zipInvalid;
            }
        }
        return errorMessage;
    }

    static Required(value: string | null = "") {
        let errorMessage: string = "";
        if (!value || value?.length < 2) {
            errorMessage = Locale.required;
        }
        return errorMessage;
    }

    static validateAddress(value?: string) {
        let errorMessage: string = "";
        if (value && value.trim().split(/ /).length < 3) {
            errorMessage = Locale.streetAddress;
        }
        return errorMessage;
    }

    static isGiftCard(code: string): boolean {
        // Codes are separated by blank space
        let strArray: string[] = code.split(" ");
        let segCount: number = 3;
        if (strArray.length !== segCount) return false;

        // Each code segment is 5 charatcers long
        let good: boolean = true;
        let i: number = 0;
        while (good && i < segCount) {
            if (strArray[i].length !== 5) good = false;
            i++;
        }

        return good;
    }
    static isNumericValue = (value: string) => {
        const regx = /^-?\d+\.?\d*$/;
        return regx.test(value);
    };
    static hasInvalidProperty(obj: { [key: string]: any }): boolean {
        if (Object.values(obj).some((val) => val === null || val === undefined)) {
            return true;
        }
        return false;
    }
    static Form<T>(fieldErrors: T, portalEntry: boolean, requiredFields: Array<keyof T>): boolean {
        let isValid = true;
        requiredFields.forEach((requiredF) => {
            if ((fieldErrors[requiredF] as string).length !== 0) {
                isValid = false;
            }
        });
        return isValid;
    }
}
