import React, { useState } from "react";
import {
    Box,
    Button,
    List,
    Typography,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Divider,
    useMediaQuery,
    Theme,
} from "@mui/material";
import { ReservationWaiver } from "shared/models/Reservation";
import { Check } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { ApprovalStatus, SignedWaiver } from "shared/models/AWaiver";
import DialogComponent, { DialogHooks } from "shared/components/DialogComponent";
import AWaiverDetails from "./AWaiverDetails";
import { AWaiverSearch } from "./AWaiverSearch";
import { useIndexicTheme } from "theme/useIndexicTheme";
import Stack from "@mui/material/Stack";
import aWaiverService from "shared/services/AWaiverService";
import { useReservationModalContext } from "modules/Reservation/context/ReservationModalProvider";
import { NotificationsType } from "shared/hooks/useNotification";

interface Props {
    reservationWaivers: Array<ReservationWaiver>;
    reservationId: number;
    waiverId: number;
}

const ReservationWaivers = (props: Props) => {
    const xsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
    const theme = useIndexicTheme();
    const [openWaiverDetails, OpenWaiverDetails, CloseWaiverDetails] = DialogHooks();
    const [openWaiverSearch, OpenWaiver, CloseWaiverSearch] = DialogHooks();
    const [signedWaiverId, setSelectedWaiverId] = useState(0);
    const [showApprove, setShowApprove] = useState(true);
    const [loading, setLoading] = useState(true);
    const { showNotification } = useReservationModalContext();

    const handleOpenWaiverDetails = (selectedWaiver: number) => {
        setSelectedWaiverId(selectedWaiver);
        OpenWaiverDetails();
    };

    const getWaiverDetails = (waiver: SignedWaiver) => {
        setShowApprove(waiver.status === ApprovalStatus.Pending);
        setLoading(!Boolean(waiver));
    };

    const updateWaiverStatus = async (status: ApprovalStatus) => {
        try {
            await aWaiverService.updateWaiverStatus(
                signedWaiverId,
                Number(props.reservationId),
                status.toString(),
            );
            showNotification({
                message: "Waiver Updated",
                type: NotificationsType.success,
            });
        } catch (error) {
            showNotification({
                message: "There was an error trying to change the approval status",
                type: NotificationsType.error,
            });
        }
    };

    return props.reservationWaivers && props.reservationWaivers?.length > 0 ? (
        <React.Fragment>
            <List>
                {props.reservationWaivers.map((item: ReservationWaiver) => (
                    <React.Fragment key={item.signedWaiverId}>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        {item.namesList.map((element: string, index: number) => (
                                            <Typography
                                                key={index}
                                                variant="body1"
                                                color="textPrimary"
                                            >
                                                {element}
                                            </Typography>
                                        ))}
                                    </React.Fragment>
                                }
                            />
                            <ListItemSecondaryAction>
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() => handleOpenWaiverDetails(item.signedWaiverId)}
                                    >
                                        {" "}
                                        View <i className="icon-awaiver"></i>{" "}
                                    </Button>
                                </Box>
                            </ListItemSecondaryAction>
                            <Divider variant="inset" component="li" />
                        </ListItem>
                    </React.Fragment>
                ))}
            </List>
            <DialogComponent
                DialogText="Waiver Details"
                isFullSize={xsScreen}
                open={openWaiverDetails}
                handleClose={CloseWaiverDetails}
                maxWidth="lg"
                customButtons={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Stack spacing={2} direction="row">
                            {showApprove ? (
                                <Button
                                    disabled={loading}
                                    startIcon={<Check />}
                                    sx={{
                                        backgroundColor: theme.palette.success.lighter,
                                        color: theme.palette.success.darker,
                                        "&:hover": {
                                            backgroundColor: theme.palette.success.light,
                                        },
                                    }}
                                    onClick={() => updateWaiverStatus(ApprovalStatus.Approved)}
                                >
                                    Approve and attach
                                </Button>
                            ) : null}
                            <Button
                                startIcon={<CloseIcon />}
                                sx={{
                                    backgroundColor: theme.palette.error.lighter,
                                    color: theme.palette.error.darker,
                                    "&:hover": {
                                        backgroundColor: theme.palette.error.light,
                                    },
                                }}
                                disabled={loading}
                                onClick={() => updateWaiverStatus(ApprovalStatus.Rejected)}
                            >
                                Reject
                            </Button>
                            <Button
                                style={xsScreen ? { flexBasis: "100%" } : {}}
                                variant="contained"
                                onClick={() => [OpenWaiver(), CloseWaiverDetails()]}
                                disabled={loading}
                            >
                                Attach new Waiver
                            </Button>
                        </Stack>
                    </div>
                }
            >
                <AWaiverDetails signedWaiver_Id={signedWaiverId} waiverDetails={getWaiverDetails} />
            </DialogComponent>
            <DialogComponent
                DialogText="Search for a waiver"
                open={openWaiverSearch}
                handleClose={CloseWaiverSearch}
            >
                <AWaiverSearch reservationId={props.reservationId} waiverId={props.waiverId} />
            </DialogComponent>
        </React.Fragment>
    ) : null;
};

export default ReservationWaivers;
