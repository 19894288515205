import React, { ReactElement, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";

interface AcceptedProps {
    title: string;
    content: string | ReactElement;
    buttonActions?: IButtonActions[];
}
interface IButtonActions {
    onAction: () => void;
    buttonText: string;
}
const useConfirmationDialog = () => {
    const [open, setOpen] = useState(false);
    const [dialogState, setDialogState] = useState<AcceptedProps>({
        title: "",
        content: "",
    });

    const handleOpen = (title: string, content: string, buttonActions?: IButtonActions[]) => {
        setOpen(true);
        setDialogState({ title, content, buttonActions });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = (action?: () => void) => {
        if (action) {
            action();
        }
        handleClose();
    };

    return {
        handleOpen,
        dialogContext: (
            <>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{dialogState.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogState.content}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                        {dialogState.buttonActions &&
                            dialogState.buttonActions.map((item) => (
                                <Button
                                    key={item.buttonText}
                                    onClick={() => handleConfirm(item.onAction)}
                                    color="primary"
                                    autoFocus
                                >
                                    {item.buttonText}
                                </Button>
                            ))}
                    </DialogActions>
                </Dialog>
            </>
        ),
    };
};

export default useConfirmationDialog;
