import { z } from "zod";
import { InterfaceToZod } from "./UtilsTypes";
import {
    Company,
    GiftCardInfo,
    ICompanySearch,
    ICompanyUser,
    IPublicNote,
    IRefundReason,
    SeatAssignmentSettings,
    SelectionItem,
} from "./Company";
import { DateTimeISOValidation } from "../utils/ZodValidations";
import { CompanyPreferencesSchema, UserPrefencesSchema } from "./Creds.schema";

export const SeatAssignmentSettingsSchema = z.object<InterfaceToZod<SeatAssignmentSettings>>({
    workspaceKey: z.string().nullable(),
    secretKey: z.string().nullable(),
});
export const CompanySchema = z.object<InterfaceToZod<Company>>({
    companyId: z.number(),
    name: z.string(),
    seatAssignmentModuleExp: DateTimeISOValidation,
    seatAssignmentSettings: z.nullable(SeatAssignmentSettingsSchema),
    textingModuleEnabled: z.boolean(),
    personnelModuleEnabled: z.boolean(),
    resourceModuleEnabled: z.boolean(),
    waiverModuleEnabled: z.boolean(),
    websiteModuleEnabled: z.boolean(),
    thresholdModuleEnabled: z.boolean(),
    depositModuleEnabled: z.boolean(),
    photoModuleEnabled: z.boolean(),
    seatAssignmentModuleEnabled: z.boolean(),
});

export const CompanyUserSchema = z.object<InterfaceToZod<ICompanyUser>>({
    userId: z.string(),
    companyId: z.number(),
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    email: z.string().nullable(),
    phone: z.string().nullable(),
    systemAccess: z.number(),
    reportAccess: z.number(),
    userPreferences: z.nullable(UserPrefencesSchema),
    companyPreferences: z.nullable(CompanyPreferencesSchema),
    active: z.boolean(),
});

export const CompanySearch = z.object<InterfaceToZod<ICompanySearch>>({
    companyId: z.number(),
    name: z.string(),
});

export const PublicNote = z.object<InterfaceToZod<IPublicNote>>({
    note: z.string(),
    publicNoteId: z.number(),
});

export const RefundReasonsSchema = z.array(
    z.object<InterfaceToZod<IRefundReason>>({
        refundReasonID: z.number(),
        reason: z.string(),
    }),
);
export const SelectionItemSchema = z.object<InterfaceToZod<SelectionItem>>({
    text: z.string().nullable(),
    typeId: z.number(),
});
export const GiftCardInfoSchema = z.object<InterfaceToZod<GiftCardInfo>>({
    code: z.string(),
    transactionDate: z.string(),
    transactionId: z.number(),
    balance: z.number(),
    originalValue: z.number(),
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    email: z.string().nullable(),
    phone: z.string().nullable(),
    address: z.string().nullable(),
    address2: z.string().nullable(),
    city: z.string().nullable(),
    state: z.string().nullable(),
    zip: z.string().nullable(),
    country: z.string().nullable(),
    recipientName: z.string().nullable(),
    recipientEmail: z.string().nullable(),
    lastEmailAttempt: z.string().nullable(),
});
export const CompanyUsersSchema = z.array(CompanyUserSchema);
export const CompanySearchSchema = z.array(CompanySearch);
export const PublicNotesSchema = z.array(PublicNote);
export const SelectionItemsSchema = z.array(SelectionItemSchema);
export const GiftCardsInfoSchema = z.array(GiftCardInfoSchema);
