import { LoadingButton } from "@mui/lab";
import { MenuItem, Select, TableCell, TableRow, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { AddOnTransaction, IRefundResult } from "shared/models/Reservation";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { MathEx } from "shared/utils/MathEx";
import { DateTimeUtils, FormatType } from "shared/utils/DateTimeUtils";
import { ReactComponent as RefundIconOutlined } from "../../../assets/icons/amend_outlined.svg";
import "./MerchandiseTable.css";
import useGlobal from "shared/hooks/GlobalHook";
import { useIndexicTheme } from "theme/useIndexicTheme";
import { useReservationModalContext } from "../context/ReservationModalProvider";
import { useQueryClient } from "@tanstack/react-query";
import TransactionService from "shared/services/TransactionService";
import { NotificationsType } from "shared/hooks/useNotification";
import { APIError } from "shared/http/errors";

interface MerchandiseRowProps {
    data: AddOnTransaction;
}

const MerchandiseRow = (props: MerchandiseRowProps) => {
    const theme = useIndexicTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { setTransactionID } = useGlobal();
    // Create an options array from the max return quantity
    const options = [...Array(props.data.maxReturn + 1).keys()];
    const [quantitySelected, setQuantitySelected] = useState(0);
    const [loadingReturnItem, setLoadingReturnItem] = useState<boolean>(false);
    const { showNotification } = useReservationModalContext();
    const queryClient = useQueryClient();

    const handleReturn = async (quantity: number, addon: AddOnTransaction) => {
        setLoadingReturnItem(true);
        try {
            await TransactionService.refundItem(addon.transactionItemId, quantity);
            showNotification({
                message: "Transaction refunded",
                type: NotificationsType.success,
            });
            queryClient.invalidateQueries({
                queryKey: ["event-date-details"],
            });
            queryClient.invalidateQueries({
                queryKey: ["reservation-details"],
            });
            queryClient.invalidateQueries({
                queryKey: ["calendar-by-event"],
            });
        } catch (err) {
            showNotification({
                message: "Error refunding merchandise",
                type: NotificationsType.error,
            });
            if (err instanceof APIError) {
                const data = err.data as IRefundResult;
                if (!data.paymentGatewayResult.success) {
                    data.paymentGatewayResult.errors.forEach((message: string) => {
                        showNotification({
                            message,
                            type: NotificationsType.error,
                        });
                    });
                }
            }
        }
        setLoadingReturnItem(false);
    };

    const formatDate = (dateString: string) => {
        return (
            <Grid container direction="row" wrap="wrap">
                <Grid style={{ marginRight: 5 }}>
                    {DateTimeUtils.newFormat(
                        DateTimeUtils.UTCStringToLocalDate(dateString),
                        FormatType.shortDate,
                    ) ?? ""}
                </Grid>
                <Grid>
                    {DateTimeUtils.newFormat(
                        DateTimeUtils.UTCStringToLocalDate(dateString),
                        FormatType.shortTime,
                    ) ?? ""}
                </Grid>
            </Grid>
        );
    };

    const handleSelectTransaction = (transactionId: number) => {
        setTransactionID(transactionId);
    };

    return (
        <>
            <TableRow key={props.data.transactionItemId}>
                <TableCell align="center" style={{ border: 0 }}>
                    <Typography
                        sx={{
                            color: "var(--grey-800)",
                            fontSize: "0.75rem",
                            fontWeight: 500,
                            textDecorationLine: "underline",
                            cursor: "pointer",
                        }}
                        onClick={() => handleSelectTransaction(props.data.transactionId)}
                    >
                        {props.data.transactionId}
                    </Typography>
                </TableCell>
                <TableCell style={{ border: 0 }}>
                    <Typography>{props.data.itemCode}</Typography>
                    <Typography>{props.data.description}</Typography>
                </TableCell>
                <TableCell align="center" style={{ border: 0 }}>
                    {formatDate(props.data.transactionDate)}
                </TableCell>
                <TableCell align="center" style={{ border: 0 }}>
                    {props.data.quantity}
                </TableCell>
                <TableCell align="center" style={{ border: 0 }}>
                     {MathEx.formatCurrency(props.data.price)}
                </TableCell>
            </TableRow>
            {props.data.maxReturn > 0 ? (
                <>
                    {isMobile ? (
                        <TableRow>
                            <TableCell colSpan={5}>
                                <Typography
                                    sx={{ fontStyle: "italic", color: theme.palette.grey[500] }}
                                >
                                    *Select a quantity to return
                                </Typography>
                                <Select
                                    value={quantitySelected}
                                    size="small"
                                    onChange={(event) =>
                                        setQuantitySelected(Number(event.target.value))
                                    }
                                    sx={{ marginRight: "1rem" }}
                                >
                                    {options.map((el, index) => (
                                        <MenuItem key={index} value={el}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <LoadingButton
                                    loading={props.data.maxReturn > 0 && loadingReturnItem}
                                    size="small"
                                    loadingPosition="start"
                                    color="primary"
                                    variant="contained"
                                    startIcon={<RefundIconOutlined />}
                                    disabled={quantitySelected === 0}
                                    onClick={() => handleReturn(quantitySelected, props.data)}
                                >
                                    Return
                                </LoadingButton>
                            </TableCell>
                        </TableRow>
                    ) : (
                        <TableRow>
                            <TableCell colSpan={3} align="right">
                                <Typography
                                    sx={{ fontStyle: "italic", color: theme.palette.grey[500] }}
                                >
                                    *Select a quantity to return
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Select
                                    value={quantitySelected}
                                    size="small"
                                    onChange={(event) =>
                                        setQuantitySelected(Number(event.target.value))
                                    }
                                >
                                    {options.map((el, index) => (
                                        <MenuItem key={index} value={el}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                            <TableCell align="center">
                                <LoadingButton
                                    loading={props.data.maxReturn > 0 && loadingReturnItem}
                                    size="small"
                                    loadingPosition="start"
                                    color="primary"
                                    variant="contained"
                                    startIcon={<RefundIconOutlined />}
                                    disabled={quantitySelected === 0}
                                    onClick={() => handleReturn(quantitySelected, props.data)}
                                >
                                    Return
                                </LoadingButton>
                            </TableCell>
                        </TableRow>
                    )}
                </>
            ) : null}
        </>
    );
};

export default MerchandiseRow;
