import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import "./MerchandiseTable.css";
import { AddOnTransaction } from "shared/models/Reservation";
import MerchandiseRow from "./MerchandiseRow";
import { Each } from "../../../shared/components/Each";

export interface MerchandiseTableProps {
    addOns: AddOnTransaction[];
}

const MerchandiseTable = (props: MerchandiseTableProps) => {
    const headerMerchandiseTable = ["Transaction", "Item", "Date", "Qty", "Unit Price"];

    return (
        <TableContainer sx={{ minWidTableCell: "90%" }}>
            <Table className="merchandise-table">
                <TableBody>
                    <TableRow key={-1}>
                        <Each
                            of={headerMerchandiseTable}
                            render={(headerName, k) => <th key={k}>{headerName}</th>}
                        />
                    </TableRow>
                    {props.addOns &&
                        props.addOns.map((el, idx) => <MerchandiseRow data={el} key={idx} />)}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default MerchandiseTable;
