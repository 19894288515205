import { CatalogIDCode } from "./Catalog";
import { Nullable } from "./Generals";
import { DateTimeString } from "./UtilsTypes";
import { IContactInfo, IContactInfoTemplate } from "./ContactInfo";
import { IAddressMultilineBase } from "./Address";

export type CartId = "Empty" | string;
export interface CartDTO {
    companyId: number;
    companySlug: Nullable<string>;
    cartId: CartId;
    isEmpty: boolean;
    reservations: CartReservation[];
    catalogItems: CartItem[];
    clickAgreements: string[];
    warnings: string[];
    errors: string[];
    appliedGiftCards: GiftCardDetail[];
    tax: number;
    subTotal: number;
    depositBalance: number;
    buyerServiceCharge: number;
    totalCartDiscount: number;
    processorSpecificJson: string;
}

export interface CartItem {
    catalogId: number;
    description: Nullable<string>;
    price: number;
    discount: number;
    tax: number;
    quantity: number;
    itemCode: Nullable<string>;
    requiredQuantity: number;
    giftCards: Nullable<GiftCardRecipient[]>;
}

export interface GiftCardRecipient {
    giftCardRecipientId?: number;
    giftCardTemplateId?: number;
    recipientName: string | null;
    recipientEmail: string | null;
    note?: string | null;
}

export interface GiftCardDetail {
    companyId: number;
    companyName: Nullable<string>;
    description: string;
    code: string;
    transactionId: number;
    transactionDate: DateTimeString;
    originalPrice: number;
    balance: number;
}

export interface CartReservation {
    description: string;
    discount: number;
    reservationId: number;
    tax: number;
    price: number;
    latestSellDate: DateTimeString;
    hasTipping: boolean;
    contact: IContactInfo;
    rates: CartDtoReservationRate[];
    addOns: CartItem[];
    eventDateId: number;
    eventId: number;
}

export interface CartDtoReservationRate {
    rateId: number;
    description: string;
    rate: number;
    tickets: number;
}
export interface PaymentOnFile {
    description: string;
    transactionId: number;
}
export interface HowHeardAnswerItem {
    howHeardAnswerID: number;
    answer: string;
}
export interface BillingInfo extends IContactInfoTemplate<string>, IAddressMultilineBase<string> {}
export interface PreCheckoutData {
    billingInfo: BillingInfo;
    gatewayId: number;
    cartDTO: CartDTO;
    howHeardAnswers: HowHeardAnswerItem[];
    paymentsOnFile: PaymentOnFile[] | null;
}

export interface CartReservationAdjustment {
    amount: number;
    catalogIdCode: CatalogIDCode;
}

export interface TicketsRequired {
    rateId: number;
    tickets: number;
}

export enum TipMethod {
    Percentage = 0,
    FixedAmount = 1,
}
export interface ReservationTip {
    tipMethod: TipMethod;
    percentTip: number;
    fixedAmount: number;
    reservationIdAddOn: number;
}

export interface SendEmailCartInfo {
    email: string;
    phone: string;
    companyFriendlyNames: string[];
}

export interface SendEmailData {
    email: string | null;
    cartId: string | null;
    companyUrlFriendlyName: string | null;
    phone: string | null;
}

export interface SendCartEmailResponse {
    success: boolean;
    errors: Nullable<string[]>;
}
