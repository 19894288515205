import {
    Alert,
    Button,
    Divider,
    LinearProgress,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import React, { FunctionComponent, useEffect, useState } from "react";
import { IconActionButton } from "shared/components/IconActionButton";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { CollapsableSingle } from "shared/components/CollapsableSingle";
import LabelDivider from "shared/components/LabelDivider";
import EditInformation from "./EditInformation";
import { DateTimeUtils } from "shared/utils/DateTimeUtils";
import Rates from "shared/components/Rates";
import useReservation from "shared/hooks/ReservationHook";
import TicketsDisplay from "shared/components/Tickets/TicketsDisplay";
import { displayPhoneNumber } from "shared/utils/Formats";
import TransactionTable from "shared/components/TransactionTable";
import useGlobal from "shared/hooks/GlobalHook";
import { useReservationModalContext } from "./context/ReservationModalProvider";
import NewDetailsSkeleton from "shared/components/NewDetailsSkeleton";
import { useAppSelector } from "store/Store";
import SeatSelector from "shared/components/Seats/SeatSelector";
import CheckInCheckOut from "./CheckInCheckOut";
import "./Reservation.css";
import { useIndexicTheme } from "theme/useIndexicTheme";
import {
    buildRateTicketPerson,
    buildTicketPersons,
    createTicketPersonArray,
    findMinId,
    shouldShowTickets,
} from "shared/business/Rates";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import reservationService from "shared/services/ReservationService";
import { ReservationHistoryItem } from "shared/components/WhoWhenComponent";
import AddToCartButton from "./components/AddToCartButton";
import { isPast } from "date-fns";
import { SaveReservationButton } from "./components/SaveReservationButton";
import ReservationWaivers from "./components/Awaiver/ReservationWaivers";
import Delivery from "./components/Delivery";
import ReservationMenu from "./components/ReservationMenu";
import { getStatusNotifedName } from "shared/utils/ReservationUtils";
import ThreePIcon from "@mui/icons-material/ThreeP";
import { useLocation, useNavigate } from "react-router-dom";
import MerchandiseTable from "modules/Reservation/components/MerchandiseTable";
import DueAmountChip from "../../shared/components/DueAmountChip";

const Reservation: FunctionComponent = () => {
    const theme = useIndexicTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { reservation, undoChanges, validateReservation } = useReservation();
    const { refetchEventDateDetails, setReservationDetails, setReservationBackup } =
        useReservationModalContext();
    const [editContactInfo, setEditContactInfo] = useState<boolean>(false);
    const [isNewReservation, setIsNewReservation] = useState<boolean>(false);
    const [showTickets, setShowTickets] = useState<boolean>(false);
    const { globalState, clearReservationPanelPayload } = useGlobal();
    const company = useAppSelector((store) => store.company.company);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const location = useLocation();
    const reservationQuery = useQuery(
        [
            "reservation-details",
            globalState.reservationPanelPayload.reservationId,
            globalState.reservationPanelPayload.eventDateId,
            globalState.reservationPanelPayload.cartId,
        ],
        () =>
            reservationService.GetReservationDetails(
                globalState.reservationPanelPayload.reservationId,
                globalState.reservationPanelPayload.eventDateId,
                globalState.reservationPanelPayload.cartId,
            ),
        {
            enabled: Boolean(globalState.reservationPanelPayload.reservationId !== 0),
        },
    );

    useEffect(() => {
        //this clean up function helps to remove any reservation that was added from link style to avoid reservation data crossing
        return () => {
            clearReservationPanelPayload();
            setReservationDetails(null);
        };
    }, []);

    useEffect(() => {
        if (reservationQuery.isSuccess) {
            const reservationDetails = reservationQuery.data;
            if (globalState.reservationPanelPayload.tickets.length > 0) {
                /* The code is checking if the length of the `tickets` array in the
                `globalState.reservationPanelPayload` object is greater than 0, that means that tickets are comming from LinkStyle. If it is, it then
                iterates over each element in the `tickets` array and calls the `buildTicketPersons`
                function with the `rateId`, `amount`, `tickets`, and `ticket_edits` arguments. The
                result of the `buildTicketPersons` function is then assigned to the `ticket_edits`
                variable. Finally, the `ticket_edits` value is assigned to the
                `reservationDetails.ticketEdits` property */
                let ticket_edits = reservationDetails.ticketEdits;
                globalState.reservationPanelPayload.tickets.forEach((x) => {
                    ticket_edits = buildTicketPersons(
                        x.rateId,
                        x.amount,
                        x.tickets,
                        ticket_edits,
                    ).ticketEdits;
                });
                reservationDetails.ticketEdits = ticket_edits;
            } else if (
                reservationDetails.ticketEdits
                    .filter((x) => x.tickets > 0)
                    .some((x) => x.ticketPersons.length === 0)
            ) {
                let ticket_edits = reservationDetails.ticketEdits.map((ticket) => {
                    if (ticket.tickets > 0) {
                        const ticketRates = createTicketPersonArray(
                            ticket.tickets,
                            findMinId(ticket.ticketPersons || []),
                        ).map(
                            buildRateTicketPerson(
                                ticket.rateId,
                                ticket.questionGroupId,
                                ticket.description,
                            ),
                        );
                        return {
                            ...ticket,
                            ticketPersons: ticket.ticketPersons.concat(ticketRates),
                        };
                    }
                    return ticket;
                });
                reservationDetails.ticketEdits = ticket_edits;
            }
            setReservationBackup(reservationDetails);
            setReservationDetails(reservationDetails);
        }
        // eslint-disable-next-line
    }, [reservationQuery.isSuccess, reservationQuery.data]);

    useEffect(() => {
        if (reservation !== null) {
            validateReservation();
            setShowTickets(
                shouldShowTickets(reservation.ticketEdits, reservation.ticketRequiredFields),
            );
            if (reservation.reservationId === -1 && !isNewReservation) {
                setEditContactInfo(true);
                setIsNewReservation(true);
            }
        }
        // eslint-disable-next-line
    }, [reservation]);

    const renderName = () => {
        if (reservation !== null) {
            if (reservation.firstName !== null) {
                return `${reservation.lastName},${reservation.firstName}`;
            } else if (reservation.lastName !== null) {
                return reservation.lastName;
            }
        }
        return "";
    };

    const checkInSuccess = () => {
        if (reservation) {
            reservationQuery.refetch();
            refetchEventDateDetails();
        }
    };

    const goToEventCalendar = () => {
        if (
            location.pathname !==
            `/event/calendar/${reservation?.eventId}/${reservation?.eventDateId}`
        ) {
            navigate(`/event/calendar/${reservation?.eventId}/${reservation?.eventDateId}`);
        }
    };

    const showPastDateWarning =
        reservation !== null
            ? isPast(DateTimeUtils.eventLocalDate(reservation?.eventDateTime)) &&
              reservation.reservationId === -1
            : false;
    return reservation !== null ? (
        <React.Fragment>
            {reservationQuery.isRefetching && (
                <LinearProgress
                    sx={{
                        position: "fixed",
                        width: "34.2rem",
                        top: "1px",
                        height: ".5rem",
                    }}
                />
            )}

            <Grid container spacing={1.5}>
                <Grid xs={12}>
                    <Grid container>
                        <Grid xs={12}>
                            {showPastDateWarning ? (
                                <Alert severity="warning">
                                    You're creating a reservation on a past date
                                </Alert>
                            ) : null}
                        </Grid>
                        <Grid md={6} xs={12}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div className="reservation-data-container">
                                    <div className="reservationId">
                                        <Typography variant="idReservation">
                                            ID:
                                            {reservation.reservationId > 0
                                                ? ` ${reservation.reservationId}`
                                                : " new"}
                                        </Typography>
                                    </div>
                                    <div className="data-actions">
                                        <DueAmountChip
                                            subtotal={reservation.subtotal ?? reservation.price}
                                            tax={reservation.tax}
                                            labelSize={"large"}
                                        />
                                        <CheckInCheckOut
                                            reservationId={reservation.reservationId}
                                            ticketPersonId={0}
                                            checked={Boolean(reservation.checkedIn)}
                                            disabled={reservation.reservationId === -1}
                                            callback={checkInSuccess}
                                        />
                                        <IconActionButton
                                            size="small"
                                            icon={<ModeEditOutlineOutlinedIcon />}
                                            onClick={() => setEditContactInfo(!editContactInfo)}
                                        >
                                            Edit contact
                                        </IconActionButton>
                                    </div>
                                </div>
                                <Typography variant="reservationFullName">
                                    {renderName()}
                                </Typography>
                            </div>
                        </Grid>
                        <Divider orientation={isMobile ? "horizontal" : "vertical"} flexItem />
                        <Grid md={4} xs={12}>
                            <div className="contact-info-readonly-phone-email">
                                <LocalPhoneIcon sx={{ color: "#637381", marginRight: "6px" }} />
                                <Typography variant="inputLabel" sx={{ fontSize: "0.875rem" }}>
                                    {reservation.phone
                                        ? displayPhoneNumber(reservation.phone)
                                        : "Not provided"}
                                </Typography>
                            </div>
                            <div className="contact-info-readonly-phone-email">
                                <EmailOutlinedIcon sx={{ color: "#637381", marginRight: "6px" }} />
                                <Typography variant="inputLabel" sx={{ fontSize: "0.875rem" }}>
                                    {Boolean(reservation.email)
                                        ? reservation.email
                                        : "Not provided"}
                                </Typography>
                            </div>
                            {reservation.notified > 0 ? (
                                <div className="contact-info-readonly-phone-email">
                                    <ThreePIcon sx={{ color: "#637381", marginRight: "6px" }} />
                                    <Typography variant="inputLabel" sx={{ fontSize: "0.875rem" }}>
                                        {getStatusNotifedName(reservation.notified)}
                                    </Typography>
                                </div>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
                {editContactInfo ? (
                    <div data-cy="EditInformation">
                        <EditInformation closePanel={() => setEditContactInfo(false)} />
                    </div>
                ) : null}
                <Grid xs={12}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="idReservation">Comment</Typography>
                        <Typography variant="commentContent">{reservation.comment}</Typography>
                    </div>
                </Grid>
                <Grid xs={12}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {reservation.rescheduleLinkSent ? (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Typography variant="amountSummary">Rescheduled from</Typography>
                                <Typography variant="inputLabel" sx={{ fontSize: "0.875rem" }}>
                                    {DateTimeUtils.eventLocalTime(
                                        DateTimeUtils.isoToLocaleString(
                                            reservation.rescheduleLinkSent,
                                        ),
                                    )}
                                </Typography>
                            </div>
                        ) : null}
                    </div>
                </Grid>
                <Grid xs={12}>
                    <LabelDivider label="Actions" />
                </Grid>
                <Grid xs={12}>
                    <ReservationMenu
                        reservationId={reservation.reservationId}
                        isWaiverSupported={reservation.isWaiverSupported}
                        pictures={reservation.pictures}
                        phone={reservation.phone}
                        eventId={reservation.eventId}
                        waiverId={reservation.waiverId}
                        afterSaveCallback={() =>
                            queryClient.refetchQueries({ queryKey: ["reservation-details"] })
                        }
                        hasTransactions={reservation.reservationTransactions.length > 0}
                        reservationPaid={reservation.paidAmount}
                    />
                </Grid>
                <Grid xs={12}>
                    <LabelDivider label="Tickets" />
                </Grid>
                <Grid xs={12}>
                    <Rates
                        rates={reservation.ticketEdits}
                        ticketEdits={reservation.ticketEdits}
                        useTicketEdits={true}
                        requiredFields={reservation.ticketRequiredFields}
                        transactions={reservation.reservationTransactions.length > 0}
                    />
                </Grid>
                {reservation.delivery ? (
                    <Grid xs={12}>
                        <CollapsableSingle
                            title={`Delivery/Pickup: ${reservation.address ?? ""}`}
                            content={<Delivery />}
                        />
                    </Grid>
                ) : null}
                {reservation.reservationWaivers.length > 0 && (
                    <Grid xs={12}>
                        <LabelDivider label="Waivers List" />
                        <ReservationWaivers
                            reservationWaivers={reservation.reservationWaivers}
                            reservationId={reservation.reservationId}
                            waiverId={reservation.waiverId}
                        />
                    </Grid>
                )}
                {showTickets && (
                    <>
                        <Grid xs={12}>
                            <LabelDivider label="Ticket Persons" />
                        </Grid>
                        <Grid xs={12}>
                            <Grid container>
                                <Grid xs={12}>
                                    <TicketsDisplay />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
                {reservation.reservationTransactions.length > 0 && (
                    <Grid xs={12}>
                        <CollapsableSingle
                            title="Transactions"
                            content={
                                <TransactionTable
                                    transactions={reservation.reservationTransactions}
                                    ticketEdits={reservation.ticketEdits}
                                />
                            }
                        />
                    </Grid>
                )}
                {reservation.addOnTransactions.length > 0 ? (
                    <Grid xs={12}>
                        <CollapsableSingle
                            title="Merchandise"
                            content={<MerchandiseTable addOns={reservation.addOnTransactions} />}
                        />
                    </Grid>
                ) : null}
                {company &&
                company.seatAssignmentModuleEnabled &&
                company.seatAssignmentSettings &&
                company.seatAssignmentSettings.workspaceKey &&
                reservation.seatAssignmentChartKey ? (
                    <Grid xs={12}>
                        <CollapsableSingle
                            title="Seats"
                            content={
                                <SeatSelector
                                    reservation={reservation}
                                    workspaceKey={company.seatAssignmentSettings?.workspaceKey}
                                    event={`${reservation?.eventDateId}`}
                                />
                            }
                        />
                    </Grid>
                ) : null}
                {reservation.reservationId > 0 ? (
                    <Grid xs={12}>
                        <CollapsableSingle
                            title="Who/When"
                            content={
                                <ReservationHistoryItem reservationId={reservation.reservationId} />
                            }
                        />
                    </Grid>
                ) : null}
                <Grid xs={12}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                        }}
                    >
                        {/*Don't reorder the buttons*/}
                        <Stack direction="row" spacing={2}>
                            {location.pathname !==
                                `/event/calendar/${reservation?.eventId}/${reservation?.eventDateId}` && (
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => goToEventCalendar()}
                                >
                                    Go to date
                                </Button>
                            )}
                            <Button size="small" variant="outlined" onClick={() => undoChanges()}>
                                Undo
                            </Button>
                            <AddToCartButton />
                            <SaveReservationButton />
                        </Stack>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    ) : (
        <NewDetailsSkeleton />
    );
};

export default Reservation;
