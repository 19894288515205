import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import { useShoppingCartSlice } from "store/ShoppingCart/useShoppingCartSlice";
import { Nullable } from "shared/models/Generals";
import { useAppSelector } from "store/Store";
import { IconActionButton } from "shared/components/IconActionButton";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import { AWaiverDialog } from "modules/Reservation/components/Awaiver/AWaiverSearch";
import { useShoppingCartProvider } from "modules/ShoppingCart/ShoppingCartProvider";
import ConversationDialog from "modules/TextMessages/ConversationDialog";
import RentalInsurance from "./RentalInsurance";
import MoveReservationEvent from "./MoveReservationEvent";
import { CameraDialog, PictureDisplayCount } from "./Camera/Camera";
import DeleteReservation from "./DeleteReservation";
import { RefundCompleteReservation } from "./RefundCompleteReservation";

interface ReservationMenuProps {
    isWaiverSupported: boolean;
    reservationId: number;
    waiverId: number;
    afterSaveCallback?: () => void;
    pictures?: number;
    rentalInsuranceCount?: number;
    rentalInsuranceStatus?: number;
    phone: Nullable<string>;
    eventId: number;
    hasTransactions: boolean;
    reservationPaid: number;
}
const ReservationMenu = (props: ReservationMenuProps) => {
    const { addExistingReservation } = useShoppingCartSlice();
    const { showNotification } = useNotification();
    const { toggleShoppingCartModal } = useShoppingCartProvider();
    const company = useAppSelector((store) => store.company.company);

    const addToCart = async () => {
        await addExistingReservation(props.reservationId);
        showNotification({
            message: "Reservation added to cart successfully",
            type: NotificationsType.success,
        });
        toggleShoppingCartModal(true);
    };

    return (
        <div data-cy="ReservationMenu" style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconActionButton
                icon={<AddShoppingCartIcon />}
                onClick={() => addToCart()}
                label="Add"
                size="small"
            />
            <MoveReservationEvent
                reservationId={props.reservationId}
                currentEventId={props.eventId}
            />
            <RefundCompleteReservation
                reservationId={props.reservationId}
                refundAmount={props.reservationPaid}
            />
            {props.reservationId !== -1 ? (
                <>
                    <CameraDialog reservationId={props.reservationId} disabled={false} />
                    {props.pictures && props.pictures > 0 ? (
                        <PictureDisplayCount
                            photoCount={props.pictures}
                            reservationId={props.reservationId}
                        />
                    ) : null}
                </>
            ) : null}

            {props.isWaiverSupported && props.reservationId !== -1 ? (
                <AWaiverDialog
                    reservationId={props.reservationId}
                    waiverId={props.waiverId!}
                    disabled={false}
                    callBack={props.afterSaveCallback}
                />
            ) : null}

            {company && company.textingModuleEnabled && props.phone ? (
                <ConversationDialog
                    renderButton={(callback) => (
                        <IconActionButton
                            icon={<SmsOutlinedIcon />}
                            color="primary"
                            label="Text"
                            size="small"
                            onClick={() => callback()}
                        />
                    )}
                    phoneNumber={props.phone}
                />
            ) : null}

            {props.rentalInsuranceStatus &&
            props.rentalInsuranceCount &&
            props.rentalInsuranceCount >= 0 ? (
                <RentalInsurance
                    rentalInsuranceCount={props.rentalInsuranceCount}
                    rentalInsuranceStatus={props.rentalInsuranceStatus}
                    reservationId={props.reservationId}
                />
            ) : null}
            {/* //TODO: show only with permission to delete */}
            <DeleteReservation
                reservationId={props.reservationId}
                hasTransactions={props.hasTransactions}
            />
        </div>
    );
};

export default ReservationMenu;
