import { useEffect, useState } from "react";
import "./UserSettings.css";
import UserSection from "./UserSection";
import LoadingButtons from "shared/components/LoadingButton";
import UserGeneralSection from "./UserGeneralSection";
import UserCalendarSection from "./UserCalendarSection";
import { ICompanyUser } from "shared/models/Company";
import userService from "shared/services/UserService";
import { CompanyPreferences, UserPreferences } from "shared/models/Creds";
import { Button, LinearProgress, Typography } from "@mui/material";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { updatedDiff } from "deep-object-diff";
import { useCredentials } from "store/Credentials/CredencialHook";

const UserSettings = () => {
    const { fetchCredentials } = useCredentials();
    const [edited, setEdited] = useState(false);
    const [companyUser, setCompanyUser] = useState<ICompanyUser>(defaultCompanyUser);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const { showNotification } = useNotification();
    const [previousCompanyUser, setPreviousCompanyUser] =
        useState<ICompanyUser>(defaultCompanyUser);

    const [loadingCompanyUser, setLoadingCompanyUser] = useState<boolean>(false);

    const getCompanyUser = async () => {
        setLoadingCompanyUser(true);
        try {
            const result = await userService.getCompanyUser();

            setCompanyUser(result);
            setPreviousCompanyUser(result);
            setEdited(false);
        } catch {}

        setLoadingCompanyUser(false);
    };

    useEffect(() => {
        getCompanyUser();
        setEdited(false);
    }, []);

    useEffect(() => {
        const result = updatedDiff(companyUser, previousCompanyUser);
        const hasEdited = Object.keys(result).length > 0;
        setEdited(hasEdited);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyUser]);

    const updateUser = (data: Partial<ICompanyUser>) => {
        setCompanyUser((user) => ({ ...user, ...data }));
    };
    const updateCompanyPreferences = (data: CompanyPreferences) => {
        const companyPreferences = {
            ...companyUser.companyPreferences,
            ...data,
        };
        updateUser({ companyPreferences });
    };

    const updateUserPreferences = (data: UserPreferences) => {
        const userPreferences = { ...companyUser.userPreferences, ...data };
        updateUser({ userPreferences });
    };

    const handleSave = async () => {
        setIsUpdating(true);
        try {
            const result = await userService.updateCompanyUser(companyUser);
            setCompanyUser(result);
            setPreviousCompanyUser(result);
            setEdited(false);
            showNotification({
                type: NotificationsType.success,
                message: "Information saved.",
            });
            fetchCredentials();
        } catch {
            showNotification({
                type: NotificationsType.error,
                message: "Cannot update information.",
            });
        }
        setIsUpdating(false);
    };

    const handleCancel = () => {
        setCompanyUser(previousCompanyUser);
    };

    return (
        <div className="settings-container">
            <div className="settings-header">
                <Typography sx={{ fontSize: "1.25rem", fontWeight: 500 }}>
                    User Preferences
                </Typography>
            </div>

            {loadingCompanyUser ? (
                <LinearProgress />
            ) : (
                <div>
                    {isUpdating && <LinearProgress />}
                    <Grid container className="user-container" spacing={2}>
                        <Grid sm={4} sx={{ width: "100%" }}>
                            <UserSection data={companyUser} handleChange={updateUser} />
                        </Grid>
                        <Grid sm={8}>
                            <UserGeneralSection
                                data={companyUser}
                                handleChange={updateCompanyPreferences}
                            />
                            <UserCalendarSection
                                data={companyUser}
                                handleChange={updateUserPreferences}
                            />
                        </Grid>
                        <Grid sm={12}>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button onClick={handleCancel}>Cancel</Button>
                                <LoadingButtons
                                    loading={isUpdating}
                                    label="Save"
                                    disabled={!edited}
                                    handleClick={handleSave}
                                />
                            </div>
                        </Grid>

                        {/* <Grid sm={12}>
                        TODO: Implement after remove EBM security preferences
                        <UserSecuritySection
                            data={companyUser}
                            handleChange={updateCompanyPreferences}
                        />
                    </Grid> */}
                    </Grid>
                </div>
            )}
        </div>
    );
};

export default UserSettings;

const defaultCompanyUser: ICompanyUser = {
    companyId: 0,
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    userId: "",
    userPreferences: {
        defaultCalendarView: 0,
        showZeroTicketTimes: false,
        showInactiveEvents: false,
        user24HourTime: false,
        firstDayOfWeek: 0,
    },
    companyPreferences: {
        defaultPage: 0,
        defaultEventGroupId: 0,
        resourceCalendarDefaultTypes: [],
    },
    systemAccess: 0,
    reportAccess: 0,
    active: false,
};
