import { useQuery } from "@tanstack/react-query";
import CompanyService from "../../../shared/services/CompanyService";
import { useSearchableItem } from "../../../shared/hooks/useSearchableItem";
import { CatalogDetail, CatalogItem } from "../../../shared/models/Catalog";
import { useMeasureElement } from "../../../shared/hooks/useMeasureElement";
import React, { useEffect, useState } from "react";
import {
    Fade,
    IconButton,
    InputAdornment,
    LinearProgress,
    TextField,
    TextFieldProps,
    Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import styles from "./Merchandise.module.css";
import { MathEx } from "../../../shared/utils/MathEx";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useShoppingCartSlice } from "../../../store/ShoppingCart/useShoppingCartSlice";
import { FlatButton } from "../../../shared/components/FlatButton";
import { getShoppingCartNotifications } from "../ShoppingCartNotifications";
import useNotification from "../../../shared/hooks/useNotification";
interface CatalogListSearchProps {
    wrapperHeight: number;
    selectedReservationId: number;
}
export const CatalogListSearch = (props: CatalogListSearchProps) => {
    const query = useQuery(["merchandise"], () => CompanyService.getCompanyMerchandise(), {
        placeholderData: [],
    });
    const { filteredItems, searchItems, setData } =
        useSearchableItem<CatalogDetail>(createSearchableKey);
    const [ref, measures] = useMeasureElement<HTMLDivElement>([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        if (query.isSuccess && query.data && query.data?.length > 0) {
            setData(query.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isSuccess, query.data]);
    const onSearchChange: TextFieldProps["onChange"] = (e) => {
        const value = e.target.value;
        setSearch(value);
        searchItems(value);
    };
    const occupiedHeight = (measures?.bottom ?? 0) + props.wrapperHeight;
    return (
        <div>
            <div
                style={{
                    padding: "0.5rem 1rem",
                }}
                ref={ref}
            >
                <Typography
                    variant={"inputLabel"}
                    fontSize={"1.125rem"}
                    style={{ marginBottom: "0.5rem" }}
                >
                    All Merchandise items
                </Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={search}
                    size="small"
                    id="header-search"
                    onChange={onSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search sx={{ color: (theme) => theme.palette.grey["700"] }} />
                            </InputAdornment>
                        ),
                    }}
                    onFocus={(e) => e.target.select()}
                    placeholder="Search merchandise items"
                    sx={{
                        borderRadius: "0.5rem",
                        borderColor: (theme) => theme.palette.grey["400"],
                        color: (theme) => theme.palette.grey["600"],
                        marginBottom: "0.5rem",
                    }}
                />
                <Fade in={query.isFetching} unmountOnExit>
                    <LinearProgress />
                </Fade>
            </div>
            <div
                style={{
                    height: `calc(100vh - ${occupiedHeight}px)`,
                    padding: "0 0.5rem 0 1rem",
                    overflow: "auto",
                }}
            >
                {filteredItems.map((el, idx) => (
                    <CatalogItemEntry
                        key={idx}
                        data={el}
                        selectedReservationId={props.selectedReservationId}
                    />
                ))}
            </div>
        </div>
    );
};
interface CatalogItemEntryProps {
    data: CatalogDetail;
    selectedReservationId: number;
}
const CatalogItemEntry = (props: CatalogItemEntryProps) => {
    const [loading, setLoading] = useState(false);
    const { addCatalogItem, addNewRecipients } = useShoppingCartSlice();
    const { showNotification } = useNotification();
    const catalogEntry = props.data;
    const [quantity, setQuantity] = useState(0);
    const increaseCount = () => {
        setQuantity((t) => t + 1);
    };
    const decreaseCount = () => {
        setQuantity((t) => (t - 1 < 0 ? 0 : t - 1));
    };
    const handleAddCatalogItemToCart = async () => {
        const notification = getShoppingCartNotifications("AddCatalogItem");
        if (quantity <= 0 || loading) {
            return;
        }
        setLoading(true);
        const item: CatalogItem = {
            catalogId: props.data.catalogId,
            quantity,
        };
        if (props.selectedReservationId >= 0) {
            item.reservationIdAddOn = props.selectedReservationId;
        }
        if (props.data.giftCard) {
            addNewRecipients(props.data, quantity);
            setLoading(false);
            setQuantity(0);
            return;
        }
        try {
            await addCatalogItem(item);
            setQuantity(0);
            showNotification(notification.success);
        } catch (e) {
            showNotification(notification.error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className={styles.catalogEntryWrapper}>
            <div className={styles.flexWrapper}>
                {!catalogEntry.inSchedule ? (
                    <span className={`${styles.catalogEntryIndicator} ${styles.warning}`}>
                        Off schedule
                    </span>
                ) : null}
                {catalogEntry.internal ? (
                    <span className={`${styles.catalogEntryIndicator} ${styles.success}`}>
                        Internal
                    </span>
                ) : null}
                <div className={styles.catalogEntryItemCode}>{catalogEntry.itemCode}</div>
                <div className={styles.flexSpacer}></div>
                <Typography variant={"inputLabel"}>
                    {MathEx.formatCurrency(catalogEntry.unitPrice)}
                </Typography>
            </div>
            <Typography sx={{ marginBottom: "0.5rem" }}>
                <span style={{ fontStyle: "italic" }}>{catalogEntry.description}</span>
            </Typography>
            <div className={styles.flexWrapper}>
                <div className={styles.catalogEntryInputWrapper}>
                    <IconButton onClick={decreaseCount} color={"primary"} disabled={quantity === 0}>
                        <RemoveIcon />
                    </IconButton>
                    <div className={styles.catalogEntryInputCount}>{quantity}</div>
                    <IconButton onClick={increaseCount} color={"primary"}>
                        <AddIcon />
                    </IconButton>
                </div>
                <div className={styles.flexSpacer}></div>
                <FlatButton disabled={quantity === 0} onClick={handleAddCatalogItemToCart}>
                    Add to cart
                </FlatButton>
            </div>
            <Fade in={loading} unmountOnExit>
                <LinearProgress sx={{ height: "0.25rem", marginTop: "0.25rem" }} />
            </Fade>
        </div>
    );
};
const createSearchableKey = (el: CatalogDetail) => {
    return `${(el.itemCode ?? "").toLowerCase()}-${el.description.toLowerCase()}-${el.unitPrice}`;
};
