import { useEffect, useRef, useState } from "react";
import { TextMessage } from "shared/models/SignalR";
import { DateTimeUtils, FormatType } from "shared/utils/DateTimeUtils";
import messageService from "shared/services/MessageService";
import { Button, List, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import "./TextMessages.css";
import { Each } from "shared/components/Each";

const ContinueConversation = (props: {
    messages: TextMessage[];
    phoneNumber: string;
    reload: () => void;
}) => {
    const chatText = useRef<HTMLInputElement>(null);
    const { showNotification } = useNotification();
    const [newMessageText, setNewMessageText] = useState("");
    const [messageList, setMessageList] = useState<TextMessage[]>([]);

    useEffect(() => {
        setMessageList(props.messages);
    }, [props.messages]);

    useEffect(() => {
        if (chatText.current !== null && chatText.current.lastElementChild) {
            chatText.current.lastElementChild.scrollIntoView();
        }
    }, [messageList]);

    useEffect(() => {
        // Set time out to get messages of conversation for every 10 seconds
        const timeout = setTimeout(() => props.reload(), 10000);
        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageList]);

    const sendTextMessage = async (message: string) => {
        if (!message || message.trim() === "") {
            return;
        }
        try {
            const result = await messageService.sendMessage(message, props.phoneNumber);
            setNewMessageText("");
            setMessageList([...messageList, result]);
        } catch {
            showNotification({
                message: "Error to send message",
                type: NotificationsType.error,
            });
        }
    };

    return (
        <div>
            <List className={"text-messages-chat"}>
                <Each
                    of={messageList}
                    render={(item: TextMessage, index) => (
                        <div
                            style={{ height: "auto" }}
                            className={`bubbleContainer ${item.isInbound ? "left" : "right"}`}
                            key={index}
                            ref={chatText}
                        >
                            <div className="bubble">
                                {item.body}
                                <div className="dateTitle">
                                    {item.sendDate
                                        ? DateTimeUtils.newFormat(
                                              DateTimeUtils.parse(item.sendDate),
                                              FormatType.full,
                                          )
                                        : DateTimeUtils.newFormat(new Date(), FormatType.full)}
                                </div>
                            </div>
                        </div>
                    )}
                />
            </List>
            <div style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>
                <TextField
                    size="small"
                    placeholder="Message to send"
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                        setNewMessageText(event.target.value);
                    }}
                    required
                    type="text"
                    value={newMessageText}
                    sx={{ marginRight: "1rem" }}
                />
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                        sendTextMessage(newMessageText);
                    }}
                    startIcon={<SendIcon />}
                >
                    Send
                </Button>
            </div>
        </div>
    );
};

export default ContinueConversation;
