import React, { FunctionComponent, useState } from "react";
import DialogComponent from "../../../shared/components/DialogComponent";
import {
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    TextFieldProps,
    Typography,
} from "@mui/material";
import { RefundAmountField } from "./RefundAmountField";
import { CurrencyComponent } from "../../Reports/components/CurrencyComponent";
import { Financial } from "../../../shared/utils/Financials";

interface RefundCatalogAmounts {
    quantity: number;
    unitPrice: number;
    serviceCharge: number;
    tax: number;
    amount: number;
}
export interface RefundCatalogItemDialogProps {
    maxRefund: RefundCatalogAmounts;
    taxRate: number;
    onSet: (arg: RefundCatalogAmounts) => void;
    onCancel: () => void;
    open: boolean;
    isCatalogItem?: boolean;
}
// Fields that can be edited
type EditableRefundAmount = Pick<RefundCatalogAmounts, "quantity" | "serviceCharge" | "unitPrice">;
const RefundCatalogItemDialog: FunctionComponent<RefundCatalogItemDialogProps> = (props) => {
    const { maxRefund, taxRate, open } = props;
    // Generate the select entries according to the number of catalog items
    const options = [...Array(maxRefund.quantity + 1).keys()];
    const [refundData, setRefundData] = useState<RefundCatalogAmounts>({
        quantity: 0,
        unitPrice: maxRefund.unitPrice,
        tax: 0,
        serviceCharge: 0,
        amount: 0,
    });
    const updateRefundData = (data: Partial<EditableRefundAmount>) => {
        let { quantity, unitPrice, serviceCharge } = refundData;
        const newQuantity = Number(data.quantity);
        if (!isNaN(newQuantity)) {
            quantity = Math.min(newQuantity, maxRefund.quantity);
        }
        const newUnitPrice = Number(data.unitPrice);
        if (!isNaN(newUnitPrice)) {
            unitPrice = Math.min(newUnitPrice, maxRefund.unitPrice);
        }
        const newServiceCharge = Number(data.serviceCharge);
        if (!isNaN(newServiceCharge)) {
            serviceCharge = Math.min(newServiceCharge, maxRefund.serviceCharge);
        }
        const tax = Financial.CalculateTax(unitPrice, quantity, taxRate);
        setRefundData({
            quantity,
            unitPrice,
            serviceCharge,
            tax: Math.min(tax, maxRefund.tax),
            amount: quantity * unitPrice,
        });
    };
    const handleSelect: TextFieldProps["onChange"] = (e) => {
        updateRefundData({ quantity: Number(e.target.value) });
    };
    const handleSetRefundAmounts = () => {
        props.onSet(refundData);
    };
    return (
        <DialogComponent
            open={open}
            handleClose={props.onCancel}
            DialogText="Set the amount you want to refund"
            ButtonActions={{
                handleSuccessActionText: "Set",
                handleSuccessActionFunction: handleSetRefundAmounts,
                handleCancelActionText: "Cancel",
                handleCancelActionFunction: props.onCancel,
            }}
        >
            <Typography variant="inputLabel" gutterBottom>
                Maximum refundable amounts
            </Typography>
            <AmountsTable data={maxRefund} />

            <Typography variant="commentContent" component={"p"} sx={{ marginBottom: "0.5rem" }}>
                Enter the amounts you want to refund
            </Typography>

            <Typography variant="idReservation">Refund quantity</Typography>
            <TextField
                select
                value={refundData.quantity}
                onChange={handleSelect}
                size={"small"}
                fullWidth
                sx={{
                    marginBottom: "0.5rem",
                }}
            >
                {options.map((el) => (
                    <MenuItem key={el} value={el}>
                        {el}
                    </MenuItem>
                ))}
            </TextField>

            <Typography variant="idReservation">Unit price</Typography>
            <RefundAmountField
                value={refundData.unitPrice}
                max={maxRefund.unitPrice}
                onChange={(e) => updateRefundData({ unitPrice: e })}
            />

            {maxRefund.serviceCharge > 0 ? (
                <>
                    <Typography variant="idReservation">Refund service charge</Typography>

                    <RefundAmountField
                        value={refundData.serviceCharge}
                        max={maxRefund.serviceCharge}
                        onChange={(e) => updateRefundData({ serviceCharge: e })}
                    />
                </>
            ) : null}

            {/*<Divider sx={{ margin: "1rem 0 " }} />*/}
            <Typography variant="inputLabel" gutterBottom>
                You'll refund
            </Typography>
            <AmountsTable data={refundData} />
        </DialogComponent>
    );
};

export default RefundCatalogItemDialog;

interface AmountsTableProps {
    data: RefundCatalogAmounts;
}
export const AmountsTable = (props: AmountsTableProps) => {
    return (
        <Table className="transaction-table">
            <TableHead>
                <TableRow>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Unit price</TableCell>
                    <TableCell>Tax</TableCell>
                    <TableCell>Fees</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>{props.data.quantity}</TableCell>
                    <TableCell>
                        <CurrencyComponent value={props.data.unitPrice} />
                    </TableCell>
                    <TableCell>
                        <CurrencyComponent value={props.data.tax} />
                    </TableCell>
                    <TableCell>
                        <CurrencyComponent value={props.data.serviceCharge} />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};
