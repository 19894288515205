import { z } from "zod";
import {
    CalendarView,
    CompanyItem,
    CompanyPreferences,
    EventGroupFilter,
    StartPage,
    UserCredentials,
    UserPreferences,
} from "./Creds";
import { InterfaceToZod } from "./UtilsTypes";

const StartPageEnum = z.nativeEnum(StartPage);
const EventGroupFilterEnum = z.nativeEnum(EventGroupFilter);
const WeekStartSchema = z.number().min(0).max(6);
const CalendarViewSchema = z.nativeEnum(CalendarView);
const CompanyItemSchema = z.object<InterfaceToZod<CompanyItem>>({
    companyId: z.number(),
    companyName: z.string(),
});
export const UserPrefencesSchema = z.object<InterfaceToZod<UserPreferences>>({
    defaultCalendarView: CalendarViewSchema,
    showZeroTicketTimes: z.boolean(),
    showInactiveEvents: z.boolean(),
    user24HourTime: z.boolean(),
    firstDayOfWeek: WeekStartSchema,
});
export const CompanyPreferencesSchema = z.object<InterfaceToZod<CompanyPreferences>>({
    defaultPage: StartPageEnum,
    defaultEventGroupId: z.union([EventGroupFilterEnum, z.number()]),
    resourceCalendarDefaultTypes: z.array(z.number()).nullable(),
});
export const UserCredentialsSchema = z.object<InterfaceToZod<UserCredentials>>({
    firstName: z.string(),
    lastName: z.string(),
    oid: z.string(),
    companyID: z.number(),
    systemAdmin: z.boolean().optional(),
    preferences: UserPrefencesSchema.nullable(),
    companies: z.array(CompanyItemSchema),
    companyPreferences: CompanyPreferencesSchema.nullable(),
});
