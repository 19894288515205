import { PropsWithChildren } from "react";
import { useIndexicTheme } from "theme/useIndexicTheme";

const SummaryCard = (props: PropsWithChildren) => {
    const theme = useIndexicTheme();
    return (
        <div
            style={{
                background: theme.palette.grey["200"],
                padding: "1rem 1rem 2.5rem 1rem",
                width: "100%",
                borderRadius: "var(--global-radius, 0.5rem)",
            }}
        >
            {props.children}
        </div>
    );
};

export default SummaryCard;
