import React, { FunctionComponent, useState } from "react";
import {
    Alert,
    Button,
    Fade,
    LinearProgress,
    Tab,
    Tabs,
    TextField,
    TextFieldProps,
    Typography,
} from "@mui/material";
import DialogComponent, { DialogHooks } from "../../../../shared/components/DialogComponent";
import { CartReservation, TicketsRequired } from "../../../../shared/models/Cart";
import { SplitPaymentCalculator, SplitPaymentRate } from "./SplitPaymentCalculator";
import { useIndexicTheme } from "../../../../theme/useIndexicTheme";
import { useShoppingCartSlice } from "../../../../store/ShoppingCart/useShoppingCartSlice";
import { FlatButton } from "../../../../shared/components/FlatButton";
import { DeleteForeverOutlined } from "@mui/icons-material";
import { getShoppingCartNotifications } from "../../ShoppingCartNotifications";
import useNotification from "../../../../shared/hooks/useNotification";

interface ShoppingCartActionsProps {
    reservation: CartReservation;
}
enum AdjustmentPanel {
    split = "split",
    deposit = "deposit",
    remove = "remove",
}
export const ShoppingCartAdjustments: FunctionComponent<ShoppingCartActionsProps> = (props) => {
    const theme = useIndexicTheme();
    const { adjustReservationAmount, adjustReservationTickets, removeAdjustments } =
        useShoppingCartSlice();
    const { showNotification } = useNotification();
    const [openDialog, handleClickOpen, handleCloseDialog] = DialogHooks();
    const [adjustmentTab, setAdjustmentTab] = useState<AdjustmentPanel>(AdjustmentPanel.split);
    const [amount, setAmount] = useState(0);
    const [splitTicketsSelected, setSplitTicketsSelected] = useState<SplitPaymentRate[]>([]);
    const [loading, setLoading] = useState(false);
    const [splitError, setSplitError] = useState("");
    const [depositError, setDepositError] = useState("");
    const onCloseDialog = async () => {
        handleCloseDialog();
        // clean up
        setAmount(0);
        setSplitError("");
        setDepositError("");
        setSplitTicketsSelected([]);
        setLoading(false);
    };
    const handleChange = (event: React.SyntheticEvent, newValue: AdjustmentPanel) => {
        setAdjustmentTab(newValue);
    };
    const handleSplitTickets = (tickets: SplitPaymentRate[]) => {
        setSplitTicketsSelected(tickets);
        setSplitError("");
    };
    const handleDepositAmount: TextFieldProps["onChange"] = (e) => {
        const value = Number(e.target.value);
        setAmount(value);
        setDepositError("");
    };

    const applyReservationAmountAdjustment = async (amount: number) => {
        const notification = getShoppingCartNotifications("AddAdjustment");
        try {
            setLoading(true);
            await adjustReservationAmount(props.reservation.reservationId, amount);
            showNotification(notification.success);
            handleCloseDialog();
        } catch (e) {
            showNotification(notification.error);
        } finally {
            setLoading(false);
        }
    };
    const applyReservationTicketsAdjustment = async (data: SplitPaymentRate[]) => {
        const notification = getShoppingCartNotifications("AddAdjustment");
        const tickets: TicketsRequired[] = data
            .filter((el) => el.quantity > 0)
            .map((el) => ({ rateId: el.rate.rateId, tickets: el.quantity }));
        try {
            setLoading(true);
            const reservationId = props.reservation.reservationId;
            await adjustReservationTickets(reservationId, tickets);
            showNotification(notification.success);
            handleCloseDialog();
        } catch (e) {
            showNotification(notification.error);
        } finally {
            setLoading(false);
        }
    };
    const onApplyAdjustment = () => {
        if (adjustmentTab === AdjustmentPanel.split) {
            const hasTicketsSelected =
                splitTicketsSelected.filter((el) => el.quantity > 0).length > 0;
            if (!hasTicketsSelected) {
                setSplitError("Select at least one ticket");
                return;
            }
            return applyReservationTicketsAdjustment(splitTicketsSelected);
        }
        if (adjustmentTab === AdjustmentPanel.deposit) {
            if (amount < 0) {
                setDepositError("Deposit amount must be 0 or greater");
                return;
            }
            return applyReservationAmountAdjustment(amount);
        }
    };
    const removeReservationAdjustments = async () => {
        const notification = getShoppingCartNotifications("RemoveAdjustment");
        try {
            setLoading(true);
            const { reservationId } = props.reservation;
            await removeAdjustments(reservationId);
            showNotification(notification.success);
            handleCloseDialog();
        } catch (e) {
            showNotification(notification.error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <Button
                variant={"text"}
                color={"primary"}
                sx={{
                    color: (theme) => theme.palette.success.main,
                }}
                onClick={handleClickOpen}
            >
                Adjustments
            </Button>

            <DialogComponent
                isFullSize={false}
                maxWidth="lg"
                open={openDialog}
                handleClose={onCloseDialog}
                DialogText={"Select an adjustment"}
                ButtonActions={{
                    handleSuccessActionText: "Apply adjustment",
                    handleSuccessActionFunction: onApplyAdjustment,
                    handleCancelActionFunction: onCloseDialog,
                    handleCancelActionText: "Cancel",
                }}
            >
                <div
                    style={{
                        minHeight: "200px",
                        minWidth: "350px",
                    }}
                >
                    <Fade in={loading}>
                        <LinearProgress />
                    </Fade>
                    <Tabs
                        value={adjustmentTab}
                        onChange={handleChange}
                        aria-label="adjustment tabs"
                        sx={{
                            marginBottom: "0.5rem",
                        }}
                    >
                        <Tab label="Deposit" value={AdjustmentPanel.deposit} />
                        <Tab label="Split payment" value={AdjustmentPanel.split} />
                        <Tab label="Remove adjustments" value={AdjustmentPanel.remove} />
                    </Tabs>
                    <div
                        style={{
                            position: "relative",
                        }}
                    >
                        <Fade in={adjustmentTab === AdjustmentPanel.deposit} unmountOnExit>
                            <div>
                                <Typography
                                    variant={"headerExpandableTitle"}
                                    component={"p"}
                                    style={{ marginBottom: "0.5rem", opacity: 1 }}
                                >
                                    Deposit amount
                                </Typography>
                                <TextField
                                    placeholder={"Deposit"}
                                    size={"small"}
                                    value={amount}
                                    onChange={handleDepositAmount}
                                    onFocus={(e) => e.target.select()}
                                    type={"number"}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                        },
                                    }}
                                    fullWidth
                                    sx={{ marginBottom: "1rem" }}
                                />
                                <Fade in={depositError !== ""} unmountOnExit>
                                    <Alert
                                        severity={"warning"}
                                        variant={"filled"}
                                        sx={{
                                            backgroundColor: theme.palette.warning.light,
                                            color: theme.palette.grey["900"],
                                            fontWeight: "500",
                                        }}
                                    >
                                        {depositError}
                                    </Alert>
                                </Fade>
                            </div>
                        </Fade>
                        <Fade in={adjustmentTab === AdjustmentPanel.split} unmountOnExit>
                            <div>
                                <Typography
                                    variant={"headerExpandableTitle"}
                                    component={"p"}
                                    style={{ marginBottom: "0.5rem", opacity: 1 }}
                                >
                                    Split payment
                                </Typography>
                                <SplitPaymentCalculator
                                    rates={props.reservation.rates}
                                    handleChange={handleSplitTickets}
                                />
                                <Fade in={splitError !== ""} unmountOnExit>
                                    <Alert
                                        severity={"warning"}
                                        variant={"filled"}
                                        sx={{
                                            backgroundColor: theme.palette.warning.light,
                                            color: theme.palette.grey["900"],
                                            fontWeight: "500",
                                        }}
                                    >
                                        {splitError}
                                    </Alert>
                                </Fade>
                            </div>
                        </Fade>
                        <Fade in={adjustmentTab === AdjustmentPanel.remove} unmountOnExit>
                            <FlatButton
                                startIcon={<DeleteForeverOutlined />}
                                onClick={removeReservationAdjustments}
                            >
                                Remove adjustments
                            </FlatButton>
                        </Fade>
                    </div>
                </div>
            </DialogComponent>
        </>
    );
};
