import { useAppDispatch, useAppSelector } from "../Store";
import {
    clearCartAction,
    clearGiftCardRecipientPanelAction,
    emptyCartAction,
    initializeCart,
    openGiftCardRecipientPanelAction,
    setAddressBillingInfo,
    setCart,
    setCartError,
    setCartSuccess,
    setContactBillingInfo,
    setHowDidYouFindUs,
    setPreCheckoutData,
    ShoppingCartState,
} from "./ShoppingCartSlice";
import { fetchCartThunk } from "./ShoppingCartThunks";
import {
    CartItem,
    GiftCardRecipient,
    PreCheckoutData,
    ReservationTip,
    TicketsRequired,
    TipMethod,
} from "../../shared/models/Cart";
import { IAddressMultiline } from "../../shared/models/Address";
import { IContactInfo } from "../../shared/models/ContactInfo";
import { ICartSuccessMessage, IPaymentFailureMessage } from "../../shared/models/SignalR";
import { LocalStorageService } from "../../shared/services/LocalStorageService";
import { CatalogDetail, CatalogItem, MiscellaneousItem } from "../../shared/models/Catalog";
import ShoppingCartService from "../../shared/services/ShoppingCartService";
import { GiftCardRecipientsData } from "../../shared/models/GiftCardRecipientsData";
import shoppingCartService from "../../shared/services/ShoppingCartService";
import { ReservationDetails } from "shared/models/Reservation";

export const useShoppingCartSlice = () => {
    const shoppingCartState: ShoppingCartState = useAppSelector((state) => state.shoppingCart);
    const dispatch = useAppDispatch();

    const fetchShoppingCart = (cartId: string) => {
        dispatch(fetchCartThunk(cartId));
    };
    const initializeShoppingCart = () => {
        dispatch(initializeCart());
    };
    const updatePreCheckoutData = (data: PreCheckoutData) => {
        dispatch(setPreCheckoutData(data));
    };
    const updateAddressInfo = (data: IAddressMultiline) => {
        dispatch(setAddressBillingInfo(data));
    };
    const updateContactInfo = (data: IContactInfo) => {
        dispatch(setContactBillingInfo(data));
    };

    const addNewReservationToCart = async (reservation: ReservationDetails) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId) {
            return;
        }
        const result = await ShoppingCartService.addReservationToCart(cartId, reservation);
        dispatch(setCart(result));
    };

    const addExistingReservation = async (reservationId: number) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId) {
            return;
        }
        const dto = await ShoppingCartService.addExistingReservation(cartId, reservationId);
        dispatch(setCart(dto));
    };
    const applyCoupon = async (couponCode: string) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId || cartId === "Empty") {
            return;
        }
        const dto = await ShoppingCartService.applyCoupon(cartId, couponCode);
        dispatch(setCart(dto));
    };
    const removeReservation = async (reservationId: number) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId || cartId === "Empty") {
            return;
        }
        const dto = await ShoppingCartService.removeReservation(cartId, reservationId);
        dispatch(setCart(dto));
    };

    const setHowDidYouFindUsOption = (optionId: number) => {
        dispatch(setHowDidYouFindUs(optionId));
    };
    const setCartSuccessMessage = (message: ICartSuccessMessage) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId || cartId === "Empty") {
            return;
        }
        if (message.CartId === cartId) {
            dispatch(setCartSuccess(message));
        }
    };
    const setCartErrorMessage = (message: IPaymentFailureMessage) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId || cartId === "Empty") {
            return;
        }
        if (message.CartId === cartId) {
            dispatch(setCartError(message));
        }
    };
    const clearCart = () => {
        dispatch(clearCartAction());
        LocalStorageService.clearShoppingCartId();
    };
    const addMiscellaneousItem = async (item: MiscellaneousItem) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId) {
            return;
        }
        const dto = await ShoppingCartService.addMiscellaneousItem(cartId, item);
        dispatch(setCart(dto));
    };
    const addCatalogItem = async (item: CatalogItem) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId) {
            return;
        }
        const dto = await ShoppingCartService.addCatalogItem(cartId, item);
        dispatch(setCart(dto));
        if (dto.errors.length > 0) {
            throw new Error(dto.errors[0]);
        }
    };
    const adjustReservationAmount = async (reservationId: number, amount: number) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId) {
            return;
        }
        const dto = await ShoppingCartService.adjustReservationAmount(
            cartId,
            reservationId,
            amount,
        );
        dispatch(setCart(dto));
    };
    const adjustReservationTickets = async (reservationId: number, data: TicketsRequired[]) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId) {
            return;
        }
        const dto = await ShoppingCartService.adjustReservationTickets(cartId, reservationId, data);
        dispatch(setCart(dto));
    };
    const removeAdjustments = async (reservationId: number) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId) {
            return;
        }
        const dto = await ShoppingCartService.removeAdjustments(cartId, reservationId);
        dispatch(setCart(dto));
    };
    const addTip = async (method: TipMethod, quantity: number, reservationId: number) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId) {
            return;
        }
        let data: ReservationTip | null = null;
        if (method === TipMethod.Percentage) {
            data = {
                tipMethod: TipMethod.Percentage,
                percentTip: quantity,
                fixedAmount: 0,
                reservationIdAddOn: reservationId,
            };
        }
        if (method === TipMethod.FixedAmount) {
            data = {
                tipMethod: TipMethod.FixedAmount,
                percentTip: 0,
                fixedAmount: quantity,
                reservationIdAddOn: reservationId,
            };
        }
        if (data !== null) {
            const dto = await ShoppingCartService.addTip(cartId, data);
            dispatch(setCart(dto));
        }
    };
    const removeCatalogItem = async (catalogItem: CatalogItem) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId) {
            return;
        }
        const dto = await ShoppingCartService.removeCatalogItem(cartId, catalogItem);
        dispatch(setCart(dto));
    };
    const emptyCart = async () => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId) {
            return;
        }
        const dto = await ShoppingCartService.emptyCart(cartId);
        dispatch(emptyCartAction(dto));
    };
    const showGiftCardRecipientList = (item: CartItem) => {
        const giftCardRecipientsData = GiftCardRecipientsData.fromCartItem(item);
        dispatch(openGiftCardRecipientPanelAction(giftCardRecipientsData.serialize()));
    };
    const addNewRecipients = (item: CatalogDetail, quantity: number) => {
        const giftCardRecipientsData = GiftCardRecipientsData.newRecipientsFromCatalogDetails(
            item,
            quantity,
        );
        // Needed since redux toolkit complains with classes
        dispatch(openGiftCardRecipientPanelAction(giftCardRecipientsData.serialize()));
    };
    const editGiftCardRecipient = async (catalogId: number, recipient: GiftCardRecipient) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId) {
            return;
        }
        const dto = await shoppingCartService.editGiftCardRecipient(catalogId, cartId, recipient);
        dispatch(setCart(dto));
    };
    const removeGiftCardRecipient = async (catalogId: number, recipientId: number) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId) {
            return;
        }
        const dto = await shoppingCartService.removeGiftCardRecipient(
            catalogId,
            cartId,
            recipientId,
        );
        dispatch(setCart(dto));
    };
    const saveGiftCardRecipients = async (catalogId: number, recipients: GiftCardRecipient[]) => {
        const cartId = shoppingCartState.cart?.cartId;
        if (!cartId) {
            return;
        }
        const dto = await shoppingCartService.addGiftCardRecipients(cartId, catalogId, recipients);
        dispatch(setCart(dto));
    };
    const clearGiftCardRecipientPanel = () => dispatch(clearGiftCardRecipientPanelAction());
    return {
        shoppingCartState,
        cart: shoppingCartState.cart,
        billingInfo: shoppingCartState.billingInfo,
        fetchShoppingCart,
        updatePreCheckoutData,
        updateAddressInfo,
        updateContactInfo,
        addNewReservationToCart,
        addExistingReservation,
        applyCoupon,
        removeReservation,
        initializeShoppingCart,
        setHowDidYouFindUsOption,
        setCartSuccessMessage,
        clearCart,
        setCartErrorMessage,
        addMiscellaneousItem,
        addCatalogItem,
        adjustReservationAmount,
        adjustReservationTickets,
        removeAdjustments,
        addTip,
        removeCatalogItem,
        emptyCart,
        showGiftCardRecipientList,
        addNewRecipients,
        clearGiftCardRecipientPanel,
        removeGiftCardRecipient,
        saveGiftCardRecipients,
        editGiftCardRecipient,
    };
};
