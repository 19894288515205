import {
    BillingInfo,
    CartDTO,
    GiftCardRecipient,
    PaymentOnFile,
    PreCheckoutData,
} from "shared/models/Cart";
import { createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AsyncThunkStatus } from "shared/models/UtilsTypes";
import { Nullable } from "shared/models/Generals";
import { fetchCartThunk } from "./ShoppingCartThunks";
import { IContactInfo } from "shared/models/ContactInfo";
import { IAddressMultiline } from "shared/models/Address";
import { GatewayID } from "modules/Checkout/processors/Gateway";
import {
    ICartSuccessMessage,
    IPaymentFailureMessage,
    StripeCartTerminalMessage,
} from "../../shared/models/SignalR";
import { CatalogDetail } from "../../shared/models/Catalog";
import {
    GiftCardRecipientsData,
    GiftCardRecipientsSerialized,
} from "../../shared/models/GiftCardRecipientsData";

export const EMPTY_CART_ID = "Empty";
export const initialCartData: CartDTO = {
    cartId: EMPTY_CART_ID,
    companySlug: null,
    companyId: 0,
    isEmpty: true,
    reservations: [],
    catalogItems: [],
    appliedGiftCards: [],
    clickAgreements: [],
    errors: [],
    warnings: [],
    buyerServiceCharge: 0,
    tax: 0,
    subTotal: 0,
    depositBalance: -1,
    totalCartDiscount: 0,
    processorSpecificJson: "",
};
const initialBillingInfo: BillingInfo = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
};
export type GiftCardRecipientCatalogDetails = Pick<
    CatalogDetail,
    "catalogId" | "description" | "itemCode" | "giftCards" | "unitPrice"
>;
export interface GiftCardRecipientPanelMessage {
    item: GiftCardRecipientCatalogDetails;
    recipients?: GiftCardRecipient[];
}
export interface ShoppingCartState {
    cart: CartDTO | null;
    billingInfo: BillingInfo;
    gatewayId: GatewayID | null;
    paymentsOnFile: PaymentOnFile[] | null;
    checkoutTransaction: string | null;
    howHeardAnswerId: number;
    error: Nullable<SerializedError>;
    status: AsyncThunkStatus;
    stripeCartMessage: Nullable<StripeCartTerminalMessage>;
    giftCardRecipientPanelMessage: Nullable<GiftCardRecipientsSerialized>;
}
const initialState: ShoppingCartState = {
    cart: null,
    billingInfo: initialBillingInfo,
    gatewayId: null,
    paymentsOnFile: null,
    checkoutTransaction: null,
    howHeardAnswerId: 0,
    error: null,
    status: "initial",
    stripeCartMessage: null,
    giftCardRecipientPanelMessage: null,
};

const slice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        setCart: (state, param: PayloadAction<CartDTO>) => {
            state.cart = param.payload;
            if (state.giftCardRecipientPanelMessage != null) {
                const catalogId = state.giftCardRecipientPanelMessage.giftCardData.catalogId;
                const item = param.payload.catalogItems.find((el) => el.catalogId === catalogId);
                if (item) {
                    state.giftCardRecipientPanelMessage =
                        GiftCardRecipientsData.fromCartItem(item).serialize();
                } else {
                    // Last recipient is removed
                    state.giftCardRecipientPanelMessage = null;
                }
            }
        },
        setContactBillingInfo: (state, param: PayloadAction<IContactInfo>) => {
            state.billingInfo = { ...state.billingInfo, ...param.payload };
        },
        setAddressBillingInfo: (state, param: PayloadAction<IAddressMultiline>) => {
            state.billingInfo = { ...state.billingInfo, ...param.payload };
        },
        setPreCheckoutData: (state, param: PayloadAction<PreCheckoutData>) => {
            const { payload } = param;
            state.billingInfo = payload.billingInfo;
            state.gatewayId = payload.gatewayId;
            state.cart = payload.cartDTO;
            state.paymentsOnFile = payload.paymentsOnFile;
        },
        initializeCart: (state) => {
            state.cart = initialCartData;
        },
        setHowDidYouFindUs: (state, param: PayloadAction<number>) => {
            state.howHeardAnswerId = param.payload;
        },
        setCartSuccess: (state, param: PayloadAction<ICartSuccessMessage>) => {
            state.stripeCartMessage = { isSuccess: true, data: param.payload };
        },
        setCartError: (state, param: PayloadAction<IPaymentFailureMessage>) => {
            state.stripeCartMessage = { isSuccess: false, data: param.payload };
        },
        clearCartAction: (state) => {
            state.cart = initialCartData;
            state.billingInfo = initialBillingInfo;
            state.gatewayId = null;
            state.paymentsOnFile = null;
            state.howHeardAnswerId = 0;
            state.error = null;
            state.stripeCartMessage = null;
        },
        emptyCartAction: (state, param: PayloadAction<CartDTO>) => {
            state.cart = param.payload;
            state.billingInfo = initialBillingInfo;
            state.gatewayId = null;
            state.paymentsOnFile = null;
            state.howHeardAnswerId = 0;
            state.error = null;
            state.stripeCartMessage = null;
        },
        openGiftCardRecipientPanelAction: (
            state,
            param: PayloadAction<GiftCardRecipientsSerialized>,
        ) => {
            state.giftCardRecipientPanelMessage = param.payload;
        },
        clearGiftCardRecipientPanelAction: (state) => {
            state.giftCardRecipientPanelMessage = null;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchCartThunk.fulfilled, (state, action) => {
            state.cart = action.payload;
            state.status = "fulfilled";
        });
        builder.addCase(fetchCartThunk.pending, (state) => {
            state.status = "pending";
        });

        builder.addCase(fetchCartThunk.rejected, (state, action) => {
            state.error = action.error;
            state.status = "rejected";
        });
    },
});

export const shoppingCartReducer = slice.reducer;
export const {
    setAddressBillingInfo,
    setContactBillingInfo,
    setCart,
    setPreCheckoutData,
    initializeCart,
    setHowDidYouFindUs,
    setCartSuccess,
    clearCartAction,
    setCartError,
    emptyCartAction,
    openGiftCardRecipientPanelAction,
    clearGiftCardRecipientPanelAction,
} = slice.actions;
export default shoppingCartReducer;
