import React, { useEffect, useState } from "react";
import styles from "./TransactionDetails.module.css";
import { Grid, LinearProgress, TextField, Typography } from "@mui/material";
import NewDetailsSkeleton from "shared/components/NewDetailsSkeleton";
import { LoadingButton } from "@mui/lab";
import { RefundCatalogItem, RefundReservation, RefundTransaction } from "shared/models/Transaction";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { TransactionReservationDetail } from "shared/models/Reservation";
import { NotificationsType } from "shared/hooks/useNotification";
import { isEmpty, updateOrAppendToArray } from "shared/utils/Common";
import { Print } from "@mui/icons-material";
import BillingInfoViewer from "./components/BillingInfoViewer";
import { MathEx } from "shared/utils/MathEx";
import PaymentInfo from "./components/PaymentInfo";
import RefundTable from "./components/RefundTable";
import RefundService from "shared/services/RefundService";
import receiptReportService from "shared/services/ReceiptReportService";
import { handlePrint } from "shared/utils/Common";
import transactionService from "shared/services/TransactionService";
import SendIcon from "@mui/icons-material/Send";
import LabelDivider from "shared/components/LabelDivider";
import ReceiptTotal from "./components/ReceiptTotal";
import { ReactComponent as RefundIcon } from "../../assets/icons/amend_outlined.svg";
import { useQuery } from "@tanstack/react-query";
import { useReservationModalContext } from "modules/Reservation/context/ReservationModalProvider";
import CompanyInfoViewer from "./components/CompanyInfoViewer";
import { ManageErrorMessage } from "../../shared/services/ErrorService";
import { APIError } from "../../shared/http/errors";

interface TransactionDetailsProps {
    transactionId: number;
}

const TransactionDetails = (props: TransactionDetailsProps) => {
    const { showNotification } = useReservationModalContext();
    const [refundTotal, setRefundTotal] = useState(0);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [refundReservations, setRefundReservations] = useState<RefundReservation[]>([]);
    const [refundCatalogItems, setRefundCatalogItems] = useState<RefundCatalogItem[]>([]);
    const [loading, setLoading] = useState(false);

    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    const transactionDetailsQuery = useQuery(["transaction-details", props.transactionId], () =>
        transactionService.getReceipt(props.transactionId),
    );

    useEffect(() => {
        handleCalcTotal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refundReservations, refundCatalogItems]);

    const sendReceipt = async () => {
        if (!isEmpty(email)) {
            setLoadingRequest(true);
            try {
                await transactionService.sendReceipt(
                    Number(transactionDetailsQuery.data?.receipt.transactionID),
                    email,
                );
                showNotification({
                    message: "Email sent successfully",
                    type: NotificationsType.success,
                });
                setEmail("");
            } catch (e) {
                const message = ManageErrorMessage(e);
                showNotification({
                    message,
                    type: NotificationsType.error,
                });

                //     const notifications = result.errors.map((err) => ({
                //         message: err,
                //         type: NotificationsType.error,
                //     }));
                //     NotificationsApi.addMany(notifications);
                // }
            }
        } else {
            setEmailError("Please enter a valid email");
        }
        setLoadingRequest(false);
    };

    const handleReservationRefund = (data: RefundReservation) => {
        const idx = refundReservations.findIndex((el) => el.reservationId === data.reservationId);
        const newRefundItems = updateOrAppendToArray(refundReservations, data, idx);
        setRefundReservations(newRefundItems.filter((el) => el.refundAmount > 0));
    };

    const handleCatalogItemRefund = (data: RefundCatalogItem) => {
        const idx = refundCatalogItems.findIndex((el) => el.catalogId === data.catalogId);
        const newRefundItems = updateOrAppendToArray(refundCatalogItems, data, idx);
        setRefundCatalogItems(newRefundItems.filter((el) => el.refundQuantity > 0));
    };

    const handleCalcTotal = () => {
        const reservations = refundReservations.reduce(
            (total, el) => total + el.refundAmount + el.refundServiceCharge,
            0,
        );
        const catalogItems = refundCatalogItems.reduce(
            (total, el) =>
                total + el.refundQuantity * el.unitPrice + el.refundTax + el.refundServiceCharge,
            0,
        );
        setRefundTotal(reservations + catalogItems);
    };

    const handleRefund = async () => {
        setLoading(true);
        const refunded: RefundReservation[] = [];
        refundReservations.forEach((value) => {
            if (value.refundAmount > 0) {
                const tickets: TransactionReservationDetail[] = [];
                value.ticketEdits.forEach((ticket) => {
                    tickets.push(ticket);
                });
                refunded.push({ ...value, ticketEdits: tickets });
            }
        });
        if (transactionDetailsQuery.data?.refunds) {
            const data: RefundTransaction = {
                transactionId: transactionDetailsQuery.data.refunds?.transactionId,
                refundAmount: 0,
                refundTax: 0,
                maxTotalRefund: transactionDetailsQuery.data.refunds?.maxTotalRefund,
                refundReservations: refunded,
                refundCatalogItems,
            };
            setLoadingRequest(true);
            try {
                await RefundService.refundTransaction(data);
                showNotification({
                    message: "Transaction refunded successfully",
                    type: NotificationsType.success,
                });

                setRefundTotal(0);
                await transactionDetailsQuery.refetch();
                setIsUpdated(!isUpdated);
            } catch (e) {
                let message = "Error refunding transaction";
                if (e instanceof APIError) {
                    const errorData: any = e.data;
                    if (errorData.paymentGatewayResult) {
                        const errorsArr = errorData.paymentGatewayResult.errors || [];
                        if (errorsArr.length > 0) {
                            message = errorsArr[0];
                        }
                    }
                } else {
                    message = ManageErrorMessage(e);
                }
                showNotification({
                    message: message,
                    type: NotificationsType.error,
                });
            }
            setLoadingRequest(false);
        }
        setLoading(false);
    };

    const handlePrintReport = async () => {
        if (transactionDetailsQuery.data?.receipt.obscuredTransaction) {
            setLoadingRequest(true);
            try {
                const result = await receiptReportService.getReceiptReport(
                    transactionDetailsQuery.data?.receipt.obscuredTransaction,
                );
                handlePrint(result);
            } catch {
                showNotification({
                    message: "Error to print report",
                    type: NotificationsType.error,
                });
            }
            setLoadingRequest(false);
        }
    };

    if (!transactionDetailsQuery.data) {
        return <NewDetailsSkeleton />;
    }
    const companyInfoData = transactionDetailsQuery.data?.receipt.reservations.find(
        (res) => res.companyID === transactionDetailsQuery.data?.receipt.cartCompanyId,
    );
    return (
        <div className={styles.transactionDetailContainer}>
            <div>
                <div className={styles.transactionDetailHeader}>
                    <Typography
                        sx={{
                            fontSize: "0.75rem",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            color: "var(--grey-600)",
                            letterSpacing: "0.075rem",
                        }}
                    >
                        Transaction
                    </Typography>
                    <Typography
                        sx={{ fontSize: "1.1rem", fontWeight: 500, color: "var(--grey-900)" }}
                    >
                        {transactionDetailsQuery.data?.receipt.transactionID}
                    </Typography>
                </div>

                <div className={styles.transactionDetailContainer}>
                    <div>
                        {loadingRequest && (
                            <LinearProgress
                                sx={{
                                    position: "fixed",
                                    width: "38.5%",
                                    top: "1px",
                                    height: ".5rem",
                                    marginRight: "1rem",
                                }}
                            />
                        )}
                        {transactionDetailsQuery.data ? (
                            <Grid container spacing={4}>
                                <Grid item>
                                    <Typography
                                        variant={"inputLabel"}
                                        sx={{
                                            fontSize: "0.875rem",
                                        }}
                                        component={"p"}
                                    >
                                        Print receipt
                                    </Typography>
                                    <LoadingButton
                                        startIcon={<Print />}
                                        sx={{ marginRight: "10px" }}
                                        onClick={handlePrintReport}
                                    >
                                        Print
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography
                                        variant={"inputLabel"}
                                        sx={{
                                            fontSize: "0.875rem",
                                        }}
                                        component={"p"}
                                    >
                                        Send receipt to:
                                    </Typography>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "0.5rem",
                                        }}
                                    >
                                        <TextField
                                            size="small"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                setEmailError("");
                                            }}
                                            placeholder={"Send email"}
                                            fullWidth
                                            helperText={emailError}
                                            error={!isEmpty(emailError)}
                                        />
                                        <LoadingButton
                                            size="small"
                                            variant="contained"
                                            loading={loadingRequest}
                                            onClick={sendReceipt}
                                            startIcon={<SendIcon />}
                                        >
                                            Send
                                        </LoadingButton>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <LabelDivider label="Receipt Details" />
                                </Grid>

                                <Grid item sm={12}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={6}>
                                            <BillingInfoViewer
                                                billingInfo={
                                                    transactionDetailsQuery.data.receipt.billingInfo
                                                }
                                            />
                                            <Typography
                                                sx={{
                                                    color: "var(--grey-900)",
                                                    fontSize: "0.875rem",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {transactionDetailsQuery.data.receipt.email}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CompanyInfoViewer data={companyInfoData} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaymentInfo data={transactionDetailsQuery.data.receipt} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReceiptTotal
                                        subTotal={transactionDetailsQuery.data.receipt.subTotal}
                                        serviceCharge={
                                            transactionDetailsQuery.data.receipt.serviceCharge
                                        }
                                        tax={transactionDetailsQuery.data.receipt.tax}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <LabelDivider label="Item & Refund" />
                                </Grid>
                                <Grid item xs={12}>
                                    <RefundTable
                                        refundReservations={
                                            transactionDetailsQuery.data.refunds
                                                .refundReservations || []
                                        }
                                        reservations={
                                            transactionDetailsQuery.data.receipt.reservations || []
                                        }
                                        catalogItems={transactionDetailsQuery.data.receipt.items}
                                        refundCatalogItems={
                                            transactionDetailsQuery.data.refunds
                                                .refundCatalogItems || []
                                        }
                                        isUpdated={isUpdated}
                                        handleReservationRefund={handleReservationRefund}
                                        handleCatalogItemRefund={handleCatalogItemRefund}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ display: "flex", justifyContent: "flex-end" }}
                                >
                                    <LoadingButton
                                        loading={loading}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<RefundIcon />}
                                        disabled={refundTotal === 0}
                                        onClick={handleRefund}
                                    >
                                        Refund {MathEx.formatCurrency(refundTotal)} plus tax
                                    </LoadingButton>
                                </Grid>

                                {transactionDetailsQuery.data.receipt.clickAgreements.length > 0 ? (
                                    <Grid item xs={12}>
                                        <Typography variant={"h6"}>
                                            Checkout click agreements:
                                        </Typography>

                                        {transactionDetailsQuery.data.receipt.clickAgreements.map(
                                            (el, idx) => (
                                                <ReactMarkdown
                                                    // target="_blank"
                                                    children={el.agreement}
                                                    rehypePlugins={[rehypeRaw as any]}
                                                    key={idx}
                                                />
                                            ),
                                        )}
                                    </Grid>
                                ) : null}
                            </Grid>
                        ) : (
                            <Typography> Error to get transaction detail try again</Typography>
                        )}
                    </div>
                </div>
            </div>

            {transactionDetailsQuery.isFetching ? (
                <LinearProgress
                    sx={{
                        position: "fixed",
                        width: "38.5%",
                        top: "1px",
                        height: ".5rem",
                        marginRight: "1rem",
                    }}
                />
            ) : null}
        </div>
    );
};

export default TransactionDetails;
