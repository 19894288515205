import React from "react";
import { Typography, Box } from "@mui/material";

// This Page is a Generic Catch All Page to Show when the app is in a
// transition or for use when there is something wrong and there is
// no other place to go. The component accepts a Message parameter
interface LogoPageProps {
    message: string;
}

const LogoPage = (props: LogoPageProps) => {
    return (
        <div data-cy="TransitionPage"
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                maxWidth: "450px",
                maxHeight: "450px",
                width: "50%",
                height: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                    height: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 10,
                    }}
                >
                    <img
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                        alt="logo"
                        height={"50%"}
                        src="/indexic_full_logo.png"
                    />
                    <Typography variant="h3">{props.message}</Typography>
                </div>
            </div>
        </div>
    );
};

export default LogoPage;
