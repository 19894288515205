import React, { FunctionComponent, useEffect, useState } from "react";
import {
    RefundCatalogItem,
    RefundReservation,
    TransactionItem,
    TransactionReservation,
} from "shared/models/Transaction";
import "shared/components/TransactionTable.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Each } from "../../../shared/components/Each";
import RefundReservationRow from "./RefundReservationRow";
import RefundCatalogItemRow from "./RefundCatalogItemRow";

interface RefundReservationTableProps {
    reservations: TransactionReservation[];
    catalogItems: TransactionItem[];
    refundReservations: RefundReservation[];
    refundCatalogItems: RefundCatalogItem[];
    handleReservationRefund: (data: RefundReservation) => void;
    handleCatalogItemRefund: (data: RefundCatalogItem) => void;
    isUpdated: boolean;
}
type Props = RefundReservationTableProps;
type ReservationRefundEntry = { reservation: TransactionReservation; refund?: RefundReservation };
type CatalogItemRefundEntry = {
    catalogItem: TransactionItem;
    refundCatalogItem?: RefundCatalogItem;
};
const RefundTable: FunctionComponent<Props> = (props) => {
    const [reservations, setReservations] = useState<ReservationRefundEntry[]>([]);
    const [catalogItems, setCatalogItems] = useState<CatalogItemRefundEntry[]>([]);
    useEffect(() => {
        setReservations(
            props.reservations.map((el) => {
                const refund = props.refundReservations.find(
                    (t) => t.reservationId === el.reservationID,
                );
                return { reservation: el, refund };
            }),
        );
        setCatalogItems(
            props.catalogItems.map((el) => {
                const refund = props.refundCatalogItems.find((t) => t.catalogId === el.catalogID);
                return { catalogItem: el, refundCatalogItem: refund };
            }),
        );
    }, [props]);

    return (
        <TableContainer sx={{ minWidTableCell: "90%" }}>
            <Table className="transaction-table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ textAlign: "left", minWidth: "10rem" }}>
                            Items / Description
                        </TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Tax</TableCell>
                        <TableCell>Fees</TableCell>
                        <TableCell>Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <Each
                        of={reservations}
                        render={(item, key) => (
                            <RefundReservationRow
                                key={key}
                                reservation={item.reservation}
                                refundReservation={item.refund}
                                onSet={props.handleReservationRefund}
                            />
                        )}
                    />
                    <Each
                        of={catalogItems}
                        render={(item, key) => (
                            <RefundCatalogItemRow
                                key={key}
                                catalogItem={item.catalogItem}
                                refundCatalogItem={item.refundCatalogItem}
                                onSet={props.handleCatalogItemRefund}
                            />
                        )}
                    />
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RefundTable;
