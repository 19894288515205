import React, { useEffect, useState } from "react";
import {
    DataGridPro,
    GridCellParams,
    GridColDef,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-pro";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import useNotification, { NotificationsType } from "shared/hooks/useNotification";
import useReports from "shared/hooks/useReports";
import { PayoutDetailReport, PayoutSummaryReport, ReportNames } from "shared/models/Report";
import reportService from "shared/services/ReportService";
import { formatCurrency, formatDateCell } from "shared/utils/Formats";
import ReportName from "./ReportName";
import DateRangeComponent from "shared/components/DateRangeComponent";
import ActionsComponent from "shared/components/Reports/ActionsComponent";
import { Fade, LinearProgress } from "@mui/material";
import { CustomNoRowsOverlay } from "./NoDataComponent";
import DialogComponent, { DialogHooks } from "shared/components/DialogComponent";
import PayoutDetails from "./PayoutDetails";

const PayoutSummary = () => {
    const columns: GridColDef[] = [
        {
            field: "payoutId",
            headerName: "Payout Id",
            type: "string",
            flex: 2,
            renderCell: (params) => {
                return (
                    <a
                        style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                        }}
                        onClick={() => getPayoutDetails(params.value as string)}
                    >
                        {params.value}
                    </a>
                );
            },
        },
        {
            field: "initiated",
            headerName: "Initiated",
            type: "string",
            flex: 1,
            valueFormatter: (param) => formatDateCell(param.value),
        },
        {
            field: "expected",
            headerName: "Expected on",
            type: "date",
            flex: 1,
            valueFormatter: (param) => formatDateCell(param.value),
        },
        {
            field: "amount",
            headerName: "Amount",
            type: "number",
            flex: 1,
            renderCell: (param) => formatCurrency(param.value),
        },
        { field: "status", headerName: "Status", type: "number", flex: 1 },
    ];
    const {
        rows,
        timeType,
        dateRange,
        isLoading,
        setRows,
        changeTimeType,
        parseDateByTimeType,
        setStartDate,
        setEndDate,
        setIsLoading,
        setShowNoDataLabel,
        handlePrintReport,
        saveDateRange,
        loadDateRange,
    } = useReports<PayoutSummaryReport>();

    const { showNotification } = useNotification();
    const [detailRows, setDetailRows] = useState<PayoutDetailReport[]>([]);
    const [payoutIdSelected, setPayoutIdSelected] = useState<string>("");
    const [payoutSelected, setPayoutSelected] = useState<PayoutSummaryReport>();
    const [open, handleClickOpen, handleClose] = DialogHooks();

    useEffect(() => {
        loadDateRange(ReportNames.Payout);
    }, []);

    const getPayoutDetails = async (payoutId: string) => {
        const data = await reportService.getPayoutDetailsReport(payoutId);
        if (data.length > 0) {
            setDetailRows(data.map((el, id) => ({ ...el, id })));
            setPayoutIdSelected(payoutId);
            const tempPayout = rows.find((el) => el.payoutId === payoutId);
            if (tempPayout) {
                setPayoutSelected(tempPayout);
            }
            handleClickOpen();
        } else {
            showNotification({
                type: NotificationsType.info,
                message: "We don't have details for the given payout",
            });
        }
    };

    const generateReport = async () => {
        setIsLoading(true);
        saveDateRange(ReportNames.Payout);
        const result = await reportService.getPayoutSummaryReport(
            parseDateByTimeType(dateRange.start),
            parseDateByTimeType(dateRange.end),
        );

        if (result) {
            const payload = result;
            setRows(payload.map((el, id) => ({ ...el, id })));
            if (payload.length === 0) {
                setShowNoDataLabel(true);
            }
        } else {
            showNotification({
                type: NotificationsType.error,
                message: "Error creating report",
            });
        }
        setIsLoading(false);
    };

    return (
        <div style={{ backgroundColor: "white", margin: 2, padding: 10 }}>
            <Grid container spacing={1.5}>
                <Grid xs={12}>
                    <ReportName name="Payout summary" />
                </Grid>
                <Grid xs={12}>
                    <div className="actions">
                        <DateRangeComponent
                            getStartDate={setStartDate}
                            getEndDate={setEndDate}
                            values={{ start: dateRange.start, end: dateRange.end }}
                            useTime={false}
                            displayTimeType={true}
                            timeType={timeType}
                            handleUTCChange={(value) => changeTimeType(value)}
                        />
                        <ActionsComponent generateReport={generateReport} displayPrint={false} />
                    </div>
                    <Fade in={isLoading}>
                        <LinearProgress />
                    </Fade>
                </Grid>
                <Grid xs={12}>
                    <div className={"report-table"} style={{ height: 650, width: "100%" }}>
                        <DataGridPro
                            rows={rows}
                            columns={columns}
                            loading={isLoading}
                            slots={{
                                toolbar: ExportToolbar,
                                noRowsOverlay: CustomNoRowsOverlay,
                            }}
                            sx={{ "--DataGrid-overlayHeight": "300px" }}
                            getCellClassName={(params: GridCellParams) => {
                                if (params.row.reservationId === -1) {
                                    return "bold";
                                }
                                return "";
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
            <DialogComponent
                DialogText={`Payout detail for: ${payoutIdSelected}`}
                open={open}
                handleClose={handleClose}
                ButtonActions={{
                    handleSuccessActionText: "Close",
                    handleSuccessActionFunction: () => handleClose(),
                }}
            >
                <PayoutDetails
                    rows={detailRows}
                    summary={payoutSelected}
                    closeDialog={() => handleClose()}
                />
            </DialogComponent>
        </div>
    );
};
const ExportToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ fileName: "Payout summary" }} />
    </GridToolbarContainer>
);
export default PayoutSummary;
