import { IContactInfo } from "./ContactInfo";
import { CartDTO } from "./Cart";
import { ShoppingCartState } from "../../store/ShoppingCart/ShoppingCartSlice";
import { Nullable } from "./Generals";

export interface CheckoutConfig {
    isCash: boolean;
    transactionCategoryId: number;
    sendConfirmation: boolean;
}
export class CheckoutConfiguration {
    public isCash: boolean;
    public transactionCategoryId: number;
    public sendConfirmation: boolean;
    constructor() {
        this.isCash = false;
        this.transactionCategoryId = -1;
        this.sendConfirmation = false;
    }

    setConfig(config: Partial<CheckoutConfig>) {
        this.isCash = config.isCash ?? false;
        this.transactionCategoryId = config.transactionCategoryId ?? -1;
        this.sendConfirmation = config.sendConfirmation ?? false;
    }
}

export interface CheckoutPost {
    readonly cartId: string;
    billingInfo: IContactInfo;
    shippingInfo: IContactInfo;
    processorPaymentData: Nullable<string>;
    howHeardAnswerID: number;
    refundReason?: number;
    transactionCategoryId?: number;
    sendConfirmation?: boolean;
}
export interface PaymentGatewayResult {
    success: boolean;
    errors: Array<string>;
    transactionReference: string;
    authorization: string;
    cardLastDigits: string;
    brand: string;
    rechargeToken: string;
    amount: number;
}
export interface RefundResult {
    transactionId: number;
    reservationId: number;
    paymentGatewayResult: PaymentGatewayResult;
}
export interface CheckoutResult {
    readonly checkoutTransaction: string; // = obscured transaction for receipt
    readonly cartDTO: CartDTO;
    readonly transactionId: number;
    readonly errors: string[];
    readonly refundResults: RefundResult[] | null;
}

export type CheckoutInfo = Pick<ShoppingCartState, "billingInfo" | "howHeardAnswerId"> & {
    processorPaymentData: string | null;
    cart: CartDTO;
};

export type CheckoutCompleteURLParams = "transactionId" | "redirect" | "checkoutTransaction";
