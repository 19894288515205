export function isEmpty(obj: any): boolean {
    return (
        obj === null ||
        obj === undefined ||
        (obj.length !== undefined && obj.length === 0) ||
        Object.keys(obj).length === 0
    );
}

export function parseJson<T = unknown>(json?: string): T | null {
    try {
        if (!isEmpty(json) && json) {
            const parsed = JSON.parse(json);
            if (!isEmpty(parsed)) {
                return parsed;
            }
        }
        return null;
    } catch (e) {
        return null;
    }
}

export function handlePrint(objeToPrint: string) {
    const w = window.open();
    if (!w) return;
    w.document.write(objeToPrint);
    w.document.write(`
        <script>
            document.querySelectorAll('table').forEach(item =>{
                item.style['page-break-after'] = ""
            })
        </script>
    `);
    w.print();
}

export const updateOrAppendToArray = <T,>(arr: T[], data: T, idx: number): T[] => {
    const arrCopy = [...arr];
    if (idx < 0) {
        // row doesn't exist
        arrCopy.push(data);
    } else {
        arrCopy[idx] = data;
    }
    return arrCopy;
};
