import { QuestionAnswer } from "shared/models/QuestionTypes";
import {
    ReservationNotifedName,
    ReservationNotified,
    TicketPerson,
} from "shared/models/Reservation";
import { mToFeetInches, kgToPounds, roundTo } from "./Conversions";
import { isPast, isValid, parseISO } from "date-fns";

/**
 * Typed version of dynamic access to the objects in js like: object[key]
 * @param obj
 * @param key
 */
const getKeyValue = <T>(obj: T, key: keyof T): T[keyof T] => obj[key];

/**
 * Given an id, name and value we can build the QuestionAnswer object
 * @param id
 * @param name
 * @param value
 */
export const buildAnswer = (id: number, name: string, value: string): QuestionAnswer => {
    const answer = new QuestionAnswer();
    answer.questionId = id;
    answer.questionText = name;
    answer.answer = [value];
    return answer;
};

// Returns the string value of the Height including the conversion
const getHeightValue = (height: number): string => {
    const { feet, inches } = mToFeetInches(height);
    return ` ${feet}' ${inches}" / ${height.toFixed(2)} m `;
};
// Returns the string value of the Weight including the conversion
const getWeightValue = (weight: number): string => {
    const pounds = kgToPounds(weight);
    return ` ${roundTo(pounds, 0)} lb / ${weight} kg `;
};
/**
 * Returns the array of `QuestionAnswer` objects containing the information of the required fields on a `TicketPerson` object
 * @param ticket
 */
export const requiredFieldsToAnswer = (ticket: TicketPerson): QuestionAnswer[] => {
    const fields = {
        participantFirstName: "Name:",
        participantLastName: "Last name:",
        participantPhone: "Phone:",
        height: "Height",
        weight: "Weight",
    };
    return Object.keys(fields).reduce((acc: QuestionAnswer[], key: any) => {
        const value = getKeyValue(ticket, key);
        const name = getKeyValue(fields, key);
        if (value) {
            switch (key) {
                case "height":
                    acc.push(buildAnswer(1, name, getHeightValue(value as number)));
                    break;
                case "weight":
                    acc.push(buildAnswer(1, name, getWeightValue(value as number)));
                    break;
                default:
                    acc.push(buildAnswer(1, name, value as string));
            }
        }
        return acc;
    }, []);
};

export const optionAvailableByDate = (startDateTime: string): boolean => {
    const parsedDate = parseISO(startDateTime);
    if (!isValid(parsedDate)) return false;
    return !isPast(parsedDate);
};

export const getStatusNotifedName = (status: number): string => {
    const name = ReservationNotifedName[status as ReservationNotified];
    return name ? name : "";
};
