import React, { useEffect, useState } from "react";
import { Button, styled } from "@mui/material";
import { PayoutDetailReport, PayoutSummaryReport } from "shared/models/Report";
import {
    DataGridPro,
    GridColDef,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { currencyCellComponent, formatDateCell } from "shared/utils/Formats";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CustomNoRowsOverlay } from "./NoDataComponent";
import useGlobal from "shared/hooks/GlobalHook";

interface Props {
    rows: PayoutDetailReport[];
    summary?: PayoutSummaryReport;
    closeDialog?: () => void;
}

const Table = styled("table")(({ theme }) => ({
    borderCollapse: "collapse",
}));
const TableCell = styled("td")(({ theme }) => ({
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
}));
const TableHeader = styled("th")(({ theme }) => ({
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
    fontWeight: "bold",
}));

const PayoutDetails = (props: Props) => {
    const columns: GridColDef[] = [
        {
            field: "transactionId",
            headerName: "Transaction Id",
            type: "string",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Button
                        color={"primary"}
                        onClick={() => handleTransactionSelected(params.value)}
                    >
                        {params.value}
                    </Button>
                );
            },
        },
        {
            field: "transactionDate",
            headerName: "Transaction date",
            type: "string",
            flex: 1,
            valueFormatter: (param) => formatDateCell(param.value),
        },
        { field: "name", headerName: "Name", type: "string", flex: 1 },
        {
            field: "amount",
            headerName: "Amount",
            type: "number",
            flex: 0.5,
            renderCell: (param) => currencyCellComponent(param.value),
        },
        {
            field: "fees",
            headerName: "Fees",
            type: "number",
            flex: 0.5,
            renderCell: (param) => currencyCellComponent(param.value),
        },
        {
            field: "tax",
            headerName: "Tax",
            type: "number",
            flex: 0.5,
            renderCell: (param) => currencyCellComponent(param.value),
        },
        {
            field: "total",
            headerName: "Total",
            type: "number",
            flex: 0.5,
            renderCell: (param) => currencyCellComponent(param.value),
        },
    ];
    const [totals, setTotals] = useState({
        amount: 0,
        fees: 0,
        tax: 0,
        total: 0,
    });
    const { setTransactionID, setOpenTransactionDetails } = useGlobal();

    useEffect(() => {
        let tempAmount = 0;
        let tempFees = 0;
        let tempTotal = 0;
        let tempTax = 0;
        props.rows.forEach((row) => {
            tempAmount += row.amount;
            tempFees += row.fees;
            tempTotal += row.total;
            tempTax += row.tax;
        });
        setTotals({
            amount: tempAmount,
            fees: tempFees,
            total: tempTotal,
            tax: tempTax,
        });
    }, [props.rows]);

    const handleTransactionSelected = (transactionId: number) => {
        setTransactionID(transactionId);
        setOpenTransactionDetails(true);
        if (props.closeDialog) {
            props.closeDialog();
        }
    };

    return (
        <Grid container spacing={1.5}>
            <Grid xs={12}>
                <div style={{ display: "flex" }}>
                    <Table>
                        <tbody>
                            <tr>
                                <TableHeader>Payout id</TableHeader>
                                <TableHeader>Amount</TableHeader>
                                <TableHeader>Fees</TableHeader>
                                <TableHeader>Tax</TableHeader>
                                <TableHeader>Total</TableHeader>
                                <TableHeader>Paid on</TableHeader>
                            </tr>
                            <tr>
                                <TableCell>
                                    {props.summary ? props.summary.payoutId : "--"}
                                </TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                    {currencyCellComponent(totals.amount)}
                                </TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                    {currencyCellComponent(totals.fees)}
                                </TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                    {currencyCellComponent(totals.tax)}
                                </TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                    {currencyCellComponent(totals.total)}
                                </TableCell>
                                <TableCell>
                                    {props.summary ? formatDateCell(props.summary.expected) : null}
                                </TableCell>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Grid>
            <Grid xs={12}>
                <DataGridPro
                    rows={props.rows}
                    columns={columns}
                    slots={{
                        toolbar: ExportToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    sx={{ "--DataGrid-overlayHeight": "300px" }}
                />
            </Grid>
        </Grid>
    );
};
const ExportToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ fileName: "Payout details" }} />
    </GridToolbarContainer>
);
export default PayoutDetails;
