import React, { CSSProperties, FunctionComponent, PropsWithChildren } from "react";
import CartUtils from "../../CartUtils";
import { Divider, Typography } from "@mui/material";
import { useIndexicTheme } from "../../../../theme/useIndexicTheme";
import { CartDTO, CartItem, CartReservation } from "../../../../shared/models/Cart";
import { MathEx } from "../../../../shared/utils/MathEx";
import { NewLineToBr } from "../../../../shared/components/NewLineToBr";
import SummaryCard from "shared/components/SummaryCard";
import styles from "./CartSummary.module.css";
import { Each } from "../../../../shared/components/Each";
import { CatalogIDCode, getCatalogItemDescription } from "../../../../shared/models/Catalog";
import { ErrorAlert, WarningAlert } from "shared/components/Alerts";
interface CartSummaryProps {
    cart: CartDTO;
    mainRowSize?: PaymentRowSize;
    isRefund?: boolean;
}

type Props = CartSummaryProps;

export const CartSummary: FunctionComponent<Props> = (props) => {
    const cart = props.cart;
    const theme = useIndexicTheme();
    const taxAndFees = CartUtils.calculateCartTaxesAndFees(cart);
    const appliedGiftCards = CartUtils.calculateGiftCardTotal(cart);
    const total = CartUtils.calculateCartTotal(cart);
    const discount = CartUtils.calculateDiscount(cart);

    const remainingBalance =
        cart?.depositBalance && cart?.depositBalance >= 0 ? cart?.depositBalance : -1;
    return (
        <>
            <SummaryCard>
                <Typography
                    variant={"reservationFullName"}
                    sx={{ fontSize: "1.125rem", marginBottom: "2rem" }}
                >
                    You're {props.isRefund ? "refunding" : "purchasing"}:
                </Typography>
                <div style={{ color: theme.palette.grey["900"] }}>
                    <Each of={cart.reservations} render={(row) => <ReservationRow data={row} />} />
                    <Each of={cart.catalogItems} render={(row) => <CatalogItemRow data={row} />} />
                </div>
                <div style={{ padding: "2rem 0", width: "100%" }}>
                    <Divider />
                </div>
                {!props.isRefund ? (
                    <Typography
                        variant={"reservationFullName"}
                        sx={{ fontSize: "1.125rem", marginBottom: "2rem" }}
                    >
                        You're paying:
                    </Typography>
                ) : null}

                <PaymentRow amount={discount}>DISCOUNT</PaymentRow>
                <PaymentRow amount={cart.subTotal} showZeroAmount={true}>
                    {props.isRefund ? "REFUND" : "SUBTOTAL"}
                </PaymentRow>
                <PaymentRow amount={taxAndFees} showZeroAmount={true}>
                    {props.isRefund ? "REFUND TAX" : "TAXES & FEES"}
                </PaymentRow>
                <PaymentRow amount={appliedGiftCards} showAsNegative={true}>
                    GIFT CARDS
                </PaymentRow>
                <PaymentRow amount={remainingBalance}>REMAINING BALANCE</PaymentRow>
                <PaymentRow
                    amount={total}
                    mainRow={true}
                    mainRowSize={"small"}
                    showZeroAmount={true}
                >
                    TOTAL {props.isRefund ? "TO BE REFUNDED" : "DUE TODAY"}
                </PaymentRow>
            </SummaryCard>
            <div style={{ marginTop: "1rem" }}>
                <Each
                    of={cart.warnings}
                    render={(warning, k) => <WarningAlert key={k}>{warning}</WarningAlert>}
                />
                <Each
                    of={cart.errors}
                    render={(error, k) => <ErrorAlert key={k}>{error}</ErrorAlert>}
                />
            </div>
        </>
    );
};
interface ReservationRowProps {
    data: CartReservation;
}
const ReservationRow = (props: ReservationRowProps) => {
    if (!props.data.description) {
        return null;
    }
    const eventName = props.data.description.split("\n")[0];
    return (
        <div className={styles.reservationRowWrapper}>
            <div className={styles.eventName}>
                <Typography variant={"idReservation"} sx={{ textTransform: "uppercase" }}>
                    {eventName}
                </Typography>
            </div>
            <div className={styles.contact}>
                <Typography variant={"inputLabel"}>
                    {props.data.contact.firstName ?? ""}
                    {` ${props.data.contact.lastName}`}
                </Typography>
            </div>
            <div className={styles.totalLabel}>
                <Typography variant={"idReservation"}>TOTAL</Typography>
            </div>
            <div className={styles.total}>
                <Typography variant={"inputLabel"}>
                    {MathEx.formatCurrency(props.data.price)}
                </Typography>
            </div>

            <div className={styles.details}>
                <NewLineToBr startLines={1}>{props.data.description}</NewLineToBr>
                {props.data.addOns.length > 0 ? (
                    <div style={{ marginTop: "1rem" }}>
                        <Typography>Merchandise</Typography>
                        <ul style={{ margin: 0 }}>
                            {props.data.addOns.map((el, idx) => (
                                <li key={idx}>
                                    {el.quantity}&nbsp;-&nbsp;{el.description}
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
interface CatalogItemRowProps {
    data: CartItem;
}
const CatalogItemRow = (props: CatalogItemRowProps) => {
    let itemHeader = getCatalogItemDescription(props.data);
    const isCoupon = props.data.catalogId === CatalogIDCode.Coupon;
    return (
        <div className={styles.reservationRowWrapper}>
            <div className={styles.eventName}>
                <Typography variant={"idReservation"} sx={{ textTransform: "uppercase" }}>
                    {itemHeader}
                </Typography>
            </div>
            <div className={styles.contact}>
                <Typography variant={"inputLabel"}>{props.data.description ?? ""}</Typography>
            </div>
            <div className={styles.totalLabel}>
                {!isCoupon ? <Typography variant={"idReservation"}>TOTAL</Typography> : null}
            </div>
            <div className={styles.total}>
                {!isCoupon ? (
                    <Typography variant={"inputLabel"}>
                        {MathEx.formatCurrency(props.data.price * props.data.quantity)}
                    </Typography>
                ) : null}
            </div>
            <div className={styles.details}>
                {props.data.quantity > 1 ? (
                    <ul style={{ margin: 0 }}>
                        <li>
                            {props.data.quantity} items ({MathEx.formatCurrency(props.data.price)}{" "}
                            each)
                        </li>
                    </ul>
                ) : null}
            </div>
        </div>
    );
};
type PaymentRowSize = "small" | "medium" | "large";
interface PaymentRowProps {
    amount: number;
    mainRow?: boolean;
    showZeroAmount?: boolean;
    showAsNegative?: boolean;
    mainRowSize?: PaymentRowSize;
}
const PaymentRow: FunctionComponent<PropsWithChildren<PaymentRowProps>> = (props) => {
    const fontSizesObj: Record<PaymentRowSize, string> = {
        small: "1rem",
        medium: "1.15rem",
        large: "1.375rem",
    };
    const size: PaymentRowSize = props.mainRowSize ?? "large";
    const fontSize = fontSizesObj[size];
    const amountStyles: CSSProperties = props.mainRow
        ? {
              color: "var(--dark-aqua-dark)",
              textAlign: "right",
              fontFamily: "Inter",
              fontSize,
              fontStyle: "normal",
              fontWeight: "600",
          }
        : { color: "var(--grey-900)" };
    if (!props.showZeroAmount && props.amount <= 0) {
        return null;
    }
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant={"idReservation"}>{props.children}</Typography>
            <div style={{ flex: 1 }}></div>
            <Typography variant={"inputLabel"} style={amountStyles}>
                {MathEx.formatCurrency(props.showAsNegative ? -1 * props.amount : props.amount)}
            </Typography>
        </div>
    );
};
