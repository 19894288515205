import { createUrl, HttpService } from "../http/http";
import { CheckoutConfig, CheckoutInfo, CheckoutPost, CheckoutResult } from "../models/Checkout";
import { isEmpty } from "../utils/Common";
import { StripeReader } from "../models/Stripe";
import { ValidateSchema } from "../utils/ZodValidations";
import { StripeReaderSchema } from "../models/Stripe.schema";

const CASH_LASTNAME = "{Cash}";
const CASH_PROCESS_PAYMENT = "{Cash}";
//TODO: create a general config file
const API_ROUTE = "api/cart";
const PORTAL_ROUTE = "portal/cart";
class CheckoutService extends HttpService {
    constructor() {
        super("/");
    }

    async ProcessCheckout(info: CheckoutInfo, config: CheckoutConfig) {
        const dto: CheckoutPost = config.isCash
            ? this.createCheckoutCashDTO(info, config)
            : this.createCheckoutDTO(info, config);
        return await this.checkout(dto);
    }
    async startStripeTerminalCheckout(
        terminalId: string,
        info: CheckoutInfo,
        config: CheckoutConfig,
    ) {
        const dto = this.createCheckoutDTO(info, config);
        const uri = createUrl([PORTAL_ROUTE, "Terminal", "Stripe", terminalId, "Checkout"]);
        const result = await this.post<StripeReader, CheckoutPost>(uri, dto);
        return ValidateSchema(result.data, StripeReaderSchema);
    }
    private async checkout(data: CheckoutPost) {
        const uri = createUrl([API_ROUTE, "Checkout", data.cartId]);
        const result = await this.post<CheckoutResult, CheckoutPost>(uri, data);
        //TODO: add zod validation
        return result.data;
    }
    createCheckoutCashDTO(info: CheckoutInfo, config: CheckoutConfig): CheckoutPost {
        const dto = this.createCheckoutDTO(info, config);
        let billingInfo = { ...dto.billingInfo };
        if (isEmpty(billingInfo)) {
            billingInfo = { lastName: CASH_LASTNAME };
        } else {
            if (isEmpty(billingInfo.lastName)) {
                billingInfo.lastName = CASH_LASTNAME;
            }
        }
        if (config.transactionCategoryId > -1) {
            dto.transactionCategoryId = config.transactionCategoryId;
        }
        return {
            ...dto,
            billingInfo,
            processorPaymentData: CASH_PROCESS_PAYMENT,
        };
    }
    createCheckoutDTO(info: CheckoutInfo, config: CheckoutConfig): CheckoutPost {
        return {
            // shippingInfo: info.shippingInfo, TODO: check shipping info
            shippingInfo: {
                firstName: info.billingInfo.firstName,
                lastName: info.billingInfo.lastName,
                email: info.billingInfo.email,
                phone: info.billingInfo.phone,
            },
            billingInfo: info.billingInfo,
            processorPaymentData: info.processorPaymentData,
            cartId: info.cart.cartId,
            howHeardAnswerID: info.howHeardAnswerId!,
            sendConfirmation: config?.sendConfirmation,
        };
    }
}
const checkoutService = new CheckoutService();

export default checkoutService;
