import React, { FunctionComponent, useState } from "react";
import { RefundCatalogItem, TransactionItem } from "shared/models/Transaction";
import { IconButton, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { CurrencyComponent } from "../../Reports/components/CurrencyComponent";
import { FlatButton } from "shared/components/FlatButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DialogHooks } from "shared/components/DialogComponent";
import { getCatalogNameFromId } from "shared/models/Catalog";
import RefundCatalogItemDialog, { RefundCatalogItemDialogProps } from "./RefundCatalogItemDialog";
import Alert from "@mui/material/Alert";
import { Warning } from "@mui/icons-material";

interface Props {
    catalogItem: TransactionItem;
    refundCatalogItem?: RefundCatalogItem;
    onSet: (data: RefundCatalogItem) => void;
}
const RefundCatalogItemRow: FunctionComponent<Props> = (props) => {
    const [open, handleClickOpen, handleClose] = DialogHooks();
    const { catalogItem, refundCatalogItem } = props;
    const { unitPrice, tax, serviceCharge, quantity } = catalogItem;
    const total = unitPrice * quantity + tax + serviceCharge;

    const [refundAmounts, setRefundAmounts] = useState({
        tax: 0,
        serviceCharge: 0,
        unitPrice: 0,
        quantity: 0,
        amount: 0,
    });

    const handleRefundAmount: RefundCatalogItemDialogProps["onSet"] = (data) => {
        setRefundAmounts(data);
        props.onSet({
            catalogId: catalogItem.catalogID,
            reservationIdAddOn: catalogItem.reservationID,
            unitPrice: data.unitPrice,
            refundTax: data.tax,
            refundQuantity: data.quantity,
            refundServiceCharge: data.serviceCharge,
            description: catalogItem.description,
        });
        handleClose();
    };
    return (
        <>
            <TableRow>
                <TableCell>
                    {catalogItem.catalogID <= 0 ? (
                        <Typography color={"primary"} fontStyle={"italic"} fontWeight={600}>
                            {getCatalogNameFromId(catalogItem.catalogID)}
                        </Typography>
                    ) : (
                        <Typography>Cat #{catalogItem.catalogID}</Typography>
                    )}
                    <Typography>{catalogItem.description}</Typography>
                </TableCell>
                <TableCell>
                    {quantity > 1 ? `${quantity} x (` : ""}
                    <CurrencyComponent value={unitPrice} />
                    {quantity > 1 ? ")" : ""}
                </TableCell>
                <TableCell>
                    <CurrencyComponent value={tax} />
                </TableCell>
                <TableCell>
                    <CurrencyComponent value={serviceCharge} />
                </TableCell>
                <TableCell>
                    <CurrencyComponent value={total} />
                </TableCell>
            </TableRow>
            {refundCatalogItem ? (
                <TableRow>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    >
                        {refundCatalogItem.refundQuantity <= 0 ? (
                            <Typography
                                fontWeight={600}
                                sx={{ color: (theme) => theme.palette.warning.dark }}
                            >
                                All elements refunded
                            </Typography>
                        ) : refundAmounts.amount === 0 ? (
                            <FlatButton color={"primary"} onClick={handleClickOpen}>
                                Set refund amounts
                            </FlatButton>
                        ) : (
                            <Stack alignItems={"center"} direction={"row"}>
                                <IconButton color={"primary"} onClick={handleClickOpen}>
                                    <EditOutlinedIcon />
                                </IconButton>
                                <Typography>Refund Amounts</Typography>
                            </Stack>
                        )}
                    </TableCell>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    >
                        {refundAmounts.amount > 0 ? (
                            <CurrencyComponent value={-1 * refundAmounts.amount} />
                        ) : null}
                    </TableCell>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    >
                        {refundAmounts.tax > 0 ? (
                            <CurrencyComponent value={-1 * refundAmounts.tax} />
                        ) : null}
                    </TableCell>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    >
                        {refundAmounts.serviceCharge > 0 ? (
                            <CurrencyComponent value={-1 * refundAmounts.serviceCharge} />
                        ) : null}
                    </TableCell>
                    <TableCell
                        sx={{
                            borderBottomColor: (theme) => theme.palette.grey["900"],
                        }}
                    ></TableCell>
                </TableRow>
            ) : null}
            {refundCatalogItem ? (
                <RefundCatalogItemDialog
                    maxRefund={{
                        quantity: refundCatalogItem.refundQuantity,
                        unitPrice: refundCatalogItem.unitPrice,
                        tax: refundCatalogItem.refundTax,
                        serviceCharge: refundCatalogItem.refundServiceCharge,
                        amount: refundCatalogItem.refundQuantity * refundCatalogItem.unitPrice,
                    }}
                    taxRate={catalogItem.taxRate}
                    onSet={handleRefundAmount}
                    onCancel={handleClose}
                    open={open}
                />
            ) : null}
        </>
    );
};

export default RefundCatalogItemRow;
