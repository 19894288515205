import { CatalogDetail } from "./Catalog";
import { Rate, RateQuestionGroup } from "./Rates";
import { ReservationListItem, ReservationListResource } from "./Reservation";
import { DateTimeString } from "./UtilsTypes";
import { Nullable } from "./Generals";
import { QuestionType } from "./QuestionTypes";

export const InitialEventTimeSummary: VwEventTimeSummary = {
    assignedPersonnel: null,
    companyId: 0,
    displayOrder: -1,
    endDateTime: "",
    eventDate: "",
    eventDateId: 0,
    eventId: 0,
    // isAwaiverSupported: true,
    maxTicketsOverride: 0,
    noFollowUp: false,
    noReminder: false,
    personnelIssue: false,
    // personnelRequired: 0,
    privateNote: null,
    publicNote: null,
    rateAdjustmentPercent: 0,
    rateAdjustmentRoundingUnit: 0,
    reportingCategoryId: -1,
    requiresPersonnel: false,
    reservationCount: 0,
    resources: null,
    sellStatus: "",
    startDateTime: "",
    threshold: 0,
    ticketsSold: 0,
    ud: "",
    uu: "",
};

export const InitialEvent: Event = {
    name: "",
    displayOrder: 0,
    companyId: 0,
    eventId: 0,
    companyName: "",
    colorClass: "",
    description: "",
    durationInSeconds: 0,
    hideDuration: false,
    urlFriendlyName: "",
    excludeFromReseller: false,
    lat: null,
    lon: null,
    minRate: 0,
    maxRate: 0,
    restrictions: null,
    reservationInfo: null,
    mapUrl: "",
    maxTickets: 0,
    address: null,
    latestSell: 0,
    latestChange: 0,
    earliestSell: 0,
    eventTimeType: 0,
    ticketRequiredFields: 0,
    nextTime: "",
    oneDay: false,
    singleTimeEventId: 0,
    rates: [],
    deposit: 0,
    depositOnly: false,
    delivery: false,
    questionsJson: "",
    rateQuestionGroups: [],
    addOns: [],
    seatAssignmentChartKey: "",
    seatAssignmentMode: "",
    seatingChartImg: "",
    isWaiverSupported: false,
    active: false,
    holdEventDateId: -1,
};

export interface Event {
    name: string;
    displayOrder: number;
    companyId: number;
    eventId: number;
    companyName: string;
    colorClass: string;
    description: string;
    durationInSeconds: number;
    hideDuration: boolean;
    urlFriendlyName: string;
    excludeFromReseller: boolean;
    lat: Nullable<number>;
    lon: Nullable<number>;
    minRate: number;
    maxRate: number;
    restrictions: Nullable<string>;
    reservationInfo: Nullable<string>;
    mapUrl: string;
    maxTickets: number;
    address: Nullable<string>;
    latestSell: number;
    latestChange?: number;
    earliestSell: number;
    eventTimeType: number;
    ticketRequiredFields: number;
    nextTime: string;
    oneDay: boolean;
    singleTimeEventId: number;
    rates: Rate[];
    deposit: number;
    depositOnly: boolean;
    delivery: boolean;
    questionsJson: string;
    rateQuestionGroups: RateQuestionGroup[];
    addOns: Array<Partial<CatalogDetail>>;
    seatAssignmentChartKey: string | null;
    seatAssignmentMode?: string;
    seatingChartImg?: string;
    isWaiverSupported?: boolean;
    active?: boolean;
    holdEventDateId: number;
}

/**
 * Old EventTimeSummary object, currently used by the Reservation list controller
 * Has fewer properties than the actual EventTimeSummary interface
 */
export interface VwEventTimeSummary {
    assignedPersonnel: Array<PersonnelAssigned> | null;
    companyId: number;
    displayOrder: number;
    endDateTime: string;
    eventDate: string;
    eventDateId: number;
    eventId: number;
    // isAwaiverSupported: boolean; TODO: check api doesn't exists
    maxTicketsOverride: number;
    noFollowUp: boolean;
    noReminder: boolean;
    personnelIssue: boolean;
    // personnelRequired: number; TODO: check api
    privateNote: Nullable<string>;
    publicNote: Nullable<string>;
    rateAdjustmentPercent: number;
    rateAdjustmentRoundingUnit: number;
    reportingCategoryId: number;
    requiresPersonnel: boolean;
    reservationCount: number;
    resources: Array<ReservationListResource> | null;
    sellStatus: string;
    startDateTime: string;
    threshold: number;
    ticketsSold: number;
    ud: string;
    uu: string;
}

export interface EventTimeSummary {
    companyId: number;
    eventCode: string;
    eventName: string;
    colorClass: string;
    eventDateId: number;
    eventId: number;
    eventDate: DateTimeString;
    startDateTime: DateTimeString;
    sellStatus: string;
    ticketsSold: number;
    privateNote: string | null;
    publicNote: string | null;
    maxTicketsOverride: number;
    maxTickets: number;
    threshold: number;
    personnelIssue: boolean;
    assignedPersonnel: Array<PersonnelAssigned> | null;
    resources: Array<ReservationListResource> | null;
    noFollowUp: boolean;
    noReminder: boolean;
    rateAdjustmentPercent: number;
    rateAdjustmentRoundingUnit: number;
    reservationCount: number;
    reportingCategoryId: number;
    displayOrder: number;
    ud: string;
    uu: string;
}

export interface PersonnelResponse {
    availablePersonnel: PersonnelAssigned[];
    requiredPersonnel: RequiredPersonnel[];
}
export interface PersonnelAssigned {
    userId: string;
    firstName: string;
    lastName: string;
    companyPersonnelTypeId: number;
    typeName: string;
}
export interface RequiredPersonnel {
    companyPersonnelTypeID: number;
    typeName: string;
    quantity: number;
    ud: DateTimeString;
    uu: Nullable<string>;
}

export interface EventDateReservationSummary {
    attachedWaiversCount: number;
    ticketSummaries: TicketSummaries[];
    reservations: ReservationListItem[];
    eventTimeSummary: VwEventTimeSummary;
    answersSummary: AnswerSummary[];
    requiredPersonnel: RequiredPersonnel[];
}
export interface TicketSummaries {
    checkedInCount: number;
    rate: string;
    tickets: number;
    waiverCount: number;
    weight: number;
}
export interface AnswerSummary {
    questionType: QuestionType;
    question: string;
    answer: string;
    total: number;
}

export interface PortalEventSummaries {
    events: PortalEventSummary[];
    eventGroups: EventGroupItem[];
}

export interface PortalEventSummary {
    eventId: number;
    companyId: string;
    companyName: Nullable<string>;
    name: string;
    active: boolean;
    colorClass: string;
    delivery: boolean;
    eventCode: string;
    maxTickets: string;
    eventTimeType: EventTimeTypes;
    thresholdMode: string;
    requireReservationUserId: boolean;
    holdEventDateId: string;
}
export enum EventTimeTypes {
    SetTime = 0,
    OpenTime,
}

export interface EventGroupItem {
    eventGroupId: number;
    eventGroupName: string;
    eventIds: number[];
    ud: Nullable<string>;
    uu: Nullable<string>;
}
export interface IEventSettings {
    maxTicketsOverride: number;
    publicNote: string;
    privateNote: string;
    sellStatus: string;
    rateAdjustmentPercent: number;
    rateAdjustmentRoundingUnit: number;
    noFollowUp: boolean;
    noReminder: boolean;
}

export interface EventDateTimeItem {
    eventDateID: number;
    eventDateTime: string;
    sell_Status: string; // O o S
    public_Note: string;
    startTime: string;
    endTime: string;
    reason: string;
    rateAdjustmentPercent: number;
    rateAdjustmentRoundingUnit: number;
    totalTicketAmount: number;
}

export interface CalendarDate {
    day: string;
    minTime: string;
    timeCount: number;
}

export interface EventsByPersonnel {
    firstName: string;
    lastName: string;
    userId: string;
    events: GroupedPersonnelEventDates[];
}

export interface GroupedPersonnelEventDates {
    typeName: string;
    companyPersonnelTypeId: number;
    eventId: number;
    eventName: string;
    tickets: number;
    eventDateTimeCount: number;
}

export interface NotifyResult {
    resultCode: number;
    resultMessage: string;
    validationError?: string | null;
}
export interface CancelEventResult {
    eventDateSettings: IEventSettings;
    notifyResult: NotifyResult;
}
export interface SelectedPersonnel {
    companyPersonnelTypeId: number;
    userId: string;
}

export interface PersonnelAssigned {
    userId: string;
    firstName: string;
    lastName: string;
    companyPersonnelTypeId: number;
    typeName: string;
}

export interface SaveCompanyPersonnel {
    companyPersonnelTypeId: number;
    personnel: Array<PersonnelAssigned>;
}

export interface RequiredPersonnelBody {
    companyPersonnelTypeId: number;
    typeName: number;
    quantity: number;
}

export interface CompanyPersonnel {
    userId: string;
    firstName: string;
    lastName: string;
    userName: string;
    personnelTypes: PersonnelType[];
}
export interface PersonnelType {
    typeName: string;
    companyPersonnelTypeId: number;
}
