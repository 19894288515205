import React, { Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { msalInstance } from "./auth/MSALAuth";
import { CustomNavigationClient } from "./shared/utils/NavigationCilent";
import { MsalProvider } from "@azure/msal-react";
import IndexicTheme from "./theme/IndexicTheme";
import AppRouter from "./AppRouter";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "theme/css/variables.css";
import { LicenseInfo } from "@mui/x-license-pro";
import { MUI_KEY } from "environment/environment";
import AuthProvider from "modules/Auth/AuthProvider";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        },
    },
});
function App() {
    LicenseInfo.setLicenseKey(MUI_KEY);
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    msalInstance.setNavigationClient(navigationClient);
    return (
        <MsalProvider instance={msalInstance}>
            <IndexicTheme>
                <QueryClientProvider client={queryClient}>
                    <Suspense fallback={<div>Loading ...</div>}>
                        <AuthProvider>
                            <AppRouter />
                        </AuthProvider>
                    </Suspense>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </IndexicTheme>
        </MsalProvider>
    );
}
export default App;
