import React, { useState, useEffect } from "react";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";

import { Locale } from "shared/utils/Locale";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { InputAdornment, TextField } from "@mui/material";
import { Validation } from "shared/utils/Validation";
import { IContactInfo, IContactInfoTemplate } from "shared/models/ContactInfo";
import { AccountCircle, MailOutline } from "@mui/icons-material";
import { useReservationModalContext } from "../../modules/Reservation/context/ReservationModalProvider";
import { useAppSelector } from "store/Store";
import parsePhoneNumberFromString, { CountryCode } from "libphonenumber-js";

export interface IContactInfoProps {
    companyEntry?: boolean;
    addContact: (contact: IContactInfo) => void;
    isValid: IContactInfoTemplate<boolean>;
    setIsValid: (isValid: IContactInfoTemplate<boolean>) => void;
    contactInfo: IContactInfo;
    isInformationPasted: boolean; //this prop helps to avoid loop render
}

export default function ContactInfo({
    companyEntry = false,
    isValid,
    contactInfo,
    isInformationPasted,
    addContact,
    setIsValid,
}: IContactInfoProps) {
    const { reservation } = useReservationModalContext();
    const shoppingCart = useAppSelector((s) => s.shoppingCart.cart);
    const [contactForm, setContactForm] = useState<IContactInfo>({
        firstName: reservation?.firstName || "",
        lastName: reservation?.lastName || "",
        email: reservation?.email || "",
        phone: reservation?.phone || "",
    });

    const [countryCode, setCountryCode] = useState<CountryCode>("US");
    const [phoneNumber, setPhoneNumber] = useState<string>(reservation?.phone || "");
    const [hasBlurred, setHasBlurred] = useState<IContactInfoTemplate<boolean>>({
        email: false,
        lastName: false,
        phone: false,
        firstName: false,
    } as IContactInfoTemplate<boolean>);
    const isRequired = !companyEntry;

    useEffect(() => {
        if (contactForm.lastName === "" && shoppingCart && shoppingCart.reservations.length > 0) {
            setContactForm(shoppingCart.reservations[0].contact);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shoppingCart]);

    useEffect(() => {
        if (isInformationPasted) {
            setContactForm(contactInfo);
        }
    }, [isInformationPasted]);

    useEffect(() => {
        setIsValid(generateValidityObj(contactForm)(isValid));
        // eslint-disable-next-line
    }, [contactForm]);

    useEffect(() => {
        if (isValid) {
            const parsePhoneNumber = parsePhoneNumberFromString(phoneNumber, countryCode);
            if (parsePhoneNumber) {
                addContact({ ...contactForm, phone: parsePhoneNumber.number });
            } else {
                addContact({ ...contactForm, phone: "" });
            }
        }
        // eslint-disable-next-line
    }, [isValid]);

    const generateValidityObj =
        (contactInfo: IContactInfo) => (validityObj: IContactInfoTemplate<boolean>) => {
            const validity = { ...validityObj };
            if (contactInfo.lastName && contactInfo.lastName !== "") {
                validity.lastName = false;
            } else {
                validity.lastName = true;
            }
            if (contactInfo.email && contactInfo.email !== "") {
                validity.email = Validation.Email(contactInfo.email).length !== 0;
            }
            if (contactInfo.phone && contactInfo.phone !== "") {
                validity.phone = Validation.PhoneNumber(contactInfo.phone).length !== 0;
                if (
                    contactInfo.phone?.length === 0 ||
                    contactInfo.phone?.length === 4 ||
                    contactInfo.phone?.length === 3
                ) {
                    validity.phone = false;
                }
            } else {
                validity.phone = false;
            }

            return validity;
        };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const newValue = { ...hasBlurred };
        (newValue as any)[event.target.name] = true;
        setHasBlurred(newValue);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = { ...contactForm };
        (newValue as any)[event.target.name] = event.target.value;
        const newValidity = { ...isValid };
        (newValidity as any)[event.target.name] = event.target.validity.valid;
        //onChange(newValue, allValid(newValidity, companyEntry));
        setContactForm(newValue);
        setIsValid(newValidity);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const emailValid = Validation.Email(event.target.value).length === 0;
        const newValidity = {
            ...isValid,
            email: emailValid,
        };
        setIsValid(newValidity);
        setContactForm({ ...contactForm, email: event.target.value });
        // onChange(
        //     { ...contactForm, email: event.target.value },
        //     allValid(newValidity, companyEntry),
        // );
    };

    const handlePhoneChange = (phone: string, info: MuiTelInputInfo) => {
        setPhoneNumber(phone);
        if (info.countryCode) {
            setCountryCode(info.countryCode);
        }
        setContactForm({ ...contactForm, phone: phone });
    };

    return (
        <Grid container spacing={2}>
            <Grid xs={12}>
                <TextField
                    data-cy="firstName"
                    id="firstName"
                    name="firstName"
                    value={contactForm.firstName}
                    label={Locale.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    size="small"
                />
            </Grid>
            <Grid xs={12}>
                <TextField
                    data-cy="lastName"
                    id="lastName"
                    name="lastName"
                    value={contactForm.lastName}
                    label={Locale.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={isRequired && isValid.lastName}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        ),
                    }}
                    required
                    fullWidth
                    size="small"
                />
            </Grid>
            <Grid xs={12}>
                <TextField
                    data-cy="email"
                    id="email"
                    name="email"
                    type="email"
                    value={contactForm.email}
                    label={Locale.email}
                    onChange={handleEmailChange}
                    onBlur={handleBlur}
                    error={isValid.email}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutline />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    size="small"
                />
            </Grid>
            <Grid xs={12}>
                <MuiTelInput
                    value={contactForm.phone}
                    error={isValid.phone}
                    label={Locale.phone}
                    onChange={handlePhoneChange}
                    onBlur={handleBlur}
                    defaultCountry={"US"}
                    variant="outlined"
                    disableFormatting
                    forceCallingCode
                    fullWidth
                    InputProps={{
                        autoComplete: "off",
                    }}
                    size="small"
                />
            </Grid>
        </Grid>
    );
}
