import { HttpService, createUrl } from "shared/http/http";
import { formatISO } from "date-fns";
import {
    CommissionReport,
    DeliveryReportReport,
    SalesByEmployeeReport,
    SalesByEmployeeOption,
    TicketTypeSummaryReport,
    ReservationByEmployeeReport,
    PayoutSummaryReport,
    PayoutDetailReport,
    TransactionReport,
    CashCategorySummary,
} from "shared/models/Report";
import { EventsByPersonnel } from "shared/models/Event";
import { ValidateSchema } from "shared/utils/ZodValidations";
import {
    CashCategorySummariesSchema,
    CommissionReportSchema,
    DeliveryReportReportSchema,
    PayoutDetailReportSchema,
    PayoutSummaryReportSchema,
    ReservationByEmployeeReportSchema,
    SalesByEmployeeReportSchema,
    TicketTypeSummaryReportSchema,
    TransactionReportSchema,
} from "shared/models/Report.schema";
import { EventsByPersonnelSchema } from "shared/models/Event.schema";
import { z } from "zod";

class ReportsService extends HttpService {
    constructor() {
        super("/portal/Report");
    }

    async getReservationByEmployeeReport(start: Date, end: Date) {
        const uri = createUrl(["ReservationsByEmployee", formatISO(start), formatISO(end)]);
        const result = await this.get<Array<ReservationByEmployeeReport>>(uri);
        return ValidateSchema(result.data, z.array(ReservationByEmployeeReportSchema));
    }
    async getPayoutSummaryReport(start: Date, end: Date) {
        const uri = createUrl(["PayoutSummaryReport", formatISO(start), formatISO(end)]);
        const result = await this.get<Array<PayoutSummaryReport>>(uri);
        return ValidateSchema<Array<PayoutSummaryReport>>(
            result.data,
            z.array(PayoutSummaryReportSchema),
        );
    }
    async getPayoutDetailsReport(payoutId: string) {
        const uri = createUrl(["PayoutDetailReport", payoutId]);
        const result = await this.get<Array<PayoutDetailReport>>(uri);
        return ValidateSchema<Array<PayoutDetailReport>>(
            result.data,
            z.array(PayoutDetailReportSchema),
        );
    }
    async getDeliveryReport(queryDate: Date) {
        const uri = createUrl(["DeliveryReport", queryDate.toISOString()]);
        const result = await this.get<Array<DeliveryReportReport>>(uri);
        return ValidateSchema<Array<DeliveryReportReport>>(
            result.data,
            z.array(DeliveryReportReportSchema),
        );
    }
    async getAssignedPersonnelSummaryReport(start: Date, end: Date) {
        const uri = createUrl(["AssignedPersonnelSummaryReport", formatISO(start), formatISO(end)]);
        const result = await this.get<Array<EventsByPersonnel>>(uri);
        return ValidateSchema<Array<EventsByPersonnel>>(
            result.data,
            z.array(EventsByPersonnelSchema),
        );
    }

    async getSalesByReport(start: Date, end: Date, option: SalesByEmployeeOption) {
        const uri = createUrl([
            "SalesByEmployeeReport",
            formatISO(start),
            formatISO(end),
            option.toString(),
        ]);
        const result = await this.get<Array<SalesByEmployeeReport>>(uri);
        return ValidateSchema<Array<SalesByEmployeeReport>>(
            result.data,
            z.array(SalesByEmployeeReportSchema),
        );
    }

    async getCommissionReport(start: Date, end: Date, isReservationDate = false) {
        const uri = createUrl([
            "CommissionReport",
            formatISO(start),
            formatISO(end),
            isReservationDate.toString(),
        ]);
        const result = await this.get<Array<CommissionReport>>(uri);
        return ValidateSchema<Array<CommissionReport>>(
            result.data,
            z.array(CommissionReportSchema),
        );
    }
    async getTicketTypeSummaryReport(start: Date, end: Date) {
        const uri = createUrl(["TicketTypeSummary", formatISO(start), formatISO(end)]);
        const result = await this.get<Array<TicketTypeSummaryReport>>(uri);
        return ValidateSchema<Array<TicketTypeSummaryReport>>(
            result.data,
            z.array(TicketTypeSummaryReportSchema),
        );
    }

    async getTransactionReport(start: Date, end: Date) {
        const uri = createUrl(["TransactionReport", start, end]);
        const result = await this.get<TransactionReport>(uri);
        return ValidateSchema<TransactionReport>(result.data, TransactionReportSchema);
    }
    async getCashCategoryReport(
        start: Date,
        end: Date,
        categories: number[],
    ): Promise<CashCategorySummary[]> {
        const uri = createUrl(["CashCategoryReport", start, end, categories.join()]);
        const result = await this.get<CashCategorySummary[]>(uri);
        return ValidateSchema(result.data, CashCategorySummariesSchema);
    }
}
const reportService = new ReportsService();
export default reportService;
