import React, { FunctionComponent } from "react";
import { MenuItem, TextField, Typography } from "@mui/material";
import { useShoppingCartSlice } from "../../../store/ShoppingCart/useShoppingCartSlice";
import { CartReservation } from "../../../shared/models/Cart";

interface SelectReservationControlProps {
    selectedReservation: number;
    onSelectReservation: (reservationId: number) => void;
}

export const SelectReservationControl: FunctionComponent<SelectReservationControlProps> = (
    props,
) => {
    const { shoppingCartState } = useShoppingCartSlice();
    const reservations: CartReservation[] = shoppingCartState.cart?.reservations || [];
    // const onSelectedReservationChange: TextFieldProps["onChange"] = (e) => {};
    return (
        <>
            <Typography variant={"inputLabel"} gutterBottom>
                Is this with a reservation? (Select one)
            </Typography>
            <TextField
                select
                fullWidth
                size={"small"}
                value={props.selectedReservation}
                sx={{
                    background: "white",
                }}
                onChange={(e) => props.onSelectReservation(Number(e.target.value))}
            >
                <MenuItem value={-1}>Not with a reservation</MenuItem>
                {reservations.map((el, idx) => (
                    <MenuItem key={idx} value={el.reservationId}>
                        <Typography>{cleanUpDescription(el.description)}</Typography>
                    </MenuItem>
                ))}
            </TextField>
        </>
    );
};

const cleanUpDescription = (description: string): string => {
    const lines = description.split("\n");
    return lines[0] + " (" + lines[1] + ")";
};
