import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { SxProps, Theme } from "@mui/material/styles";
interface Props {
    label: string;
    loading: boolean;
    disabled: boolean;
    handleClick: () => void;
    sx?: SxProps<Theme>;
    fullWidth?: boolean;
}
export default function LoadingButtons(props: Props) {
    return (
        <LoadingButton
            size="small"
            disabled={props.disabled}
            loading={props.loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={props.handleClick}
            sx={props.sx ?? null}
            fullWidth={props.fullWidth}
        >
            <span>{props.label}</span>
        </LoadingButton>
    );
}
