import { ZodArray, ZodError, ZodObject, z } from "zod";
import { PRODUCTION } from "../../environment/environment";

export const ValidateSchema = <T = unknown>(data: T, schema: ZodObject<any> | ZodArray<any>): T => {
    if (PRODUCTION) {
        const result = schema.safeParse(data);
        if (result.success) {
            return result.data as T;
        }
        // Needed for type narrow
        if (result.success === false) {
            console.error(result.error);
        }
        return data as T;
    } else {
        // schema.parse throws an error if parsing fails
        try {
            return schema.parse(data) as T;
        } catch (e) {
            if (e instanceof ZodError) {
                console.error({ ZodError: e, data });
            }
            throw e;
        }
    }
};

export const DateTimeISOValidation = z.string();
